import React from 'react';
import {
  View,
  Text,
  ImageBackground,
  Image,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Platform
} from 'react-native';
import styles from '../../style/scanpack';
import axios from 'axios';
import globalStyles from '../../style/global';
import {LinearGradient} from 'expo-linear-gradient';
import backImage from '../../../assets/bg_right.png';
import backNewImage from '../../../assets/Right_new2.jpg';
import placeholder from '../../../assets/placeholder.jpg';
import {fontFamily} from '../../helpers/fontFamily';
import ScanImageComponent from './ScanImageComponent';
import {
  AddBox,
  GetOrderDetail,
  RemoveBoxItems,
  PrintSlip,
  LoaderShow
} from '../../actions/orderActions';
import {SortList, filterScannedList} from '../../helpers/scanPackFunctions';
import _ from 'lodash';
import {connect} from 'react-redux';
import OrderItem from '../../commons/orderItem';
import {GetProductDetail} from '../../actions/productAction';
import OrderItemImage from '../../commons/orderItemImage';
import DisplayQuantityInBox from '../../commons/displayQunatityInBox';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ScannedKitItem from '../../commons/scannedKitItem';
import ScannedKitItemContainer from '../../commons/ScannedKitItemContainer';
let count = 0;

class ScannedItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      prevButton: 0,
      nextButton: 0,
      scannedItemPerPage: 1,
      imageFlag: [],
      data: {
        filter: 'awaiting',
        order: 'DESC',
        limit: '1',
        offset: '0'
      }
    };
  }
  imageCheck = err => {
    let ll = err.nativeEvent.error.includes('404');
    this.setState({imageFlag: [this.state.imageFlag, ll]});
  };

  componentWillUnmount(){
    let unscannedList = this.props?.order?.order?.unscanned_items
    if(unscannedList?.length === 0){
      this.addBoxMultiBox()
    }
    let multiboxSetting = this.props?.bothSettings?.data?.data?.general_setting;
    if(
      unscannedList?.length == 0 &&
      multiboxSetting?.per_box_packing_slips === 'when_order_is_complete' &&
      multiboxSetting?.multi_box_shipments
    ) {
      this.orderCompletePrintSlip();
      return false;
    }
  }

  orderCompletePrintSlip = () => {
    let arrayempty = [];
    this?.props?.Order?.boxes?.map((item, index) => {
      arrayempty.push(item.id);
    });
    let item = {
      box_ids: arrayempty,
      orderArray: [{id: this.props?.Order?.basicinfo?.id}]
    };
    this.props.PrintSlip(item);
  };

  GetOrderDetail = async detail => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    const updatedData = await axios({
      method: 'get',
      url: `${url}/orders/${detail?.id?.id}.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    });
    return updatedData;
  };

  addBoxMultiBox = async () => {
    const updatedDataAPI = await this.GetOrderDetail({id: this.props?.order?.order});
    let addBoxPrintSlip =
      this.props?.bothSettings?.data?.data?.general_setting
        ?.per_box_packing_slips;
    let boxItemProps = updatedDataAPI?.data?.order?.order_item_boxes;
    // let boxItemProps = this.props?.Order?.order_item_boxes;
    let boxProps = this.props?.Order?.boxes;
    let boxFilter = boxItemProps.filter(
      i => i.box_id == boxProps[boxProps.length - 1]?.id
    );
    let item = {
      name: `Box ${this?.props?.Order?.boxes.length + 1}`,
      order_id: this.props?.Order?.basicinfo?.id
    };
    if (boxItemProps[boxProps?.length - 1] && boxFilter.length > 0) {
      let detail = {
        id: this.props?.Order?.basicinfo?.id,
        orderDetail: this.state.data
      };
      this.props.AddBox(item, this.props.goNext, this.props?.Order?.boxes);
      if (addBoxPrintSlip === 'when_new_boxes_are_started') {
        this.printSlip();
      }
    } else {
      this.props?.bothSettings?.data?.data?.general_setting?.multi_box_shipments && this.props.multiBoxStateUpdate();
    }
  };

  removeBoxItem = async (box_id, order_item_id, kit_product_id) => {
    const id = this.props?.Order?.basicinfo?.id;
    let data = {
      box_id,
      kit_product_id,
      order_item_id,
      id
    };
    this.props.RemoveBoxItems(data);
    setTimeout(() => {
      this.props.updateOrder(this?.props?.order?.order?.increment_id);
    }, 300);
  };

  printSlip = () => {
    let item = {
      box_ids: this.props?.Order?.boxes[this.props?.activeBoxIndex]?.id,
      orderArray: [{id: this.props?.Order?.basicinfo?.id}]
    };
    this.props.PrintSlip(item);
  };

  checky = boxId => {
    if (boxId) {
      return this.props?.Order.order_item_boxes
        .filter(data => data.box_id === boxId)
        .map(data => data.item_qty)
        .reduce((a, b) => a + b, 0);
    }
    return 0;
  };
  render() {
    const scannedItems = 
      this.props?.order?.order?.scanned_items
    SortList(this.props?.Order?.order_item_boxes);
    let updatedScannedlist = filterScannedList(this.props?.order?.order?.scanned_items)  
    let multiBoxLength = this.props?.Order?.order_item_boxes?.length;
    const {
      bothSettings: {
        data: {
          data: {
            general_setting: {multi_box_shipments}
          }
        }
      }
    } = this.props;
    const windowHeight = Dimensions.get('window').height;
    let props = this.props;
    let localCount = 0;
    this.props?.order?.order?.scanned_items &&
      this.props.order.order.scanned_items.length > 0 &&
      this.props.order.order.scanned_items.map(item => {
        if (item?.child_items?.length === 0 || !item?.child_items) {
          localCount = localCount + item?.scanned_qty;
        }
      });
    count = localCount;
    let perPage = this.state.scannedItemPerPage * 10;
    return (
      <View
        style={
          Platform.OS === 'web'
            ? styles.scannedItemContainerWeb
            : styles.scannedItemContainer
        }>
        {Platform.OS === 'web' ? (
          <View style={{flex: 1}}>
            <ImageBackground source={backNewImage} style={{height: '100%'}}>
              {multi_box_shipments && scannedItems?.length > 0 ? (
                <View
                  testID="multiBox"
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#000',
                    padding: multiBoxLength === 0 ? 27 : 0
                  }}>
                  <Text
                    style={{
                      color: '#D7D7D7',
                      marginTop: '3px',
                      fontSize: '18px',
                      fontFamily: 'Poppins_600SemiBold'
                    }}>
                    {this.props?.Order?.boxes.length > 0 &&
                      this.props?.Order?.boxes[this.props.activeBoxIndex]?.name}
                  </Text>
                  {this.props?.Order?.boxes[this.props.activeBoxIndex]?.id && (
                    <Text
                      style={{
                        color: 'white',
                        fontSize: '18px',
                        fontFamily: 'Poppins_300Light'
                      }}>
                      {this.checky(
                        this.props?.Order?.boxes[this.props.activeBoxIndex]?.id
                      )}{' '}
                      of {count} Scanned Items
                    </Text>
                  )}
                </View>
              ) : (
                <Text
                  dataSet={{componentName: 'scannedQtyCount'}}
                  style={[
                    styles.scannedItemTextWeb,
                    {fontFamily: fontFamily.font600}
                  ]}>
                  {count} Scanned Items
                </Text>
              )}
              <View>
                {this.props?.Order?.boxes?.length > 1 &&
                  this.props.activeBoxIndex >= 1 && (
                    <View
                      style={{
                        color: 'white',
                        position: 'absolute',
                        marginTop: '0.5%',
                        fontSize: '21px',
                        left: 10
                      }}>
                      <TouchableOpacity
                        style={{flexDirection: 'row', marginTop: '2.5%'}}
                        testID="goPrevIcon"
                        onPress={() => this.props.goPrev()}>
                        <i
                          className="icon-up-open"
                          style={{rotate: '270deg'}}></i>
                        <Text
                          style={{
                            color: 'white',
                            fontSize: '18px'
                          }}>
                          Back
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
              </View>
              {(multi_box_shipments &&
                props?.order?.order?.scanned_items?.length > 0) ||
              props.Order?.order_item_boxes > 0 ? (
                <>
                  {this.props.LoaderDetail.loading ? (
                    <View style={{height: '640px', justifyContent: 'center'}}>
                      <ActivityIndicator size="large" />
                    </View>
                  ) : (
                    <View style={{height: '90%', zIndex: '-1'}}>
                      {this.props.Order?.boxes[this.props?.activeBoxIndex] && (
                        <LinearGradient
                          style={{
                            height: '30px',
                            marginBottom: '5%'
                          }}
                          start={{x: 0, y: 1}}
                          end={{x: 0, y: 0}}
                          colors={['#142130', '#304454']}>
                          <TouchableOpacity
                            testID="printSlip"
                            style={{
                              color: 'white',
                              position: 'absolute',
                              right: 105,
                              marginTop: '1%',
                              width: '5%'
                            }}
                            onPress={() => {
                              this.printSlip();
                            }}>
                            <i
                              className="icon-print"
                              style={{fontSize: '21px'}}></i>
                          </TouchableOpacity>
                          {this.props.activeBoxIndex !==
                            this.props.Order?.boxes?.length - 1 &&
                            this.props.activeBoxIndex !==
                              this.props.Order?.boxes?.length && (
                              <View
                                style={{
                                  color: 'white',
                                  position: 'absolute',
                                  marginTop: '0.5%',
                                  fontSize: '21px',
                                  right: 10
                                }}>
                                <TouchableOpacity
                                  style={{
                                    flexDirection: 'row',
                                    marginTop: '2.5%'
                                  }}
                                  testID="goNextIcon"
                                  onPress={() => this.props.goNext()}>
                                  <Text
                                    style={{
                                      color: 'white',
                                      fontSize: '18px'
                                    }}>
                                    Next
                                  </Text>
                                  <i
                                    className="icon-up-open"
                                    style={{rotate: '90deg'}}></i>
                                </TouchableOpacity>
                              </View>
                            )}

                          {this.props.activeBoxIndex ==
                            this.props.Order?.boxes?.length - 1 && (
                            <TouchableOpacity
                              testID="addBoxIcon"
                              style={{
                                position: 'absolute',
                                marginTop: '1%',
                                fontSize: '21px',
                                left: '90%',
                                color: 'white'
                              }}
                              dataSet={{ componentName: 'addBoxIcon'}}
                              onPress={async () => {
                                this.addBoxMultiBox();
                                // await this.props.GetOrderDetail({ id: this.props?.order?.order?.id })
                              }}>
                              <i className="icon-plus"></i>
                            </TouchableOpacity>
                          )}
                        </LinearGradient>
                      )}
                      {props.Order?.order_item_boxes?.map((order, index) => {
                        return (
                          <>
                            <View>
                              {this.props?.Order?.boxes[
                                this.props.activeBoxIndex
                              ]?.id === order.box_id && (
                                <>
                                  {props?.order?.order &&
                                  props.order.order.scanned_items.length > 0 ? (
                                    <View>
                                      {scannedItems
                                        .slice(0, perPage)
                                        .map((item, index) => {
                                          return (
                                            <View>
                                            {(item?.product_id ===
                                              order?.product_id) && order.kit_id === null &&
                                              order.order_item_id === item?.order_item_id &&
                                              (item && item.product_type === 'single') ||
                                              (item && item.product_type === 'depends') ? 
                                              (
                                              <ScannedKitItem
                                                item = {item}
                                                order = {order}
                                                activeBoxName = {this.props?.Order?.boxes[this.props.activeBoxIndex]?.name}
                                                redirectToItemDetail={item => {
                                                  this.props.redirectToItemDetail(item);
                                                }}
                                                removeBoxItem = {(box_id, order_item_id, kit_id) => 
                                                  {this.removeBoxItem(box_id, order_item_id, kit_id)
                                                }}
                                              />
                                            ):(
                                              <View>
                                              {(item?.scanned_qty > 0) && 
                                              (item?.product_id === order?.product_id) &&
                                              order.order_item_id === item?.order_item_id
                                              && (
                                              <ScannedKitItemContainer
                                                item = {item}
                                                order = {order}
                                                index = {index}
                                                lists = {props.order.order}
                                                activeBoxName = {this.props?.Order?.boxes[this.props.activeBoxIndex]?.name}
                                                redirectToItemDetail={item => {
                                                  this.props.redirectToItemDetail(item);
                                                }}
                                                removeBoxItem = {(box_id, order_item_id, kit_id) => 
                                                  {this.removeBoxItem(box_id, order_item_id, kit_id)
                                                }}
                                              />
                                              )}
                                              </View>
                                            )}
                                            </View>
                                          );
                                        })}
                                    </View>
                                  ) : (
                                    <View style={globalStyles.p_10}></View>
                                  )}
                                </>
                              )}
                            </View>
                          </>
                        );
                      })}
                    </View>
                  )}
                </>
              ) : (
                <>
                  {' '}
                  {props?.order?.order &&
                  props.order.order.scanned_items.length > 0 ? (
                    <View>
                      {updatedScannedlist
                        .slice(0, perPage)
                        .map((item, index) => {
                          return (
                            <View key={item?.id}>
                              {(item.scanned_qty > 0 || item.skippable ) &&
                              item?.product_type != "individual" &&
                              (item?.child_items?.length === 0 || item?.child_items === undefined || item?.product_type==='depends') && (
                                <View
                                  key={item?.id}
                                  style={{
                                    flexDirection: 'row',
                                    position: 'relative',
                                    backgroundColor: '#292929',
                                    textAlign: 'left',
                                    display: 'flex',
                                    borderRadius: 12,
                                    marginTop: 5
                                  }}>
                                  <OrderItem
                                    item={item}
                                    redirectToItemDetail={item => {
                                      this.props.redirectToItemDetail(item);
                                    }}
                                    testID={'redirectToItem'}
                                  />
                                  <OrderItemImage
                                    item={item}
                                    shuffleTheItem={() => {}}
                                  />
                                </View>
                              )}
                            </View>
                          );
                        })}
                    </View>
                  ) : (
                    <View style={globalStyles.p_10} testID="emptyList"></View>
                  )}
                </>
              )}
              {this.props.LoaderDetail.loading === false &&
                props.order.order.scanned_items &&
                props.order.order.scanned_items.length >= perPage && (
                  <View style={{marginTop: '5%'}}>
                    {props.order.order.scanned_items &&
                    props.order.order.scanned_items.length >= perPage ? (
                      <TouchableOpacity
                        testID="showMore"
                        onPress={() => {
                          let perPage = this.state.scannedItemPerPage + 1;
                          this.setState({scannedItemPerPage: perPage});
                        }}>
                        <Text
                          style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: 14,
                            zIndex: 99999,
                            textShadowColor: '#000',
                            textShadowOffset: {width: 1, height: 1},
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 8,
                            background: '#41970f',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            borderRadius: 5,
                            marginTop: 2
                          }}>
                          Show More
                        </Text>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          let perPage = 1;
                          this.setState({scannedItemPerPage: perPage});
                        }}>
                        <Text
                          style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: 14,
                            zIndex: 99999,
                            textShadowColor: '#000',
                            textShadowOffset: {width: 1, height: 1},
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 8,
                            background: '#f54400',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            borderRadius: 5,
                            marginTop: 2
                          }}>
                          Reset
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
            </ImageBackground>
          </View>
        ) : (
          <View style={{height: windowHeight - 90, width: '100%'}}>
            {props.order &&
            props.order.order &&
            props.order.order.scanned_items.length > 0 ? (
              scannedItems.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.scanned_qty > 0 && (
                      <View
                        key={index}
                        style={{
                          flexDirection: 'row',
                          margin: 0,
                          borderRadius: 30,
                          borderWidth: 4,
                          backgroundColor: '#336598',
                          borderColor: 'black',
                          padding: 10
                        }}>
                        <View style={{flex: 20}}>
                          <TouchableOpacity
                            style={{
                              backgroundColor: '#fff',
                              padding: 5,
                              borderTopLeftRadius: 20,
                              borderTopRightRadius: 20
                            }}>
                            <Text
                              numberOfLines={2}
                              style={
                                item.name && item.name.length <= 32
                                  ? {
                                      fontSize: 20,
                                      maxWidth: '75%',
                                      lineHeight: '100%'
                                    }
                                  : {
                                      fontSize: 16,
                                      maxWidth: '75%',
                                      lineHeight: '100%'
                                    }
                              }>
                              {item.name}
                            </Text>
                          </TouchableOpacity>
                          {item.sku ? (
                            <Text
                              numberOfLines={1}
                              ellipsizeMode="tail"
                              style={{
                                color: '#fff',
                                fontSize: 20,
                                fontWeight: '600',
                                maxWidth: '70%'
                              }}>
                              <Text
                                style={{
                                  fontSize: 12,
                                  fontFamily: fontFamily.font400,
                                  fontWeight: 'bold'
                                }}>
                                SKU :{' '}
                              </Text>
                              {item.sku}
                            </Text>
                          ) : (
                            <></>
                          )}
                          <View
                            style={{
                              backgroundColor: '#95b0e5',
                              paddingLeft: 3,
                              paddingRight: 3
                            }}>
                            <View
                              style={{maxWidth: '70%', flexDirection: 'row'}}>
                              {item.location !== null &&
                                item.location !== '' &&
                                item.location !== undefined && (
                                  <View
                                    style={{
                                      flex: 1,
                                      flexDirection: 'row',
                                      alignItems: 'baseline',
                                      height: 25
                                    }}>
                                    <Text>L1 : </Text>
                                    <Text
                                      numberOfLines={1}
                                      ellipsizeMode="tail"
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        paddingRight: 10
                                      }}>
                                      {item.location}
                                    </Text>
                                  </View>
                                )}
                              {item.custom_product_1 !== null &&
                                item.custom_product_1 !== '' &&
                                item.custom_product_1 !== undefined && (
                                  <View
                                    style={{
                                      flex: 1,
                                      flexDirection: 'row',
                                      alignItems: 'baseline',
                                      height: 25
                                    }}>
                                    <Text>C1:</Text>
                                    <Text
                                      numberOfLines={1}
                                      ellipsizeMode="tail"
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 'bold'
                                      }}>
                                      {item.custom_product_1}
                                    </Text>
                                  </View>
                                )}
                            </View>
                          </View>
                          <View
                            style={{
                              backgroundColor: '#d3def4',
                              padding: 3,
                              borderBottomLeftRadius: 20,
                              borderBottomRightRadius: 20
                            }}>
                            <Text
                              style={{fontSize: 20, maxWidth: '75%'}}
                              numberOfLines={1}
                              ellipsizeMode="tail">
                              {item &&
                                item.barcodes.length > 0 &&
                                item.barcodes[0].barcode}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            flex: 5,
                            borderBottomRightRadius: 12,
                            borderTopRightRadius: 12,
                            position: 'absolute',
                            right: 6,
                            width: '25%',
                            height: '100%',
                            bottom: 0
                          }}>
                          <View
                            style={{
                              backgroundColor: '#fff',
                              shadowColor: '#000',
                              shadowOffset: {width: -5, height: 2},
                              shadowOpacity: 0.25,
                              shadowRadius: 3.84,
                              elevation: 3,
                              borderRadius: 12,
                              borderWidth: 8,
                              borderColor: '#336598',
                              height: '100%'
                            }}>
                            <Text
                              style={{
                                position: 'absolute',
                                textAlign: 'center',
                                fontSize: 18,
                                fontWeight: '600',
                                backgroundColor: '#fff',
                                opacity: 0.7,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: 999
                              }}>
                              {item.scanned_qty}/
                              {item.qty_remaining + item.scanned_qty}
                            </Text>
                            {item?.images && item?.images?.length > 0 ? (
                              <Image
                                defaultSource={placeholder}
                                source={{uri: item.images[0].image}}
                                style={{
                                  height: '100%',
                                  width: '96%',
                                  resizeMode: 'cover',
                                  borderRadius: 12
                                }}
                              />
                            ) : (
                              <Image
                                source={placeholder}
                                style={{
                                  height: '100%',
                                  width: '96%',
                                  resizeMode: 'cover',
                                  borderRadius: 12
                                }}
                              />
                            )}
                          </View>
                        </View>
                      </View>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <View style={globalStyles.p_10}></View>
            )}
          </View>
        )}
      </View>
    );
  }
}

const mapDispatchToProps = {
  AddBox,
  GetOrderDetail,
  RemoveBoxItems,
  GetProductDetail,
  PrintSlip,
  LoaderShow
};
const mapStateToProps = state => {
  return {
    LoaderDetail: state.orderLoader
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScannedItems);
