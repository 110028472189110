import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import downArrow from '../../../assets/down_arrow.png';
import dropdownStyles from '../../style/dropdownStyles';
import usePersistentMenuState from '../../commons/usePersistentMenuState';

const CustomTouchableOpacity = ({
  onPress,
  handleStatusSelect,
  buttonName,
  buttonText,
  orderCount,
  testID
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={dropdownStyles.customTouchable}
      testID={testID}>
      <View style={dropdownStyles.customTouchableContent}>
        <Text style={dropdownStyles.customTouchableCount}>{orderCount} </Text>
        <Text
          style={[
            handleStatusSelect?.includes(buttonName) &&
              dropdownStyles.dropdownActiveButton,
            dropdownStyles.customTouchableText,
            buttonName === 'unassigned' && dropdownStyles.notAssignedStyle
          ]}>
          {buttonText || buttonName}
        </Text>
        <TouchableOpacity
          onPress={onPress}
          style={dropdownStyles.customTouchableReset}
          testID={testID + '_reset'}>
          {handleStatusSelect.includes(buttonName) && (
            <i
              className="icon-cancel"
              style={dropdownStyles.customTouchableIcon}></i>
          )}
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

const UserTagsDropdown = ({
  getFilteredOrders,
  resetFilters,
  assignedUserTags,
  clearAllFilters,
  resetFilterCheck,
  status,
  setSelectedUsers,
  setFilterIncludedTags,
  filterIncludedTags
}) => {
  const [isOpen, toggleDropdown] = usePersistentMenuState('userTagsDropdown');
  const [selectedStatus, setSelectedStatus] = useState(() => [status]);


  const handleStatusSelect = status => {
    let statues;
    let isPresent = false;
    setSelectedStatus(prevSelectedStatus => {
      if (!prevSelectedStatus.includes(status)) {
        statues = [status];
        return [status];
      } else {
        statues = [prevSelectedStatus];
        isPresent = true;
        return prevSelectedStatus;
      }
    });
    isPresent === true ? disableFilter(status) : getFilteredOrders(statues);
    setSelectedUsers(statues, filterIncludedTags);
    getFilteredOrders(status);
  };

  const disableFilter = statusToRemove => {
    let updatedStatus = [];
    setSelectedStatus(prevSelectedStatus => {
      updatedStatus = prevSelectedStatus.filter(
        status => status !== statusToRemove
      );
      return updatedStatus;
    });
    resetFilters(updatedStatus);
  };

  useEffect(() => {
    if (clearAllFilters === true) {
      setSelectedStatus([]);
      resetFilterCheck();
    }
  }, [clearAllFilters]);

  useEffect(() => {
    if (selectedStatus) {
      setSelectedUsers(selectedStatus);
    }
  }, [selectedStatus]);

  return (
    <View style={{borderTop: '2px solid rgb(0, 0, 0)', marginTop: '1rem'}}>
      <TouchableOpacity
        style={dropdownStyles.button}
        onPress={toggleDropdown}
        testID="toggleButton">
        <View style={dropdownStyles.buttonContent}>
          <Text style={dropdownStyles.text}>Users</Text>
          <Image
            style={[dropdownStyles.icon, isOpen && dropdownStyles.iconOpen]}
            source={downArrow}
          />
        </View>
        {isOpen && (
          <View>
            <Text style={[dropdownStyles.text, dropdownStyles.includeTagsText]}>
              Assigned To:
            </Text>
          </View>
        )}
      </TouchableOpacity>
      {isOpen && (
        <View style={dropdownStyles.dropdownContent}>
          {assignedUserTags?.map(tag => (
            <CustomTouchableOpacity
              key={tag.username}
              onPress={() => {
                setFilterIncludedTags(true);
                handleStatusSelect(tag.username);
              }}
              handleStatusSelect={selectedStatus}
              buttonName={tag.username}
              buttonText={
                tag.username === 'unassigned'
                  ? 'Not Assigned to a User'
                  : tag.username
              }
              orderCount={tag.unique_order_count}
              testID={`${tag.username.toLowerCase()}_button`}
            />
          ))}
        </View>
      )}
    </View>
  );
};

export default UserTagsDropdown;
