import React, {Component} from 'react';
import i18n from 'i18n-js';
import styles from '../../style/popupmodel';
import CommonStyles from '../../style/commonStyles';
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import DefaultInput from '../../commons/Inputs/defaultInput';
export default class AlertBoxTrackingNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width
    };
    this.trackingFocusAlert = React.createRef();
  }
  componentDidUpdate() {
    this.trackingFocusAlert.current.focus();
  }
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  render() {
    return (
      <>
        <View style={styles.alertContainer}>
          <LinearGradient
             testID='alertBoxContainer'
            locations={[0, 1]}
            colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
            style={[
              CommonStyles.gradientContainerTwo,
              {width: this.state.windowWidth >= 600 ? '60%' : '90%'}
            ]}
          >
            <TouchableOpacity
             testID='cancelIcon'
              onPress={this.props.closeAlert}
              style={CommonStyles.cancelIcon}
            >
              <i
                className="icon-cancel"
                style={{fontSize: '22px', color: 'gray'}}
              ></i>
            </TouchableOpacity>
            <Text
              style={CommonStyles.textStyleOne}
            >
              Please try again
            </Text>
            <Text
              style={CommonStyles.textStyleTwo}
            >
              {i18n.t('scanpackItem.Scanpack_Item_tracking_msg')}
            </Text>
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <DefaultInput
                {...this.props}
                placeholder="Scan"
                name="trackingOrder"
                autoFocus={true}
                ref={this.trackingFocusAlert}
                value={this.props.trackingOrderInput}
                onChangeText={this.props.onChangeText}
                onSubmitEditing={this.props.onSubmitEditing}
                style={[styles.alertBoxTracking, {width: '94%'}]}
              />
            </View>
          </LinearGradient>
        </View>
      </>
    );
  }
}
