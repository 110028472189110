import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_FAIL,
  GET_ALL_PRODUCT,
  GET_SEARCH_PRODUCT,
  CLEAR_SEARCH_PRODUCT,
  UPDATE_PRODUCT_INFO,
  UPDATE_PRODUCT_ALIAS,
  UPDATE_PRODUCT_INFO_FAIL,
  UPDATE_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST_FAIL,
  ALL_KIT_PRODUCT,
  GET_KIT_PRODUCT,
  REMOVE_KIT_PRODUCT,
  ADD_IMAGE,
  ADD_HTML_IMAGE,
  UPDATE_PRODUCT_ALIAS_FAIL,
  ADD_IMAGE_FAIL,
  GET_KIT_PRODUCT_FAIL,
  ALL_KIT_PRODUCT_FAIL,
  GET_ALL_PRODUCT_FAIL,
  GET_SEARCH_PRODUCT_FAIL,
  IS_LOADING,
  IS_PRODUCT_CREATED,
  IS_PRODUCT_BARCODE_ADDED,
  CREATE_PRODUCT,
  ADD_PDF,
  GET_PDFS,
  IS_PRODUCT_UPDATED,
  IS_PRODUCT_LOADING
} from '../constants';

export function GetProductDetail(id) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    if (id !== 0) {
      axios({
        method: 'get',
        url: `${url}/products/${id}.json`,
        headers: {Authorization: `Bearer ${access_token}`}
      })
        .then(data => {
          // console.log("actionproductdetail......", data)
          dispatch({
            type: PRODUCT_DETAIL,
            payload: data.data.product
          });
        })
        .catch(error => {
          dispatch({
            type: PRODUCT_DETAIL_FAIL,
            payload: error?.response?.status
          });
        });
    } else {
      dispatch({
        type: PRODUCT_DETAIL_FAIL,
        payload: null
      });
    }
  };
}

export function GetProductInfoDetail(id) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    dispatch({
      type: IS_PRODUCT_LOADING,
      payload: true
    });
    if (id !== 0) {
      axios({
        method: 'get',
        url: `${url}/products/${id}.json`,
        headers: {Authorization: `Bearer ${access_token}`}
      })
        .then(data => {
          // console.log("actionproductdetail......", data)
          dispatch({
            type: PRODUCT_DETAIL,
            payload: data.data.product
          });
          dispatch({
            type: IS_PRODUCT_LOADING,
            payload: false
          });
        })
        .catch(error => {
          dispatch({
            type: PRODUCT_DETAIL_FAIL,
            payload: error?.response?.status
          });
          dispatch({
            type: IS_PRODUCT_LOADING,
            payload: false
          });
        });
    } else {
      dispatch({
        type: PRODUCT_DETAIL_FAIL,
        payload: null
      });
      dispatch({
        type: IS_PRODUCT_LOADING,
        payload: false
      });
    }
  };
}

export function GetAllProduct(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/products.json?filter=${data.filter}&sort=${data.sort}&order=${data.order}&is_kit=${data.is_kit}&limit=${data.limit}&offset=${data.offset}`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(res => {
        // console.log("getalllproducts......", res.data)
        dispatch({
          type: GET_ALL_PRODUCT,
          payload: res.data.products
        });
      })
      .catch(error => {
        dispatch({
          type: GET_ALL_PRODUCT_FAIL,
          payload: error?.response?.status
        });
      });
  };
}

export function ClearSearchProduct() {
  return dispatch => {
    dispatch({
      type: CLEAR_SEARCH_PRODUCT
    });
  };
}

export function GetSearchProduct(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    dispatch({
      type: IS_LOADING,
      payload: true
    });
    axios({
      method: 'get',
      url: `${url}/products/search.json?search=${data.search}&sort=${data.sort}&order=${data.order}&limit=${data.limit}&offset=${data.offset}&app=${data.app}`,
      headers: {Authorization: `Bearer ${access_token}`},
      // data: { app: 'app'}
    })
      .then(res => {
        // console.log("Success response here ==>",res)
        dispatch({
          type: GET_SEARCH_PRODUCT,
          payload: res.data.products
        });
        dispatch({
          type: IS_LOADING,
          payload: false
        });
      })
      .catch(error => {
        // console.log("error message here == >",error);
        dispatch({
          type: GET_SEARCH_PRODUCT_FAIL,
          payload: error
        });
        dispatch({
          type: IS_LOADING,
          payload: false
        });
      });
  };
}
// kit info action
export function AllKITProduct(productID, data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/products/${productID}/add_product_to_kit.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(res => {
        dispatch({
          type: ALL_KIT_PRODUCT,
          payload: res.data.products
        });
      })
      .catch(error => {
        dispatch({
          type: ALL_KIT_PRODUCT_FAIL,
          payload: error?.response?.status
        });
      });
  };
}
export function GETAllKITProduct(productID) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/products/${productID}.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(res => {
        // console.log('.........................................', res.data)
        dispatch({
          type: GET_KIT_PRODUCT,
          payload: res.data.product.productkitskus
        });
      })
      .catch(error => {
        dispatch({
          type: GET_KIT_PRODUCT_FAIL,
          payload: error?.response?.status
        });
      });
  };
}
export function RemoveKITProduct(productID, data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'POST',
      url: `${url}/products/${productID}/remove_products_from_kit.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    }).then(res => {
      dispatch({
        type: REMOVE_KIT_PRODUCT,
        payload: res.data.product.productkitskus
      });
    });
  };
}
export function UpdateProductInfo(ID, data, increment_id) {
  let order_id = {order_id: increment_id};
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    if (ID > 0) {
      axios({
        method: 'put',
        url: `${url}/products/${ID}.json`,
        headers: {Authorization: `Bearer ${access_token}`},
        data : {...data,...order_id}
      })
        .then(async res => {
          dispatch({
            type: UPDATE_PRODUCT_INFO,
            payload: {
              data: res.data,
              time: new Date()
            }
          });
        })
        .catch(error => {
          dispatch({
            type: UPDATE_PRODUCT_INFO_FAIL,
            payload: {
              data: error?.response?.status,
              time: new Date()
            }
          });
        });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_INFO_FAIL,
        payload: null,
        time: new Date()
      });
    }
  };
}

export function updateProductAlias(ID, data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    dispatch({
      type: IS_PRODUCT_UPDATED,
      payload: true
    });
    axios({
      method: 'post',
      url: `${url}/products/${ID}/set_alias.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(async res => {
        dispatch({
          type: UPDATE_PRODUCT_ALIAS,
          payload: res.data,
          time: new Date()
        });
        dispatch({
          type: IS_PRODUCT_UPDATED,
          payload: false
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_PRODUCT_ALIAS_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
        dispatch({
          type: IS_PRODUCT_UPDATED,
          payload: false
        });
      });
  };
}

export function updateProductList(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    if (data !== null) {
      axios({
        method: 'post',
        url: `${url}/products/update_product_list.json`,
        headers: {Authorization: `Bearer ${access_token}`},
        data
      })
        .then(async res => {
          dispatch({
            type: UPDATE_PRODUCT_LIST,
            payload: {
              data: res.data,
              time: new Date()
            }
          });
        })
        .catch(error => {
          dispatch({
            type: UPDATE_PRODUCT_LIST_FAIL,
            payload: {
              data: error?.response?.status,
              time: new Date()
            }
          });
        });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_LIST_FAIL,
        payload: {
          data: '',
          time: new Date()
        }
      });
    }
  };
}

export function printScan(ID,barcodeQtyValue,isBarcodeNumber) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/products/${ID}/generate_barcode_slip.pdf?${isBarcodeNumber?"barcode":"barcode_qty"}=${barcodeQtyValue}`,
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      responseType: 'blob'
    }).then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  };
}
export async function printReceiving(ID) {
  const access_token = await AsyncStorage.getItem('access_token');
  const url = await AsyncStorage.getItem('url');
  let data = await axios({
    method: 'post',
    url: `${url}/products/print_receiving_label.json`,
    headers: {Authorization: `Bearer ${access_token}`},
    data: {
      sort: '',
      order: 'DESC',
      filter: 'active',
      search: '',
      select_all: false,
      inverted: false,
      is_kit: 0,
      limit: 20,
      offset: 0,
      setting: '',
      status: '',
      productArray: [{id: ID}]
    }
    
  });
  return data;
}

export async function getData(ID, barcodeQtyValue,isBarcodeNumber) {
  const access_token = await AsyncStorage.getItem('access_token');
  const url = await AsyncStorage.getItem('url');
  let printData = await axios({
    method: 'get',
    url: `${url}/products/${ID}/generate_barcode_slip.pdf?${isBarcodeNumber?"barcode":"barcode_qty"}=${barcodeQtyValue}`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    responseType: 'blob'
  });

  return printData.config.url;
}

export async function updateIsPrinted(data) {
  const access_token = await AsyncStorage.getItem('access_token');

  const url = await AsyncStorage.getItem('url');
  let printData = await axios({
    method: 'put',
    url: `${url}/print_pdf_links/${data.id}/update_is_printed.json`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    data
  });
  return printData;
}

export function getPdfData(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/print_pdf_links/get_pdf_list.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(res => {
        dispatch({
          type: GET_PDFS,
          payload: {data: res.data, time: new Date()}
        });
      })
      .catch(error => { console.log(error);});
  };
}

export function addPdf(data){
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/print_pdf_links.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(data => {
        dispatch({
          type: ADD_PDF,
          payload: true
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}


let count = 1;
export function addImage(ID, data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    // const formData= new FormData()
    const img = data.substring(data.indexOf(',') + 1);
    const type = data.substring(data.indexOf('/') + 1, data.indexOf(';'));
    // console.log("type here------->>>>>>>",data)
    const datanew = {
      base_64_img_upload: true,
      product_image: {
        image: img,
        content_type: `image/${type}`,
        original_filename: `product${count}.${type}`
      }
    };
    // formData.append('base_64_img_upload', true)
    // formData.append('content_type','image/png')
    // formData.append('original_filename','product.png')
    // formData.append('product_image',JSON.stringify({'image':data}))

    // formData.append('image_type',mediaTypes)
    axios({
      method: 'post',
      url: `${url}/products/${ID}/add_image.json`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        'content-type': 'application/json'
      },
      data: datanew
    })
      .then(res => {
        // console.log("resss-->",res)
        if (res.data.status) {
          count += 1;
        }
        dispatch({
          type: ADD_IMAGE,
          payload: {
            product_image: res.data.status
          }
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_IMAGE_FAIL,
          payload: {
            product_image: error.response.status
          }
        });
      });
  };
}

export function uploadHtmlImage(ID, data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    const img = data.substring(data.indexOf(',') + 1);
    const type = data.substring(data.indexOf('/') + 1, data.indexOf(';'));
    const datanew = {
      base_64_img_upload: true,
      product_image: {
        image: img,
        content_type: `image/${type}`,
        original_filename: `product${count}.${type}`
      }
    };
    axios({
      method: 'post',
      url: `${url}/products/${ID}/convert_and_upload_image.json`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        'content-type': 'application/json'
      },
      data: datanew
    })
      .then(res => {
        if (res.data.status) {
          count += 1;
        }
        dispatch({
          type: ADD_HTML_IMAGE,
          payload: {
            product_image: res.data.uri
          }
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_IMAGE_FAIL,
          payload: {
            product_image: error.response.status
          }
        });
      });
  };
}

export async function barcodeQtyAPi(id, qty, whid,productId) {
  const access_token = await AsyncStorage.getItem('access_token');
  const url = await AsyncStorage.getItem('url');
  let data = await axios({
    method: 'put',
    url: `${url}/products/${id}/adjust_available_inventory.json`,
    headers: {Authorization: `Bearer ${access_token}`},
    data: {
      method: 'receive',
      inv_wh_id: whid,
      product_barcode: productId,
      id: id,
      inventory_count: '',
      barcodes_qty: qty,
      location_primary: '',
      location_secondary: '',
      location_tertiary: ''
    }
  });
  return data;
}
export function SetIsProductCreated() {
  return dispatch => {
    dispatch({
      type: IS_PRODUCT_CREATED,
      payload: true
    });
  };
}

export function SetIsProductBarCodeAdded(val) {
  return dispatch => {
    dispatch({
      type: IS_PRODUCT_BARCODE_ADDED,
      payload: val
    });
  };
}

export function CreateProduct() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/products.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(data => {
        dispatch({
          type: CREATE_PRODUCT,
          payload: data.data.product.id
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function resetIsProductUpdated() {
  return async dispatch => {
    dispatch({
      type: IS_PRODUCT_UPDATED,
      payload: ''
    });
  };
}

export function resetAddPdf() {
  return async dispatch => {
    dispatch({
      type: ADD_PDF,
      payload: false
    });
  };
}

export function resetIsProductInfo() {
  return async dispatch => {
    dispatch({
      type: IS_PRODUCT_LOADING,
      payload: ''
    });
  };
}
