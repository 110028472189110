import React, { Component } from 'react';
import { View, TextInput, TouchableOpacity, FlatList, SafeAreaView } from 'react-native';
import { connect } from 'react-redux';
import { GetSearchProduct } from '../../actions/productAction';
import styles from '../../style/productdetail';
import { Text } from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import i18n from 'i18n-js';

let addItemSuccess = true;
let update = true;
let updateSearch = false;
class ProductSearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      getAllItem: {
        filter: 'all',
        sort: '',
        order: 'DESC',
        is_kit: 0,
        limit: 20,
        offset: 0
      },
      searchData: {
        search: '',
        sort: '',
        order: 'DESC',
        is_kit: -1,
        limit: 20,
        offset: 0
      },
      allProduct: [],
      selected: [],
      searchProduct: [],
      existingItems: [],
      orderID: '',
      windowWidth: 0,
      checkWidth: 0,
      searchTxt: '',
      start: 0,
      end: 10,
      quickScanToolTip3: false,
      presentkitid: []
    };
    window.addEventListener('resize', this.update);
    this.tooltipRef = React.createRef();
    this.searchField = React.createRef();
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  componentDidMount() {
    const { product, items, productSearchList, GetAllProduct } = this.props;
    this.setState({ allProduct: productSearchList });
    this.update();
    // let product = this.props?.product;

    if (product) {
      let existingItems = [];
      items?.items.map((item, index) => {
        existingItems.push(item.productinfo.id);
      });
      update = true;
      this.setState({ existingItems });
      GetAllProduct(this.state.getAllItem);
    }
    if (items?.items?.length) {
      let sorted = this.props.items.items.sort(
        (a, b) =>
          Date.parse(a.iteminfo?.updated_at) -
          Date.parse(b.iteminfo?.updated_at)
      );
      this.setState({ items: sorted });
    }
    this.state.searchData['search'] = this.props.lastSearched;
  }
  componentDidUpdate() {
    const { searchProduct } = this.props
    this.searchField.current.focus()
    if (
      updateSearch === false && searchProduct && this.state.searchProduct !== searchProduct
    ) {
      let temp = this.state.searchData;
      this.setState({
        allProduct: this.props?.searchProduct,
        searchProduct: this.props?.searchProduct,
        temp
      });
      update = false;
      updateSearch = true;
    }
  }
  submitItems() {
    let { product } = this.props
    const { selected } = this.state;
    // let productID = this.props?.product?.basicinfo.id;
    if (product?.basicinfo?.id && selected.length > 0) {
      let data = {
        product_ids: selected
      };
      this.props.AllKITProduct(product?.basicinfo?.id, data);
      setTimeout(() => this.props?.AddItemToInventory(), 1000);
      addItemSuccess = true;
    }
  }
  searchItem() {
    let search = this.state.searchData;
    if (search.search !== '') {
      this.props?.GetSearchProduct(search);
      updateSearch = false;
    }
  }
  redirectToProductDetail = product => {
    let page = 'ProductSearch';
    this.props?.navigation?.navigate('ProductDetail', {
      productInfo: product.id,
      userData: this.props.userInfo,
      page: page,
      oncomeBack: () => this.props.oncomeBack()
    });
  };

  handleChange(text) {
    let searchData = this.state.searchData;
    searchData['search'] = text;
    this.setState({ searchData });
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  render() {
    const colors = {
      active: '#7bfac3',
      inactive: '#979797',
      new: '#FFB538'
    }
    return (
      <LinearGradient
        colors={[
          '#000',
          '#000',
          '#5c778f',
          '#5c778f',
          '#253c57',
          '#253c57',
          '#000'
        ]}
        start={[-0.5, -1]}
        style={{
          height: '100%',
          paddingBottom: 15
        }}
      >
        <View
          onStartShouldSetResponder={()=>{this.setState({ quickScanToolTip3: false });}}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-around',
              marginTop: 10,
              height: 40,
              flexWrap: 'wrap',
              paddingHorizontal: 15,
              zIndex: 9999
            }}
          >
            <View style={{position: 'absolute', top: 7, left: 20}}>
              <TouchableOpacity testID='backButton' onPress={() => this.props.productSearchBack()}>
                <AntDesign
                  name="arrowleft"
                  size={24}
                  color="#778fa5"
                  style={{ fontWeight: 'bold' }}
                />
              </TouchableOpacity>
            </View>
            <View style={{ width: '90%', marginLeft: 50, flexDirection: 'row' }}>
              <TextInput
                placeholder="Type Product to search"
                name="searchOrder"
                autoFocus={true}
                ref={this.searchField}
                value={this.state.searchData && this.state.searchData.search}
                onChangeText={text => {
                  this.handleChange(text), this.setState({ searchTxt: text });
                }}
                onSubmitEditing={e => this.searchItem(e)}
                style={{
                  backgroundColor: '#f4efef',
                  borderRadius: 5,
                  height: 30,
                  paddingHorizontal: 10,
                  flexDirection: 'row',
                  width: '90%',
                  marginLeft: 30
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.searchItem()
                  }
                }}
              />
              <TouchableOpacity
                testID='searchItemButton'
                onPress={() => this.searchItem(this.state.searchTxt)}
                style={{
                  flexDirection: 'row',
                  marginLeft: 10,
                  marginTop: '5.5px'
                }}
              >
                <i
                  className="icon-search"
                  style={{
                    color: 'rgb(172,187,199)',
                    marginTop: '34%',
                    marginLeft: -50,
                    fontSize: '19px'
                  }}
                ></i>
              </TouchableOpacity>
              <TouchableOpacity
                testID='quickScanToolTipBtn'
                onPress={() => {
                  this.setState({
                    quickScanToolTip3: !this.state.quickScanToolTip3
                  });
                }}
                style={{
                  flexDirection: 'row',
                  marginLeft: 10
                }}
              >
                <i
                  className="icon-info-circled"
                  style={{
                    color: 'rgb(172,187,199)',
                    marginTop: '41%',
                    fontSize: '17px',
                    position: 'relative'
                  }}
                ></i>

                {this.state.quickScanToolTip3 && (
                  <LinearGradient
                    testID='quickScanToolTip'
                    locations={[0, 1]}
                    colors={['#8a9daf', '#d7e1e9']}
                    style={{
                      padding: 10,
                      position: 'absolute',
                      top: 37,
                      right: this.state.windowWidth <= 450 ? -3 : -20,
                      borderRadius: 5,
                      width: 300,
                      alignSelf: 'flex-end'
                    }}
                  >
                    <Text style={{ fontSize: 20, color: '#030303' }}>
                      {i18n.t('order.productSearchListTooltip')}
                    </Text>
                    <View
                      style={{
                        position: 'absolute',
                        right: this.state.windowWidth <= 450 ? '3%' : '8%',
                        top: -10,
                        borderLeftWidth: 10,
                        borderLeftColor: 'transparent',
                        borderRightWidth: 10,
                        borderRightColor: 'transparent',
                        borderBottomWidth: 10,
                        borderBottomColor: '#8d9fb1'
                      }}
                    />
                  </LinearGradient>
                )}
              </TouchableOpacity>
            </View>
          </View>
          {this.state.windowWidth > 900 ? (
            <View
              style={[styles.productListLinearView, { paddingLeft: 10, width: '97%', }]}
            >
              <View style={[styles.itemContainerProduct]}>
                <View
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: '1%',
                    width: '100%',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap'
                  }}
                >
                  <View style={{ width: '95%', flexDirection: 'row' }}>
                    <View style={[styles.addItemViewProduct, { width: '25%' }]}>
                      <Text style={styles.gridHeader2Product}>SKU</Text>
                    </View>

                    <View style={[styles.addItemViewProduct, { width: '25%' }]}>
                      <Text style={[styles.gridHeader2Product]}>
                        {i18n.t('product.product_name')}
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.addItemViewProduct,
                        {
                          width: '15%'
                        }
                      ]}
                    >
                      <Text style={styles.gridHeader2Product}>{i18n.t('product.QOH')}</Text>
                    </View>

                    <View style={[styles.addItemViewProduct, { width: '15%' }]}>
                      <Text style={styles.gridHeader2Product}>{i18n.t('product.status')}</Text>
                    </View>
                    <View style={[styles.addItemViewProduct, { width: '23%' }]}>
                      <Text style={styles.gridHeader2Product}>{i18n.t('product.barcodes')}</Text>
                    </View>
                  </View>
                </View>
              </View>

              {this.state.allProduct.length > 0 && (
                <View>
                  {this.state.allProduct &&
                    this?.state?.allProduct?.length > 0 &&
                    this?.state?.allProduct?.map((item, index) => {
                      return (
                        <TouchableOpacity
                          testID='redirectToProductDetail'
                          key={index}
                          onPress={() => {
                            this.redirectToProductDetail(item);
                          }}
                          delayLongPress={1000}
                        >
                          <View style={[styles.itemContainerProduct]}>
                            <View
                              style={{
                                flexDirection: 'row',
                                paddingHorizontal: '1%',
                                width: '100%',
                                flexWrap: 'wrap'
                              }}
                            >
                              <FontAwesome
                                name="circle-o"
                                style={{ resizeMode: 'contain' }}
                                size={32}
                                color="rgba(250, 250, 250, .4)"
                              />
                              <View
                                style={{
                                  width: '95%',
                                  flexDirection: 'row',
                                  borderBottomWidth: 0.7,
                                  borderColor: 'white'
                                }}
                              >
                                <View
                                  style={[
                                    styles.addItemViewProduct,
                                    { width: '25%' }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName2Product,
                                      { fontSize: 16 }
                                    ]}
                                  >
                                    {item.sku}
                                  </Text>
                                </View>

                                <View
                                  style={[
                                    styles.addItemViewProduct,
                                    { width: '25%' }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName2Product,
                                      { fontSize: 16 }
                                    ]}
                                  >
                                    {item.name}
                                  </Text>
                                </View>

                                <View
                                  style={[
                                    styles.addItemViewProduct,
                                    {
                                      width: '16%'
                                    }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName2Product,
                                      { fontSize: 16 }
                                    ]}
                                  >
                                    {item.qty_on_hand}
                                  </Text>
                                </View>

                                <View
                                  style={[
                                    styles.addItemViewProduct,
                                    { width: '14%' }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.statusNewProduct,
                                      { fontSize: 16, color: colors[item.status] }
                                    ]}
                                  >
                                    {this.Capitalize(item.status)}
                                  </Text>
                                </View>

                                <View
                                  style={[
                                    styles.addItemViewProduct,
                                    { width: '25%' }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName2Product,
                                      { fontSize: 16 }
                                    ]}
                                  >
                                    {item.barcode}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      );
                    })}
                </View>
              )}
            </View>
          ) : (
            <View
              style={[styles.productListLinearView, { marginHorizontal: 10, }]}
            >
              <View style={[styles.itemContainerProduct]}>
                <View
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: '2%',
                    width: '100%',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap'
                  }}
                >
                  <View
                    style={{
                      width: '95%',
                      flexDirection: 'row',
                      justifyContent: 'space-around'
                    }}
                  >
                    <View style={[styles.addItemViewProduct, { width: '25%' }]}>
                      <Text style={[styles.gridHeader2Product, { fontSize: 16 }]}>
                        SKU
                      </Text>
                    </View>

                    <View style={[styles.addItemViewProduct, { width: '35%' }]}>
                      <Text style={[styles.gridHeader2Product, { fontSize: 16, lineHeight: 13 }]}>
                        Product Name
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.addItemViewProduct, { width: '10%' }
                      ]}
                    >
                      <Text style={[styles.gridHeader2Product, { fontSize: 16 }]}>
                        QOH
                      </Text>
                    </View>
                    <View style={[styles.addItemViewProduct, { width: '15%' }]}>
                      <Text style={[styles.gridHeader2Product, { fontSize: 16 }]}>
                        Status
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <SafeAreaView>
                {this.props.productSearchList.length > 0 && (
                  <View style={{ overflow: 'hidden' }}>
                    {this.props.productSearchList &&
                      this.props.productSearchList.length > 0 && (
                        <FlatList
                          data={this.props.productSearchList}
                          keyExtractor={item => item.id}
                          renderItem={({ item }) => (
                            <TouchableOpacity
                              testID='redirectToProductMobileView'
                              onPress={() => {
                                this.redirectToProductDetail(item);
                              }}
                            >
                              <View
                                style={[
                                  styles.itemContainerProduct,
                                  {
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }
                                ]}
                              >
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    paddingHorizontal: '2%',
                                    width: '100%',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                  }}
                                >
                                  <FontAwesome
                                    name="circle-o"
                                    style={{
                                      resizeMode: 'contain',
                                      width: '5%',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                    size={24}
                                    color="rgba(250, 250, 250, .4)"
                                  />
                                  <View
                                    style={{
                                      width: '95%',
                                      flexDirection: 'row',
                                      borderBottomWidth: 0.7,
                                      borderColor: 'white',
                                      justifyContent: 'space-around'
                                    }}
                                  >
                                    <View
                                      style={[
                                        styles.addItemViewProduct,
                                        { width: '25%' }
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.itemskuProduct,
                                          { fontSize: 16 }
                                        ]}
                                      >
                                        {item.sku}
                                      </Text>
                                    </View>

                                    <View
                                      style={[
                                        styles.addItemViewProduct,
                                        { width: '35%' }
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.itemName2Product,
                                          { fontSize: 16 }
                                        ]}
                                      >
                                        {item.name}
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        styles.addItemViewProduct,
                                        {
                                          width: '10%'
                                        }
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.itemName2Product,
                                          { fontSize: 16 }
                                        ]}
                                      >
                                        {item.qty_on_hand}
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        styles.addItemViewProduct,
                                        { width: '15%' }
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.statusNewProduct,
                                          { fontSize: 16, color: colors[item.status] }
                                        ]}
                                      >
                                        {this.Capitalize(item.status)}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </TouchableOpacity>
                          )}
                        />
                      )}
                  </View>
                )}
              </SafeAreaView>
            </View>
          )}
        </View>
      </LinearGradient>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchProduct: state.product.searchProduct
  };
};

const mapDispatchToProps = {
  GetSearchProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearchList);
