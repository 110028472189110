import React, { useEffect, useRef } from 'react';
import { StyleSheet, Animated } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { BlurView } from 'expo-blur';
import {Text, View, TouchableOpacity} from 'react-native';
import { CancelTagUpdate } from '../actions/orderActions';

const ImportProgressBar = () => {
  const animatedWidth = useRef(new Animated.Value(0)).current;
  const blinkOpacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    // Continuous loading animation
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedWidth, {
          toValue: 100,
          duration: 2000, // Duration for one cycle
          useNativeDriver: false,
        }),
        Animated.timing(animatedWidth, {
          toValue: 0,
          duration: 0, // Instant reset
          useNativeDriver: false,
        }),
      ])
    ).start();

    // Continuous blinking effect
    Animated.loop(
      Animated.sequence([
        Animated.timing(blinkOpacity, {
          toValue: 0.5,
          duration: 1000,
          useNativeDriver: true,
        }),
        Animated.timing(blinkOpacity, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, []);

  const widthInterpolation = animatedWidth.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
  });

  return (
    <LinearGradient
      colors={['#062665', '#0B6EDF', '#06B3E4']}  
      start={[0, 0.5]}
      end={[1, 0.5]}
      style={styles.container}
    >
      <Animated.View style={[styles.progressBar, { width: widthInterpolation, opacity: blinkOpacity }]}>
        <LinearGradient
          colors={['rgb(167, 173, 182)', 'rgb(114, 208, 255)', 'rgb(101, 255, 255)']}
          start={[1, 0.5]}
          end={[1, 0.5]}
          style={styles.progressBarContainer}
        >
          <BlurView
            style={[styles.progressBar, { width: '100%' }]}
            intensity={8}
          />
        </LinearGradient>
      </Animated.View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 6,
    backgroundColor: 'rgb(8, 173, 228)',
    borderRadius: 5,
    overflow: 'hidden',
  },
  progressBarContainer: {
    height: '100%',
    borderRadius: 10,
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#007aff',
    borderRadius: 10,
  },
  cancelButton: {
    position: 'absolute',
    right: '1%', // Position the button within the progress bar
    // bottom: '-11%',
    top: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background for the button
    borderRadius: 5,
    padding: 2
  },
  cancelButtonText: {
    color: '#FFFFFF', // Text color for the button
    fontWeight: 'bold',
    fontFamily: 'Poppins_400Regular',
    fontSize: 12
  },
});

export default ImportProgressBar;
