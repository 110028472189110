import {LinearGradient} from 'expo-linear-gradient';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  ClearSearchProduct,
  barcodeQtyAPi,
  getData,
  printReceiving,
  // printScan
} from '../../actions/productAction';
// import {Unmounted} from '../../actions/menuActions';
import {printScan, printReceivingLabel} from '../../actions/productAction';
import {Unmounted} from '../../actions/menuActions'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  Image,
  Dimensions,
  Platform
} from 'react-native';
import AlertBoxSaving from '../../commons/alertBoxSaving';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import {fontFamily} from '../../helpers/fontFamily';
import styles from './styles';
import {fetchData, handlePrinterSelection} from '../../../qzservice';
import PrinterShow from '../directPrinting/printerShow';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {GetGeneralSetting} from '../../actions/userAction';

let dimensions = Dimensions.get('window');
let imageHeight = Math.round((dimensions.width * 6) / 26);

const Product = ({
  navigation,
  user,
  steps,
  qunatityLabel1,
  qunatityLabel2,
  quantityMessage,
  product,
  qtyReceive,
  setReceiveQty,
  qtyRef,
  locationRef,
  saveQuantity,
  saveProductInfo,
  locationPrimary,
  setLocationPrimary,
  locationPrimaryQty,
  setLocationPrimaryQty,
  locationSecondary,
  setLocationSecondary,
  locationSecondaryQty,
  setLocationSecondaryQty,
  locationTertiary,
  setLocationTertiary,
  locationTertiaryQty,
  setLocationTertiaryQty,
  pickImage,
  recevingNotes,
  setReceivingNotes,
  goBack,
  saveData,
  onPressProductDetail
}) => {
  const dispatch = useDispatch();
  const settingcheck = useSelector(
    state => state?.user?.settings?.data?.data?.settings
  );
  const isUnMounted = useSelector(state => state.menu.isUnMounted);
  const [barcodeQty, setBarcodeQty] = useState(1);
  const [modal, setModal] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [qty, setQty] = useState(false);
  const [loc, setLoc] = useState(false);
  const [permissionState, setPermissionState] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [imageDimensions, setImageDimensions] = useState(imageHeight);
  const [paddingHorizontal, setPaddingHorizontal] = useState(
    window.innerWidth > 768 ? '25px' : '5%'
  );
  const addEditProduct = user?.userInfo?.role?.add_edit_products;
  const editProductLocation = user?.userInfo?.role?.edit_product_location;
  const editProductQuantity = user?.userInfo?.role?.edit_product_quantity;
  const isLocationEditable = addEditProduct || editProductLocation;
  const isQuantityEditable = addEditProduct || editProductQuantity;
  const [saveDataState, setSaveDataState] = useState(false);
  const [url, setUrl] = useState('');
  const [printLabelUrl, setPrintLabelUrl] = useState('');
  const [msgType, setMsgType] = useState('');
  const [dimension,setDimension]=useState({});
  let dpi= 0;
  const openPopup = () => {
    setPermissionState(true);
    setIsEditable(false);
  };

  const onCloseAlert = () => {
    setPermissionState(false);
    setIsEditable(true);
  };
  const handleDimensions = useCallback(() => {
    setPaddingHorizontal(window.innerWidth > 768 ? '25px' : '5%');
  }, []);

  const handleBarcodeQty = e => {
    setBarcodeQty(e);
  };

  const printLabel = async () => {
    let ID = product?.basicinfo?.id;
    let print = await printReceiving(ID);
    if (settingcheck?.print_product_receiving_labels) {
      setMsgType('receive_recount_label');
      setUrl(print?.data?.url);
      let data = await fetchData();
      if(data.printersList.length ==0){
        window.open(print?.data?.url)
        navigation.navigate('ScanPack');
      }else{
      setPrinters(JSON.parse(JSON.stringify(data.printersList)));
      let shippingLabel =
        (await AsyncStorage.getItem('user_receive_label')) || '';
      const dimensionsJSON = await AsyncStorage.getItem('receive_recount_Dimensions');
      const dimensions = JSON.parse(dimensionsJSON);
      setDimension({width: dimensions.width, height: dimensions.height})
      dpi = dimensions.dpi
      if (shippingLabel.length === 0) {
        setModal(true);
      } else {
        setModal(false);
        setTimeout(() => {
          handlePrinterSelection(
            JSON.parse(shippingLabel),
            print?.data?.url,
            '',
            'receive_recount_label',
            {width: dimensions.width, height: dimensions.height},
            dimensions.dpi
          );
        }, [3000]);
        }
      }
    } else {
      window.open(print?.data?.url);
    }
  };

  const keyDownCallback = event => {
    if ((event.key === 'Enter' || event.code === 'Enter') && event.shiftKey) {
      return;
    }
    if (event.key === 'Tab' || event.code === 'Tab') {
      if (qtyRef.current.isFocused()) saveQuantity();
      else {
        saveProductInfo();
      }
    }
    if (event.key === 'Enter' || event.code === 'Enter') {
      if (qtyRef.current.isFocused()) {
        saveQuantity();
        if (steps.includes('location')) {
          locationRef.current.focus();
        } else {
          // goBack();1
        }
      } else if (locationRef.current.isFocused()|| loc) {
        saveProductInfo();
        setTimeout(() => {
          setSubmit()
          goBack();
        }, 500)
      } else {
        saveProductInfo();
        // goBack();
      }
    }
  };

  const updateSize = useCallback(function () {
    setImageDimensions(Math.round((Dimensions.get('window').width * 6) / 20));
  });

  const setSubmit = () => {
    setSaveDataState(!saveDataState)
    if (steps.includes("quantity") && steps.length === 1) {
      setTimeout(() => {
        goBack();
        setIsEditable(true);
      }, 500)
    }
  };
  const selectLoc = e => {
    if (steps.includes('location')) {
      e.target.select();
    }
  };
  const closeAlert = () => {
    setSaveDataState(false)
  }
  useEffect(() => {
    dispatch(GetGeneralSetting());
    // document.addEventListener('keydown', keyDownCallback);
    return () => document.removeEventListener('keydown', keyDownCallback);
  }, [saveQuantity, saveProductInfo]);

  useEffect(() => {
    window.addEventListener('resize', handleDimensions);
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', handleDimensions);
      window.removeEventListener('resize', updateSize);
      dispatch(ClearSearchProduct())
      dispatch(Unmounted(isUnMounted))
    };
  }, []);

  const PrintBarcode = async () => {
    let ProductBarcodeLabelSize = localStorage.getItem('Product_Barcode_label_size') || '{}';
    setMsgType('product_barcode');
    let ID = product?.basicinfo?.id;
    let barcodeQtyValue = barcodeQty;
    if (settingcheck?.print_product_barcode_labels) {
      let printData = await getData(ID, barcodeQtyValue,false);
      setUrl(printData);
      let data = await fetchData();
      if(data.printersList.length ==0){
        window.open(print?.data?.url)
        navigation.navigate('ScanPack');
      }else{
      setPrinters(JSON.parse(JSON.stringify(data.printersList)));

      let shippingLabel =
        (await AsyncStorage.getItem(`print_product_barcode_labels_${ProductBarcodeLabelSize}`)) || '';
      setDimension({width: 1, height: 3})
      dpi = 2
      if (shippingLabel.length === 0) {
        setModal(true);
      } else {
        setModal(false);
        setTimeout(() => {
          handlePrinterSelection(
            JSON.parse(shippingLabel),
            printData,
            '',
            'product_barcode',
            {width: 1, height: 3},
            dpi
          );
        }, [3000]);
        }
      }
    } else {
      dispatch(printScan(ID, barcodeQtyValue, false));
    }
  };

  return (
    <LinearGradient
      colors={[
        '#000',
        'rgba(37, 60, 87,1)',
        'rgba(92, 119, 143,1)',
        'rgba(37, 60, 87,1)',
        '#000'
      ]}
      start={[-0.5, -1]}
      end={[1, 1]}
      locations={[0, 0.2, 0.5, 0.8, 0.9, 1]}
      style={{
        backgroundColor: 'rgba(92, 119, 143,1)'
      }}>
      {modal && (
        <PrinterShow
          dataPrinter={printers}
          handlePrinterSelection={async printer => {
            let printerState = await handlePrinterSelection(
              printer,
              url,
              '',
              msgType,
              dimension,
              dpi
            );
            setModal(false);
            setSelectedPrinter(printerState.type);
          }}
          closePrompt={() => {
            setModal(false);
          }}
        />
      )}
      {saveDataState && <AlertBoxSaving closeAlert={() => closeAlert()} />}
      {saveDataState &&
        setTimeout(() => {
          closeAlert();
        }, 2000)}
      <AlertBoxPermission
        permissionState={permissionState}
        onCloseAlert={() => onCloseAlert()}
      />
      <View style={styles.container}>
        <View style={styles.productTopContainer}>
          <View>
            <View style={{flexDirection: 'row'}}>
              <Button
                label="Save & Close"
                onPress={() => {
                  saveQuantity();
                  saveProductInfo();
                  goBack();
                }}
              />
              <Button label="Cancel" onPress={goBack} shadowColor={'#e853fa'} />
            </View>
            <Text
              style={{
                fontSize: '15px',
                fontFamily: fontFamily.font300,
                textAlign: 'center',
                color: '#ffffff'
              }}>
              Enter also saves changes
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.productMiddleContainer,
            {justifyContent: 'center', alignItems: 'flex-end'}
          ]}>
          {product?.images && product.images.length > 0 ? (
            <View>
              <Image
                style={[
                  styles.image,
                  {
                    height: imageDimensions,
                    width: imageDimensions
                  }
                ]}
                source={{
                  uri: product?.images[product.images.length - 1].image
                }}
              />
              <TouchableOpacity
                onPress={pickImage}
                style={{
                  alignSelf: 'center',
                  paddingTop: 5,
                  marginBottom: -20
                }}>
                <Text
                  style={{
                    color: 'white',
                    fontFamily: fontFamily.font600
                  }}>
                  Edit Image
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <AddImageButton onPress={pickImage} />
          )}
          <TextInput
            onBlur={saveProductInfo}
            placeholder="Receiving Notes"
            placeholderTextColor={'#656161'}
            multiline={true}
            style={styles.receivingNotesInput}
            value={recevingNotes}
            onChangeText={setReceivingNotes}
          />
        </View>
        <View style={[styles.productBottomContainer, {marginHorizontal:window.innerWidth > 1200 ?  '15%' : '10%' }]}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal,
              marginVertical: 5
            }}>
            <Text
              style={{
                fontSize: '16px',
                color: '#ffffff',
                fontFamily: fontFamily.font400
              }}>
              {product?.basicinfo?.name || ''}
            </Text>
            <Text
              style={{
                fontSize: '16px',
                color: '#ffffff',
                fontFamily: fontFamily.font400
              }}>
              {product?.skus[0]?.sku || ''}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: 'rgba(157,176,193,0.07058823529411765)',
              borderRadius: '7px',
              padding: 5,
              paddingHorizontal
            }}>
            <View style={styles.inputRow}>
              <Input
                label={qunatityLabel1}
                value={`${product?.inventory_warehouses[0].info.quantity_on_hand}`}
              />
              <Input
                testId="quantityInput"
                forwardRef={qtyRef}
                autoFocus={true}
                onFocus={() => {
                  setQty(true);
                }}
                label={qunatityLabel2}
                onSubmitEditing={setSubmit}
                value={`${qtyReceive}`}
                onKeyPress={keyDownCallback}
                setValue={setReceiveQty}
                keyboardType={'default'}
                returnKeyType={Platform.OS === 'android' ? 'done' : 'next'}
                showPopup={isQuantityEditable}
                openPopup={openPopup}
                isEditable={isEditable}
              />
            </View>
            <View style={styles.inputRow}>
              <Input
                testId="LocationInput"
                forwardRef={locationRef}
                label={'Location 1'}
                onFocus={e => {
                  selectLoc(e);
                  setLoc(true);
                }}
                onKeyPress={keyDownCallback}
                onSubmitEditing={setSubmit}
                value={locationPrimary}
                setValue={setLocationPrimary}
                returnKeyType={Platform.OS === 'android' ? 'done' : 'next'}
                showPopup={isLocationEditable}
                openPopup={openPopup}
                isEditable={isEditable}
              />
              <Input
                label={'Location 1 QTY'}
                value={locationPrimaryQty}
                onSubmitEditing={setSubmit}
                setValue={setLocationPrimaryQty}
                keyboardType={'numeric'}
                showPopup={isQuantityEditable}
                openPopup={openPopup}
                isEditable={isEditable}
              />
            </View>
            <View style={styles.inputRow}>
              <Input
                label={'Location 2'}
                value={locationSecondary}
                onSubmitEditing={setSubmit}
                setValue={setLocationSecondary}
                showPopup={isLocationEditable}
                openPopup={openPopup}
                isEditable={isEditable}
              />
              <Input
                label={'Location 2 QTY'}
                value={locationSecondaryQty}
                onSubmitEditing={setSubmit}
                setValue={setLocationSecondaryQty}
                keyboardType={'numeric'}
                showPopup={isQuantityEditable}
                openPopup={openPopup}
                isEditable={isEditable}
              />
            </View>
            <View style={styles.inputRow}>
              <Input
                label={'Location 3'}
                value={locationTertiary}
                onSubmitEditing={setSubmit}
                setValue={setLocationTertiary}
                showPopup={isLocationEditable}
                openPopup={openPopup}
                isEditable={isEditable}
              />
              <Input
                label={'Location 3 QTY'}
                value={locationTertiaryQty}
                onSubmitEditing={setSubmit}
                setValue={setLocationTertiaryQty}
                keyboardType={'numeric'}
                showPopup={isQuantityEditable}
                openPopup={openPopup}
                isEditable={isEditable}
              />
            </View>
          </View>
        </View>
        <Text style={styles.message}>{quantityMessage}</Text>
        <View style={[styles.productBottomContainer, {marginHorizontal:window.innerWidth > 1200 ?  '15%' : '10%' }]}>
          <View
            style={{
              backgroundColor: 'rgba(157,176,193,0.07058823529411765)',
              borderRadius: '7px',
              padding: 5
            }}>
            <View style={{paddingHorizontal}}>
              <View style={styles.inputRow}>
                <View
                  style={{
                    flex: 1,
                    marginHorizontal: '10%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <Text
                    style={{
                      width: '50%',
                      fontSize: '16px',
                      fontFamily: fontFamily.font600,
                      color: '#ffffff'
                    }}>
                    Print Product Barcode
                  </Text>
                  <TextInput
                    value={barcodeQty}
                    testID='handleBarcodeQtyTest'
                    onChangeText={e => handleBarcodeQty(e)}
                    onSubmitEditing={e => {
                      barcodeQtyAPi(
                        product?.basicinfo?.id,
                        barcodeQty,
                        product?.inventory_warehouses[0]?.info?.inventory_warehouse_id,
                        product?.barcodes[0]?.barcode
                      );
                    }}
                    style={[
                      styles.textInput,
                      {width: '30%', textAlign: 'right', paddingRight: 10}
                    ]}
                  />
                </View>
                <TouchableOpacity
                testID='barcodePrint'
                onPress={PrintBarcode}>
                  <i
                    className="icon-print"
                    style={{color: 'white', fontSize: '25px'}}
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.inputRow}>
                <Text
                  style={{
                    width: '50%',
                    fontSize: '16px',
                    fontFamily: fontFamily.font600,
                    color: '#ffffff',
                    marginLeft:"10%"
                  }}>
                  Print Receiving Label
                </Text>
                <TouchableOpacity
                testID={'printLabel'}
                onPress={printLabel}>
                  <i
                    className="icon-print"
                    style={{color: 'white', fontSize: '25px'}}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                height: 2,
                backgroundColor: '#f4efef',
                marginHorizontal: '3%',
                marginVertical: 10
              }}
            />
            <TouchableOpacity
              onPress={onPressProductDetail}
              style={{
                flexDirection: 'row',
                alignSelf: 'center',
                alignItems: 'center',
                marginVertical: 10,
                marginRight: 10
              }}>
              <Text
                style={{
                  fontSize: '16px',
                  fontFamily: fontFamily.font600,
                  color: '#ced1d6'
                }}>
                Edit all product fields
              </Text>
              <i
                className="icon-edit"
                style={{color: 'white', fontSize: '16px', marginLeft: 10}}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </LinearGradient>
  );
};

export const Button = ({
  label = 'Label here',
  onPress = () => {},
  shadowColor = '#2bfac3'
}) => (
  <TouchableOpacity style={[styles.button, {shadowColor}]} onPress={onPress}>
    <Text style={styles.buttonText}>{label}</Text>
  </TouchableOpacity>
);

const Input = ({
  forwardRef = null,
  label,
  value,
  keyboardType = 'default',
  setValue = () => {},
  isEditable,
  openPopup,
  showPopup,
  onSubmitEditing,
  onFocus,
  testId,
  returnKeyType,
  onKeyPress,
  autoFocus
}) => (
  <View style={{flex: 1, margin: 10}}>
    <Text style={styles.inputText}>{label}</Text>
    <TextInput
      testID={testId}
      ref={forwardRef}
      keyboardType={keyboardType}
      value={value || ''}
      style={styles.textInput}
      onChangeText={showPopup ? setValue : openPopup}
      editable={isEditable}
      blurOnSubmit={false}
      onSubmitEditing={onSubmitEditing}
      onFocus={onFocus}
      returnKeyType={returnKeyType}
      autoFocus={autoFocus}
      onKeyPress={onKeyPress}
    />
  </View>
);

const InputRow = ({
  label = 'Print Product Barcode',
  value = 1,
  onChangeText,
  barcodeQtyUpdate
}) => (
  <View
    style={{
      flex: 1,
      marginHorizontal: '10%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
    <Text
      style={{
        width: '50%',
        fontSize: '16px',
        fontFamily: fontFamily.font600,
        color: '#ffffff'
      }}>
      {label}
    </Text>
    <TextInput
      value={value}
      onChangeText={onChangeText}
      onSubmitEditing={barcodeQtyUpdate}
      style={[
        styles.textInput,
        {width: '30%', textAlign: 'right', paddingRight: 10}
      ]}
      clearButtonMode="always"
    />
  </View>
);

export const AddImageButton = ({onPress}) => (
  <TouchableOpacity
    testID="addImage"
    onPress={onPress}
    style={{alignSelf: 'flex-end', marginEnd: '2%'}}
    key="add-image-button">
    <LinearGradient
      colors={['#5c778f', '#5c778f', '#000']}
      start={[-0.5, -1]}
      style={{
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#869db1',
        borderBottomWidth: 0,
        shadowColor: '#ffff',
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.9,
        shadowRadius: 3,
        elevation: 3,
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '80px',
        maxHeight: '80px'
      }}>
      <i className="icon-plus" style={{color: 'white', fontSize: '65px'}}></i>
    </LinearGradient>
    <Text
      style={{
        color: 'white',
        alignSelf: 'center',
        fontFamily: 'Poppins_600SemiBold'
      }}>
      Add Image
    </Text>
  </TouchableOpacity>
);
export default Product;
