import React,{useRef} from 'react';
import { Text, TouchableOpacity, View, StyleSheet, ActivityIndicator, Dimensions } from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';
import { fontFamily } from '../../helpers/fontFamily';
import promptStyle from '../../style/scanpack';
import StylesInput from '../Inputs/stylesInput';
import { LinearGradient } from 'expo-linear-gradient';


const CommonPrompt = (props) => {
  const windowWidth = window.innerWidth;
  const InputFocus = useRef();
  React.useEffect(()=>{
    InputFocus.current.focus()
  },[props.message,props.value])
  return (
    <View
    testID='CommonPrompt'
     style={[styles.responseView]}
    >
       <View style={[windowWidth >= 900 ? styles.typeScanContainer : "", windowWidth <= 900 ? styles.recordSerialMobile : "",{marginBottom: '9%'}]}>
        <LinearGradient 
        locations={[0, 1]}
        start={{ x: 0, y: 1 }}
        end={{ x: 0, y: 0 }}
        colors={['#d47d02', '#ffb636']}
        style={[
          styles.typeScanBox,{
            backgroundColor: '#d3dfea',
            paddingHorizontal: 10,
            paddingVertical: 5,
            width: windowWidth >= 900 ? '50%' : '100%',
            marginTop: "2%",
            borderColor: '#e8971a',
            borderWidth: 3,
            height: 'fit-content',
            boxShadow: '2px 2px 10px #363636'
          }
        ]}
        >
          <View
          style={styling.cancelIcon}
      >
        <TouchableOpacity
        dataSet={{ componentName: 'CommonCloseModalBtn'}} 
        testID='CancelIcon'
        onPress={props.closeAlert}
        >
          <i
            className="icon-cancel"
            style={{ fontSize: '26px', color: '#4b4132' }}
          ></i>
        </TouchableOpacity>
          </View>

          <View
          style={[globalStyles.flexDirectionRow,{alignItems: 'center'}]}
          >
            <Text
            testID='Message'
            style={[
                styles.scanCountText,
                { fontFamily: fontFamily.font400, fontSize:windowWidth >= 900? 20 : 16,textAlign: 'center',width: '90%' }
              ]}
            >
            {props.message}
            </Text>
          </View>

          <View
          style = {styling.inputContainer}
          >
            <StylesInput 
            name={props.name}
            value = {props.value}
            ref = {InputFocus}
            onChangeText = {props.onChangeText}
            onSubmitEditing = {
              props.value !== '' ? props.onSubmitEditing: ''
            }
            placeholder={props.placeholder}
            placeholderTextColor={props.placeholderTextColor}
            style = {
              [promptStyle.promptTextIn, styles.typeScanCountInput,styling.inputStyle]}
            inputStyle={styling.customInputStyle}
            useInputStyle= {false}
            />
            {props.loading && 
              <View 
              style={styling.loadingContainer}
              testID= 'loader'
              >
                <ActivityIndicator size="large" color="#336599" />
              </View> 
            }
            {props.showButtons &&
            <CommonButtons 
            {...props}
            onPress={props.value !== '' ? props.onSubmitEditing: ''}
            onCancel = {props.closeAlert}
            windowWidth = {windowWidth}
            />
            }
          </View>

        </LinearGradient>
      </View>
    </View>

  )
}
export default CommonPrompt;


const CommonButtons = (props) => {
  return(
    <View
      dataSet={{ componentName: 'ButtonContainer'}}
      testID='ButtonContainer'
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2%',
        width: '100%'
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity
          dataSet={{ componentName: 'onCommonSubmitButton'}}
          disabled= {props.value !== '' ? false : true}
          onPress={props.onPress}
          style={[
            styles.modalButton,
            {
              marginLeft: props.windowWidth >= 900 ? '-50%' : '-50%'
            }
          ]}
        >
          <Text>
            {props.submit ? 'Submit':  'Save'}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          dataSet={{ componentName: 'onCommonCancelButton'}}
          onPress={props.onCancel}
          style={[
              styles.modalButton,
              { marginLeft: props.windowWidth >= 900 ? '84%' : '100%' }
            ]}
        >
          <Text>
            Cancel
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styling = StyleSheet.create({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20
  },
  inputStyle: {
    border: 'none',
    marginBottom: '1%',
    width: '90%',
    marginTop: '1%',
    height: 38
  },
  customInputStyle: {
    height: '100%',
    fontSize: 20,
    padding: 10
  },
  cancelIcon:{
    display:'flex',
    alignSelf: 'flex-end'
  },
  loadingContainer:{
    flex: 1, 
    justifyContent: 'center'
  },
})