import React from "react";
const CommonSelect = ({
  list,
  value,
  onChange,
  refProp,
  FocusVisible,
  width,
}) => {
  const getName = (name) =>{
    if(width !== '60%'){
      if(name.length > 30){
        const updatedName = name.slice(0, 30);
        return updatedName+'..'
      }
      return name
    }
    return name
  }
  return (
    <select
      className="customSelect"
      id="weight"
      style={{
        cursor: "pointer",
        width: width,
        height: 35,
        backgroundColor: "transparent",
        borderStyle: "none none solid none",
        borderColor: "#fff",
        color: "#fff",
        fontSize: width === '60%' ? 18 : 14,
        overflow: 'clip',
      }}
      value={value}
      ref={refProp}
      data-focusvisible-polyfill={FocusVisible}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    >
       <option
          disabled
          style={{
             backgroundColor: '#dbdada',
             color: '#000',
             fontSize: 18
           }}
          value={''}>
         Please select country
        </option>
      {list.map((el) => {
        return (
          <option
            key={el["alpha-2"]}
            style={{
              backgroundColor: "#dbdada",
              color: "#000",
              fontSize: 18,
            }}
            value={el["alpha-2"]}
          >
            {el["alpha-2"] + " " + getName(el.name)}
          </option>
        );
      })}
    </select>
  );
};
export default CommonSelect;