import React, {Component, createRef} from 'react';
import {
  View,
  Text,
  TextInput,
  Dimensions,
  Image,
  Button,
  TouchableOpacity
} from 'react-native';
import styles from '../../style/productdetail';
import arrowDown from '../../../assets/arrow-down-product.png';
import arrowUp from '../../../assets/arrow-up-product.png';
import {Editor} from 'react-draft-wysiwyg';
import Switch from 'react-switch';
import {LinearGradient} from 'expo-linear-gradient';
import HTML from "react-native-render-html";
import { FontAwesome } from '@expo/vector-icons';
// import '.../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class ScanPackOptionMobileView extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let productskit = this.props.product.productkitskus;
    let {basicinfo} = this.props;
    return (
      <>
        {this.props.quickScanToolTip2 && (
          <LinearGradient
            testID='quicKScanToolTip2'
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={{
              padding: 10,
              position: 'absolute',
              top: 233,
              borderRadius: 5,
              width: 299,
              alignSelf: 'flex-start',
              zIndex: 999
            }}
          >
            <Text style={{fontSize: 20, color: '#030303'}}>
              When enabled, the packer will be prompted to scan a serial number
              each time this product is packed. The recorded serial can then be
              viewed in the Order Activity log or downloaded in the Order Serial
              CSV. One or two entries can be recorded based on the setting.
            </Text>
            <View
              style={{
                position: 'absolute',
                right: 65,
                top: -10,
                borderLeftWidth: 10,
                borderLeftColor: 'transparent',
                borderRightWidth: 10,
                borderRightColor: 'transparent',
                borderBottomWidth: 10,
                borderBottomColor: '#8d9fb1'
              }}
            />
          </LinearGradient>
        )}
        {this.props.quickScanToolTip3 && (
          <LinearGradient
            testID='quicKScanToolTip3'
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={{
              padding: 10,
              position: 'absolute',
              top: 306,
              borderRadius: 5,
              width: 299,
              alignSelf: 'flex-start',
              zIndex: 999,
              marginBottom: 5
            }}
          >
            <Text style={{fontSize: 20, color: '#030303'}}>
              Intangible items, also known as transactional items, are
              non-physical items that do not need to be packed. Examples are
              Coupon Codes, Insurance, Same day handling charges etc. Many times
              these items import along with regular order items. To avoid having
              these items prompted during Scan and Pack you can enable this
              option to designate them as intangible.If you have intangible
              items that are dynamically generated but always contain a specific
              string you can have GroovePacker automatically set these items to
              intangible. You'll find this option in the Scan and Pack settings.
            </Text>
            <View
              style={{
                position: 'absolute',
                right: 110,
                top: -10,
                borderLeftWidth: 10,
                borderLeftColor: 'transparent',
                borderRightWidth: 10,
                borderRightColor: 'transparent',
                borderBottomWidth: 10,
                borderBottomColor: '#8d9fb1'
              }}
            />
          </LinearGradient>
        )}

        {this.props.quickScanToolTip4 && (
          <LinearGradient
            testID='quicKScanToolTip4'
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={{
              padding: 10,
              position: 'absolute',
              top: 343,
              borderRadius: 5,
              width: 299,
              alignSelf: 'flex-start',
              zIndex: 999,
              marginBottom: 5
            }}
          >
            <Text style={{fontSize: 20, color: '#030303'}}>
              When this option is enabled for a product you can add it to any
              order you're packing just by scanning it. This is great for
              tracking the inventory of boxes or bags that are decided on at the
              time of packing. Catalogs, coupons, freebie items can be scanned
              and tracked too. Items added will be saved with the order record
              in GroovePacker and included on packing slips printed after
              packing is complete.
            </Text>
            <View
              style={{
                position: 'absolute',
                right: 97,
                top: -10,
                borderLeftWidth: 10,
                borderLeftColor: 'transparent',
                borderRightWidth: 10,
                borderRightColor: 'transparent',
                borderBottomWidth: 10,
                borderBottomColor: '#8d9fb1'
              }}
            />
          </LinearGradient>
        )}
        {this.props.quickScanToolTip5 && (
          <LinearGradient
            testID='quicKScanToolTip5'
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={{
              padding: 10,
              position: 'absolute',
              bottom: 19,
              borderRadius: 5,
              width: 299,
              alignSelf: 'flex-start',
              zIndex: 999,
              marginBottom: 5
            }}
          >
            <Text style={{fontSize: 20, color: '#030303'}}>
              Unlike the commonly used "Pass" option the Skippable option is
              usually enabled only for non-essential items, like free-bees or
              coupon cards which are normally scanned but which can be skipped
              when stock is out. The SKIP action barcdoe is scanned to skip an
              item. Skipped items may be removed from the order or may remain
              depending on the global setting found in the Scan and Pack options
              with the SKIP action barcode.
            </Text>
            <View
              style={{
                position: 'absolute',
                right: 34,
                top: -10,
                borderLeftWidth: 10,
                borderLeftColor: 'transparent',
                borderRightWidth: 10,
                borderRightColor: 'transparent',
                borderBottomWidth: 10,
                borderBottomColor: '#8d9fb1'
              }}
            />
          </LinearGradient>
        )}

        {this.props.quickScanToolTip6 && (
          <LinearGradient
            testID='quicKScanToolTip6'
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={{
              padding: 10,
              position: 'absolute',
              top: 415,
              borderRadius: 5,
              width: 299,
              alignSelf: 'flex-start',
              zIndex: 999
            }}
          >
            <Text style={{fontSize: 20, color: '#030303'}}>
              The packing instructions text here will be shown every time this
              item is suggested for scanning. In addition to adding instructions
              here they can also be imported in bulk using the CSV product
              importer.
            </Text>
            <View
              style={{
                position: 'absolute',
                right: 121,
                top: -10,
                borderLeftWidth: 10,
                borderLeftColor: 'transparent',
                borderRightWidth: 10,
                borderRightColor: 'transparent',
                borderBottomWidth: 10,
                borderBottomColor: '#8d9fb1'
              }}
            />
          </LinearGradient>
        )}

        {this.props.quickScanToolTip7 && (
          <LinearGradient
            testID='quicKScanToolTip7'
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={{
              padding: 10,
              position: 'absolute',
              top: -28,
              left: 21,
              borderRadius: 5,
              width: 299,
              alignSelf: 'flex-start',
              zIndex: 999
            }}
          >
            <Text style={{fontSize: 20, color: '#030303'}}>
              The suggested scanning sequence is determined automatically. Items
              are sorted alphabetically by the Primary bin location. If no
              primary location is saved the SKU will be used to sort the items.
              If you wish to Override this sorting sequence and force some items
              to display before or after other items this setting can be used.
              ie. If the item should be picked or scanned before all others, or
              after all others you can adjust this value. By default, all items
              have a value of 50. Items with override values closer to 1 will be
              sorted before those with values closer to 99 regardless of the SKU
              or Location saved. The override value can also be set in bulk
              using a CSV file.
            </Text>
            <View
              style={{
                position: 'absolute',
                right: 11,
                bottom: -10,
                borderLeftWidth: 10,
                borderLeftColor: 'transparent',
                borderRightWidth: 10,
                borderRightColor: 'transparent',
                borderTopWidth: 10,
                borderTopColor: '#8d9fb1'
              }}
            />
          </LinearGradient>
        )}

        {this.props.quickScanToolTip8 && (
          <LinearGradient
            testID='quicKScanToolTip8'
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={{
              padding: 10,
              marginLeft:
                this.props.windowWidth < 330
                  ? '0%'
                  : this.props.windowWidth < 430
                  ? '15%'
                  : this.props.windowWidth < 550
                  ? '26%'
                  : '40%',
              position: 'absolute',
              top: 291,
              borderRadius: 5,
              width: 299,
              alignSelf: 'flex-start',
              zIndex: 999
            }}
          >
            <Text style={{fontSize: 20, color: '#030303'}}>
              Custom product data can be entered here or imported using the
              product CSV importer. Each field can optionally be displayed when
              the item is suggested for scanning. The is very useful when
              variant information like size or color is not easily readable in
              the SKU or product name but is required by the picker or packer.
            </Text>
            <View
              style={{
                position: 'absolute',
                right: 4,
                bottom: -14,
                borderLeftWidth: 14,
                borderLeftColor: 'transparent',
                borderRightWidth: 14,
                borderRightColor: 'transparent',
                borderTopWidth: 14,
                borderTopColor: '#8d9fb1'
              }}
            />
          </LinearGradient>
        )}
        <View
          style={[
            this.props.windowWidth < 450
              ? {marginLeft: '20px', marginTop: '-5px'}
              : {marginLeft: '30px', marginTop: '-35px'},
              {display: 'none'}
          ]}
        >
          {productskit?.length >= 1 ? (
            <Text
              style={{
                fontWeight: 'bold',
                fontFamily: 'Poppins_700Bold',
                fontSize: '18px',
                background: ' -webkit-linear-gradient(#4b657d  , #768ea5)',
                webkitBackgroundClip: 'text',
                webkitTextFillColor: 'transparent'
              }}
            >
              KIT:{this?.props?.product?.skus[0]?.sku}
            </Text>
          ) : (
            <Text
              style={{
                fontWeight: 'bold',
                fontFamily: 'Poppins_700Bold',
                fontSize: '18px',
                background: ' -webkit-linear-gradient(#4b657d  , #768ea5)',
                webkitBackgroundClip: 'text',
                webkitTextFillColor: 'transparent'
              }}
            >
              {this?.props?.product?.skus[0]?.sku}
            </Text>
          )}
        </View>
        <View
          style={{
            paddingHorizontal: '1%',
            backgroundColor: 'rgba(104, 135, 156, 0.3)',
            borderRadius: 10,
            marginHorizontal: '1%',
            marginTop: 20,
            marginBottom: 10,
            position: 'relative',
            zIndex: 9,
            shadowColor: '#50708b',
            shadowOffset: {width: 1, height: 1},
            shadowRadius: 10
          }}
        >
          {this.props.dropDownToggleState && (
            <View
              style={{
                position: 'absolute',
                zIndex: 1,
                top: 51,
                left: 0,
                width: 200,
                backgroundColor: 'rgba(45, 68, 95,1)',
                borderRadius: 5
              }}
            >
              {this.props.pickerSelectedOptions.map((option, index) => {
                return (
                  <View key={index} style={{}}>
                    <TouchableOpacity
                      testID='clickScanDrop'
                      onPress={() => {
                        this.props.show === true
                          ? this.props.permissionCheck()
                          : this.props.clickScandrop(option.value, index);
                      }}
                      style={{
                        marginBottom: 10,
                        marginTop: 10,
                        justifyContent: 'center',
                        backgroundColor:
                          index == this.props.clickScanIndex ? '#4b5f76' : ''
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 14,
                          color: '#fff',
                          marginLeft: 10
                        }}
                      >
                        {option.label}
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          )}
          {this.props.dropDownTypeInToggle && (
            <View
              style={{
                position: 'absolute',
                zIndex: 1,
                top: 114,
                left: 0,
                width: 200,
                backgroundColor: 'rgba(45, 68, 95,1)',
                borderRadius: 5
              }}
            >
              {this.props.pickerSelectedOptions.map((option, index) => {
                return (
                  <View key={index}>
                    <TouchableOpacity
                      testID='typeInCount'
                      onPress={() => {
                        this.props.show === true
                          ? this.props.permissionCheck()
                          : this.props.typeInCountDrop(option.value, index);
                        // this.props.userRole.role.add_edit_products ?
                        //   this.typeInCountDrop(option.value, index) : <></>
                      }}
                      style={{
                        marginBottom: 10,
                        marginTop: 10,
                        justifyContent: 'center',
                        backgroundColor:
                          index == this.props.typeInCountIndex ? '#4b5f76' : ''
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 14,
                          color: '#fff',
                          marginLeft: 10
                        }}
                      >
                        {option.label}
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          )}
          {this.props.dropDownRecordSerial && (
            <View
              style={{
                position: 'absolute',
                top: 177,
                zIndex: 1,
                width: 193,
                left: 0,
                backgroundColor: 'rgba(45, 68, 95,1)',
                borderRadius: 5
              }}
            >
              {this.props.pickerSelectedRecordOptions.map((option, index) => {
                return (
                  <TouchableOpacity
                  testID='updateSerialRecord'
                    onPress={() => {
                      this.props.show === true
                        ? this.props.permissionCheck()
                        : this.props.updateSerialRecord(option.value, index);
                      // this.props.userRole.role.add_edit_products ?
                      //   this.updateSerialRecord(option.value, index) : <></>
                    }}
                    style={{
                      marginBottom: 10,
                      marginTop: 10,
                      justifyContent: 'center',
                      backgroundColor:
                        index == this.props.recordSerialIndex ? '#4b5f76' : ''
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 14,
                        color: '#fff',
                        marginLeft: 10
                      }}
                    >
                      {option.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
          <View style={{}}>
            {this.props.quickScanToolTip && (
              <LinearGradient
                testID='quicKScanToolTip0'  
                locations={[0, 1]}
                colors={['#8a9daf', '#d7e1e9']}
                style={{
                  padding: 10,
                  position: 'absolute',
                  zIndex: 99,
                  top: 37,
                  borderRadius: 5,
                  width: 299,
                  alignSelf: 'flex-start'
                }}
              >
                <Text style={{fontSize: 20, color: '#030303'}}>
                  Items with missing or damaged barcodes can be Passed using the
                  Pass button when enabled. The option can be disabled for items
                  that should never be passed. "With Confirmation" will require
                  a user with "Pass Button" permission to provide their
                  confirmation code. "With Message" will permit Passing but a
                  message will be shown which discourages unnecessary use.
                </Text>
                <View
                  style={{
                    position: 'absolute',
                    right: 112,
                    top: -10,
                    borderLeftWidth: 10,
                    borderLeftColor: 'transparent',
                    borderRightWidth: 10,
                    borderRightColor: 'transparent',
                    borderBottomWidth: 10,
                    borderBottomColor: '#8d9fb1'
                  }}
                />
              </LinearGradient>
            )}

            <View>
              <View
                style={{
                  flex: 2,
                  flexDirection: 'column'
                }}
              >
                <View style={{flexDirection: 'row', width: '200px'}}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 15,
                      flex: 2,
                      padding: 10,
                      color: '#fff',
                      marginLeft: -10
                    }}
                  >
                    Pass / click-scan
                  </Text>
                  <TouchableOpacity
                    testID='tooltip0'
                    style={{marginTop: 6, marginRight: 12}}
                    // onpress={()=>{this.props.toolTipFun("0")}}
                    onMouseEnter={() => {
                      this.props.toolTipFunShowMobile('0');
                    }}
                    onMouseLeave={() => {
                      this.props.toolTipFunHideMobile('0');
                    }}
                  >
                    <i
                      className="icon-info-circled"
                      style={{color: 'rgb(172,187,199)', fontSize: '17px'}}
                    ></i>
                  </TouchableOpacity>
                </View>
                <View style={{marginTop: -10, width: '50%'}}>
                  <TouchableOpacity testID='ClickScanBtn' onPress={() => this.props.dropDownToggle()}>
                    <View style={[styles.mainContainerMobile]}>
                      <View
                        style={[
                          styles.dropDownToggleMobileStyle,
                          {flexDirection: 'row'}
                        ]}
                      >
                        <Text style={{color: '#fff'}}>
                          {this.props.clickScanstate}
                        </Text>
                        <i
                          class="icon-down-open"
                          style={{
                            color: 'rgb(205,205,205)',
                            position: 'absolute',
                            right: '0%'
                          }}
                        />
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={{marginTop: 10, zIndex: 2}}>
              {this.props.quickScanToolTip1 && (
                <LinearGradient
                  testID='quicKScanToolTip1'
                  locations={[0, 1]}
                  colors={['#8a9daf', '#d7e1e9']}
                  style={{
                    padding: 10,
                    position: 'absolute',
                    top: 34,
                    left: -6,
                    borderRadius: 5,
                    width: 299,
                    alignSelf: 'flex-start',
                    zIndex: 999
                  }}
                >
                  <Text style={{fontSize: 20, color: '#030303'}}>
                    When enabled, this setting allows you to scan one of an item
                    and then manually type in a quantity rather than scanning
                    each item one by one. It's ideal when you have many items
                    that are pre-counted in cases. You can set the type-in
                    trigger in the Scan and Pack settings.
                  </Text>
                  <View
                    style={{
                      position: 'absolute',
                      right: 107,
                      top: -10,
                      borderLeftWidth: 10,
                      borderLeftColor: 'transparent',
                      borderRightWidth: 10,
                      borderRightColor: 'transparent',
                      borderBottomWidth: 10,
                      borderBottomColor: '#8d9fb1'
                    }}
                  />
                </LinearGradient>
              )}
              <View
                style={{
                  flex: 2,
                  flexDirection: 'column',
                  zIndex: 99
                }}
              >
                <View style={{flexDirection: 'row', width: '200px'}}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 15,
                      flex: 2,
                      padding: 10,
                      color: '#fff',
                      marginLeft: -10
                    }}
                  >
                    Type-In Count
                  </Text>
                  <TouchableOpacity
                    testID='tooltip1'
                    style={{marginTop: 6, marginRight: 12}}
                    // onPress={() => this.props.toolTipFun("1")}
                    onMouseEnter={() => {
                      this.props.toolTipFunShowMobile('1');
                    }}
                    onMouseLeave={() => {
                      this.props.toolTipFunHideMobile('1');
                    }}
                  >
                    <i
                      className="icon-info-circled"
                      style={{color: 'rgb(172,187,199)', fontSize: '17px'}}
                    ></i>
                  </TouchableOpacity>
                </View>
                <View style={{marginTop: -10, width: '50%'}}>
                  <View>
                    <TouchableOpacity
                      testID='TypeInCountBtn'
                      onPress={() => this.props.dropDownToggleTypeIn()}
                    >
                      <View style={[styles.mainContainerMobile]}>
                        <View
                          style={[
                            styles.dropDownToggleMobileStyle,
                            {flexDirection: 'row'}
                          ]}
                        >
                          <Text style={{color: '#fff'}}>
                            {this.props.typeInCountState}
                          </Text>
                          <i
                            class="icon-down-open"
                            style={{
                              color: 'rgb(205,205,205)',
                              position: 'absolute',
                              right: '0%'
                            }}
                          />
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
            <View style={{marginTop: 10}}>
              <View
                style={{
                  flex: 2,
                  flexDirection: 'column'
                }}
              >
                <View style={{flexDirection: 'row', width: '228px'}}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 15,
                      flex: 2,
                      padding: 10,
                      color: '#fff',
                      marginLeft: -10
                    }}
                  >
                    Record Serial / Lot / Batch
                  </Text>
                  <TouchableOpacity
                    testID='tooltip2'
                    style={{marginTop: 4}}
                    // onPress={() => this.props.toolTipFun("2")}
                    onMouseEnter={() => {
                      this.props.toolTipFunShowMobile('2');
                    }}
                    onMouseLeave={() => {
                      this.props.toolTipFunHideMobile('2');
                    }}
                  >
                    <i
                      className="icon-info-circled"
                      style={{
                        color: 'rgb(172,187,199)',
                        fontSize: '17px',
                        marginTop: 5
                      }}
                    ></i>
                  </TouchableOpacity>
                </View>
                <View style={{marginTop: -10, width: '50%'}}>
                  <TouchableOpacity
                    testID='SerialRecordBtn'
                    onPress={() => this.props.dropDownToggleRecord()}
                  >
                    <View style={[styles.mainContainerMobile]}>
                      <View
                        style={[
                          styles.dropDownToggleMobileStyle,
                          {flexDirection: 'row'}
                        ]}
                      >
                        <Text style={{color: '#fff'}}>
                          {this.props.recordSerialState}
                        </Text>
                        <i
                          class="icon-down-open"
                          style={{
                            color: 'rgb(205,205,205)',
                            position: 'absolute',
                            right: '0%'
                          }}
                        />
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={{marginTop: 15}}>
              <View
                style={{
                  flex: 2,
                  flexDirection: 'row'
                }}
              >
                <Switch
                  checked={basicinfo.is_intangible}
                  disabled={this.props.item_intan}
                  onChange={(value, name) => {
                    this.props.show === true
                      ? this.props.permissionCheck()
                      : this.props.productInfoHandleChange(
                          value,
                          'is_intangible'
                        );
                  }}
                  offColor="#5c6e79"
                  onColor="#65baea"
                  offHandleColor="#969696"
                  onHandleColor="#fbfdfe"
                  handleDiameter={22}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
                <Text
                  style={{
                    marginLeft: 10,
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 15
                  }}
                >
                  Intangible Item
                </Text>
                <TouchableOpacity
                  testID='tooltip3'
                  // onPress={() => this.props.toolTipFun("3")}
                  onMouseEnter={() => {
                    this.props.toolTipFunShowMobile('3');
                  }}
                  onMouseLeave={() => {
                    this.props.toolTipFunHideMobile('3');
                  }}
                >
                  <i
                    className="icon-info-circled"
                    style={{
                      color: 'rgb(172,187,199)',
                      top: -5,
                      fontSize: '17px'
                    }}
                  ></i>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{marginTop: 15}}>
              <View
                style={{
                  flex: 2,
                  flexDirection: 'row'
                }}
              >
                <Switch
                  checked={basicinfo.is_skippable}
                  disabled={this.props.skippable}
                  onChange={(value, name) => {
                    this.props.show === true
                      ? this.props.permissionCheck()
                      : this.props.productInfoHandleChange(
                          value,
                          'is_skippable'
                        );
                  }}
                  offColor="#5c6e79"
                  onColor="#65baea"
                  offHandleColor="#969696"
                  onHandleColor="#fbfdfe"
                  handleDiameter={22}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
                <Text
                  style={{
                    marginLeft: 10,
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 15
                  }}
                >
                  Optional Item / skippable
                </Text>
                <TouchableOpacity
                  testID='tooltip5'
                  // onPress={() => this.props.toolTipFun("5")}
                  onMouseEnter={() => {
                    this.props.toolTipFunShowMobile('5');
                  }}
                  onMouseLeave={() => {
                    this.props.toolTipFunHideMobile('5');
                  }}
                >
                  <i
                    className="icon-info-circled"
                    style={{
                      color: 'rgb(172,187,199)',
                      Top: -5,
                      fontSize: '17px'
                    }}
                  ></i>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{marginTop: 15}}>
              <View style={{flexDirection: 'row', marginBottom: -10}}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignSelf: 'flex-start',
                    justifyContent: 'center',
                    alignItems: 'flex-start'
                  }}
                >
                  <Text
                    style={{color: '#fff', fontWeight: 'bold', fontSize: 15}}
                  >
                    Packing Instructions
                  </Text>
                  <TouchableOpacity
                    testID='tooltip6'
                    // onPress={() => this.props.toolTipFun("6")}
                    onMouseEnter={() => {
                      this.props.toolTipFunShowMobile('6');
                    }}
                    onMouseLeave={() => {
                      this.props.toolTipFunHideMobile('6');
                    }}
                  >
                    <i
                      className="icon-info-circled"
                      style={{color: 'rgb(172,187,199)', fontSize: '17px'}}
                    ></i>
                  </TouchableOpacity>
                  <View
                  style={{marginLeft: 10,paddingBottom: 2}}
                  >
                  <Switch
                      testID='packingInstructionsSwitchMobile'
                      checked={this.props.isDisabled? true:basicinfo.packing_instructions_conf}
                      disabled={this.props.isDisabled}
                      onChange={(value, name) => {
                        this.props.show === true
                          ? this.props.permissionCheck()
                          : this.props.productInfoHandleChange(
                              value,
                              'packing_instructions_conf'
                            );
                      }}
                      offColor="#5c6e79"
                      onColor="#65baea"
                      offHandleColor="#969696"
                      onHandleColor="#fbfdfe"
                      handleDiameter={22}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                  />
                  </View>
                  <View style={{display: 'flex',width: this.props.windowWidth < 375 && '41%', flexDirection: 'row' }}>
                    <View
                      style={{marginLeft: 5}}
                    >
                        <TouchableOpacity
                        onPress={() => this.props.htmlViewtoggle()}
                        testID='showHtmlMobile'
                      >
                        <Text
                          style={{color: '#fff', fontWeight: 'bold', fontSize: 15}}
                        >
                          {this.props.showHtml ? 'Edit HTML' : 'Display HTML'}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View>
                      <TouchableOpacity 
                        testID='pickImageMobile'
                        onPress={() => this.props.handleImagePicker()}
                      >
                        <FontAwesome name="picture-o"
                          className="icon-receive"
                          style={{
                            color: 'white',
                            marginTop: this.props.windowWidth > 800 && 3,
                            marginLeft: 5,
                            fontSize: '20px'
                          }}
                        ></FontAwesome>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flex: 2,
                    alignSelf: 'flex-end',
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                  }}
                >
                  <TouchableOpacity
                    // onPress={() => this.showEditorFun()}
                    testID='showEditorBtn'
                    onPress={() => {
                      this.props.show === true
                        ? this.props.permissionCheck()
                        : this.props.showEditorFun();
                      // this.props.userRole.role.add_edit_products ?
                      //   this.showEditorFun() : <></>
                    }}
                  >
                    <Text
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: 15,
                        display: 'none'
                      }}
                    >
                      HTML
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View>
                {this.props.showEditor ? (
                  <View style={{marginTop: 10}}
                   testID='packingInstructionEditor'
                  >
                    <Editor
                      editorState={this.props.editorState}
                      initialContentState={basicinfo.packing_instructions}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(text, name) =>
                        this.props.onEditorStateChange(text)
                      }
                      onContentStateChange={(text, name) =>
                        this.props.productInfoHandleChange(
                          text.blocks[0].text,
                          'packing_instructions'
                        )
                      }
                    />
                  </View>
                ) : (
                  this.props.showHtml ? (
                    <TouchableOpacity
                      onPress={this.props.htmlViewtoggle}
                    >
                      <View
                      testID='HtmlViewMobile'
                      style={{
                        margin: 10,
                        borderRadius: 15,
                        padding: 10,
                        backgroundColor: 'black',
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 15
                      }}
                      >
                        {!basicinfo.packing_instructions ? (
                          <Text style={{ color: 'gray' }}>Click edit HTML to add text</Text>
                        ) : (
                          <HTML
                            html={`<div style="color: white; font-size: 27px; line-height: 1.2; margin-top: 10px; margin-bottom: 10px">${basicinfo.packing_instructions}</div>`}
                            imagesMaxWidth={this.props.windowWidth/2}
                          />
                        )}
                      </View>
                    </TouchableOpacity>
                  ):(
                  <TextInput
                    testID='packingInstructionInput'
                    ref={this.props.textInputRef}
                    multiline={true}
                    editable={this.props.packing_inst}
                    numberOfLines={4}
                    style={{
                      margin: 10,
                      borderRadius: 15,
                      padding: 10,
                      backgroundColor: '#dbdada',
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 15
                    }}
                    value={basicinfo.packing_instructions}
                    onChangeText={(text, name) => {
                      this.props.show === true
                        ? this.props.permissionCheck()
                        : this.props.productInfoHandleChange(
                            text,
                            'packing_instructions'
                          );
                    }}
                  />
                  )
                )}
              </View>
            </View>
            <View>
              <View style={{flexDirection: 'row'}}>
                <TextInput
                style={{
                  width: 50,
                  height: 30,
                  borderRadius: 5,
                  backgroundColor: '#dbdada',
                  marginTop: 7,
                  textAlign: 'center'
                }}
                  value={this.props.scanSeqcount}
                  onChangeText={(text) =>
                    this.props.setCount(text)
                  }
                />
                <View
                  style={{
                    flexDirection: 'column',
                    marginTop: 5,
                    marginLeft: 5,
                    marginRight: 5
                  }}
                >
                  <TouchableOpacity
                    testID='countInc'
                    style={{marginBottom: -5}}
                    onPress={() =>
                      this.props.show === true
                        ? this.props.permissionCheck()
                        : this.props.countIncDec('Inc')
                    }
                  >
                    <Image style={{width: 15, height: 20}} source={arrowUp} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID='countDec'
                    style={{marginTop: -5}}
                    onPress={() =>
                      this.props.show === true
                        ? this.props.permissionCheck()
                        : this.props.countIncDec('Dec')
                    }
                  >
                    <Image style={{width: 15, height: 20}} source={arrowDown} />
                  </TouchableOpacity>
                </View>
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                  <Text
                    style={{color: '#fff', fontWeight: 'bold', fontSize: 15}}
                  >
                    Scanning Sequence Override
                  </Text>
                </View>
                <View>
                  <TouchableOpacity
                    testID='tooltip7'
                    // onPress={() => this.props.toolTipFun("7")}
                    onMouseEnter={() => {
                      this.props.toolTipFunShowMobile('7');
                    }}
                    onMouseLeave={() => {
                      this.props.toolTipFunHideMobile('7');
                    }}
                  >
                    <i
                      className="icon-info-circled"
                      style={{
                        color: 'rgb(172,187,199)',
                        fontSize: '17px',
                        marginTop: 8
                      }}
                    ></i>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={{marginTop: 15}}>
              <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: 'column', width: '70%'}}>
                  <Text style={{color: '#fff'}}>Custom Product 1</Text>
                  <TextInput
                    testID='customProductInput'
                    editable={this.props.cust_prod1}
                    style={{
                      borderRadius: 10,
                      padding: 10,
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 15,
                      backgroundColor: '#dbdada'
                    }}
                    value={basicinfo.custom_product_1}
                    onChangeText={(text, name) => {
                      this.props.show === true
                        ? this.props.permissionCheck()
                        : this.props.productInfoHandleChange(
                            text,
                            'custom_product_1'
                          );
                    }}
                  />
                </View>
                <View style={{flexDirection: 'column', marginLeft: 10}}>
                  <Text style={{color: '#fff'}}>Display</Text>
                  <View style={{marginTop: 7}}>
                    <Switch
                      checked={basicinfo.custom_product_display_1}
                      disabled={this.props.dis1}
                      onChange={(value, name) => {
                        this.props.show === true
                          ? this.props.permissionCheck()
                          : this.props.productInfoHandleChange(
                              value,
                              'custom_product_display_1'
                            );
                      }}
                      offColor="#5c6e79"
                      onColor="#65baea"
                      offHandleColor="#969696"
                      onHandleColor="#fbfdfe"
                      handleDiameter={22}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                    />
                  </View>
                </View>
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                  <TouchableOpacity
                    testID='tooltip8'
                    style={{}}
                    // onPress={() => this.props.toolTipFun("8")}
                    onMouseEnter={() => {
                      this.props.toolTipFunShowMobile('8');
                    }}
                    onMouseLeave={() => {
                      this.props.toolTipFunHideMobile('8');
                    }}
                  >
                    <i
                      className="icon-info-circled"
                      style={{
                        color: 'rgb(172,187,199)',
                        fontSize: '17px',
                        marginTop: 14
                      }}
                    ></i>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={{marginTop: 15}}>
              <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: 'column', width: '70%'}}>
                  <Text style={{color: '#fff'}}>Custom Product 2</Text>
                  <TextInput
                    testID='customProductInput2'
                    editable={this.props.cust_prod2}
                    style={{
                      borderRadius: 10,
                      padding: 10,
                      backgroundColor: '#dbdada',
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 15
                    }}
                    value={basicinfo.custom_product_2}
                    onChangeText={(text, name) => {
                      this.props.show === true
                        ? this.props.permissionCheck()
                        : this.props.productInfoHandleChange(
                            text,
                            'custom_product_2'
                          );
                    }}
                  />
                </View>
                <View style={{flexDirection: 'column', marginLeft: 10}}>
                  <Text style={{color: '#fff'}}>Display</Text>
                  <View style={{marginTop: 7}}>
                    <Switch
                      checked={basicinfo.custom_product_display_2}
                      disabled={this.props.dis2}
                      onChange={(value, name) => {
                        this.props.show === true
                          ? this.props.permissionCheck()
                          : this.props.productInfoHandleChange(
                              value,
                              'custom_product_display_2'
                            );
                      }}
                      offColor="#5c6e79"
                      onColor="#65baea"
                      offHandleColor="#969696"
                      onHandleColor="#fbfdfe"
                      handleDiameter={22}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                    />
                  </View>
                </View>
              </View>
            </View>
            <View style={{marginTop: 15}}>
              <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: 'column', width: '70%'}}>
                  <Text style={{color: '#fff'}}>Custom Product 3</Text>
                  <TextInput
                    testID='customProductInput3'
                    editable={this.props.cust_prod3}
                    style={{
                      borderRadius: 10,
                      padding: 10,
                      backgroundColor: '#dbdada',
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 15
                    }}
                    value={basicinfo.custom_product_3}
                    onChangeText={(text, name) => {
                      this.props.show === true
                        ? this.props.permissionCheck()
                        : this.props.productInfoHandleChange(
                            text,
                            'custom_product_3'
                          );
                    }}
                  />
                </View>
                <View style={{flexDirection: 'column', marginLeft: 10}}>
                  <Text style={{color: '#fff'}}>Display</Text>
                  <View style={{marginTop: 7}}>
                    <Switch
                      checked={basicinfo.custom_product_display_3}
                      disabled={this.props.dis3}
                      onChange={(value, name) => {
                        this.props.show === true
                          ? this.props.permissionCheck()
                          : this.props.productInfoHandleChange(
                              value,
                              'custom_product_display_3'
                            );
                      }}
                      offColor="#5c6e79"
                      onColor="#65baea"
                      offHandleColor="#969696"
                      onHandleColor="#fbfdfe"
                      handleDiameter={22}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }
}
export default ScanPackOptionMobileView;
