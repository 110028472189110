import React from 'react';
import { View, TouchableOpacity, Alert, Linking, StyleSheet } from 'react-native';

const BootButtonComponent = () => {
  const openLink = () => {
    Linking.openURL('https://groovepacker.com/GPBOT.html')
  }
  return (
    <View>
      <TouchableOpacity style={styles.circularButton}
        title="Click Me"
        TouchableHighlight testID='bootButton' onPress={openLink}        
      >
         <i
            class="icon-comment"
            style={{ color: '#fff' }}
        />
      </TouchableOpacity>
    </View>
  );
};
const styles = StyleSheet.create({
    circularButton: {
      width: 40,
      height: 40,
      borderRadius: 50, // half of width and height to make it circular
      backgroundColor: '#95abbf',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonText: {
      color: 'white',
      fontSize: 16,
    },
  });
  
export default BootButtonComponent;
