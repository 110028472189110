import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

const usePersistentMenuState = (menuId, defaultValue = true) => {
  const user = useSelector(state => state.user.userInfo);
  const {current_tenant,name} = user

  const storageKey = user
    ? `${current_tenant}-${name}-${menuId}-isOpen`
    : `${menuId}-isOpen`;

  const [isOpen, setIsOpen] = useState(() => {
    const storedValue = localStorage.getItem(storageKey);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  });

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem(storageKey, JSON.stringify(isOpen));
    }
  }, [isOpen, storageKey, user]);

  return [isOpen, toggleDropdown];
};

export default usePersistentMenuState;
