import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ORDER_LIST,
  ORDER_LIST_FAIL,
  ORDER_DETAIL,
  ORDER_DETAIL_FAIL,
  SEARCH_ORDER,
  SEARCH_ORDER_COUNT,
  UPDATE_ORDER_QUANTITY,
  ADD_ORDER_ITEMS,
  REMOVE_ORDER_ITEMS,
  REMOVE_BOX_ITEMS,
  ORDER_DETAIL_LOADER,
  REMOVE_BOX,
  ADD_BOX,
  UPDATE_ORDER_INFO,
  REMOVE_ORDER_ITEMS_FAIL,
  UPDATE_ORDER_INFO_FAIL,
  ADD_ORDER_ITEMS_FAIL,
  RECORD_EXCEPTION,
  CLEAR_EXCEPTION,
  ADD_ORDER_IMAGE,
  ADD_ORDER_IMAGE_FAIL,
  ADD_ORDER_IMAGE_LOADING,
  ADD_ORDER_IMAGE_CLEAR,
  REMOVE_PACKING_IMAGE_LOADING,
  REMOVE_PACKING_IMAGE_DONE,
  REMOVE_PACKING_IMAGE_FAIL,
  GET_SHIPMENT_ID_SUCCESS,
  GET_SHIPMENT_ID_FAIL,
  IS_LOADING,
  GET_SS_LABEL,
  GET_SS_LABEL_FAIL,
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ADDRESS_FAIL,
  FETCH_REAL_TIME_RATES,
  FETCH_REAL_TIME_RATES_FAIL,
  FETCH_REAL_TIME_RATES_SELECTED,
  FETCH_REAL_TIME_RATES_SELECTED_FAIL,
  CARRIER_LOADING,
  SET_CARRIER_VISIBILITY,
  SET_CARRIER_VISIBILITY_FAIL,
  SET_RATE_VISIBILITY,
  SET_RATE_VISIBILITY_FAIL,
  CREATE_SS_LABEL,
  CREATE_SS_LABEL_FAIL,
  SET_LABEL_SHORTCUT,
  SET_LABEL_SHORTCUT_FAIL,
  PURCHASE_LOADING,
  PURCHASE_LOADING_DONE,
  ADD_ITEM_BOX,
  SET_CONTRACTED_CARRIER,
  SET_CONTRACTED_CARRIER_FAIL,
  SET_PRESETS,
  SET_PRESETS_FAIL,
  IS_ORDER_CREATED,
  ARE_ORDERS_UPDATED,
  GET_ORDERS_TAGS,
  CREATE_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL
} from '../constants';

export function GetOrderList(data, call) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    if (call === false) {
      dispatch({
        type: ORDER_LIST_FAIL
      });
    } else {
      axios({
        method: 'get',
        url: `${url}/orders.json?filter=${data.filter}&order=${data.order}&limit=${data.limit}&offset=${data.offset}&app=${data.app}&count=${data.count}&sort=${data.sort}&oldest_unscanned=${data.oldestUnscanned}`,
        headers: {Authorization: `Bearer ${access_token}`}
      })
        .then(res => {
          dispatch({
            type: ORDER_LIST,
            payload: res.data
          });
        })
        .catch(error => {
          if (error?.response?.status == '401') {
            dispatch({
              type: ORDER_LIST_FAIL,
              payload: error?.response?.status
            });
          }
        });
    }
  };
}

export function GetOrderDetail(detail) {
  return async dispatch => {
    dispatch(LoaderShow(true));
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/orders/${detail?.id}.json`,
      headers: {Authorization: `Bearer ${access_token}`}
      // data: detail.orderDetail
    })
      .then(data => {
        dispatch({
          type: ORDER_DETAIL,
          payload: data.data.order
        });
        dispatch(LoaderShow(false));
      })
      .catch(error => {
        dispatch({
          type: ORDER_DETAIL_FAIL,
          payload: error?.response?.status
        });
        dispatch(LoaderShow(false));
      });
  };
}

export function SearchOrder(data) {
  
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    // debugger
    dispatch({
      type: IS_LOADING,
      payload: true
    });
    axios({
      method: 'get',
      url: `${url}/orders/search.json?search=${data.search}&order=${data.order}&limit=${data.limit}&offset=${data.offset}&app=${data.app}`,
      headers: {Authorization: `Bearer ${access_token}`},
    })
      .then(res => {
        // console.log("Response of Order Search ====>",res?.data?.orders);
        dispatch({
          type: SEARCH_ORDER,
          payload: res?.data?.orders
        });
        dispatch({
          type: SEARCH_ORDER_COUNT,
          payload: res?.data?.orders_count
        });
        dispatch({
          type: IS_LOADING,
          payload: false
        });
      })
      .catch(error => {
        // console.log("Error of Order Search ====>",error);
        // dispatch({
        //   type: SEARCH_ORDER_FAIL,
        //   payload: error,
        // });
      });
    dispatch({
      type: IS_LOADING,
      payload: false
    });
  };
}

export function ResetSearchOrder() {
  return async dispatch => {
    dispatch({
      type: ORDER_DETAIL,
      payload: ''
    });
  };
}

export function UpdateOrderQuantity(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/update_item_in_order.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    }).then(async res => {
      dispatch({
        type: UPDATE_ORDER_QUANTITY,
        payload: res.data,
        time: new Date()
      });
    });
  };
}

export function AssignOrdersToUsers(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/assign_orders_to_users.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    });
  };
}

export function DeassignOrdersToUsers(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/deassign_orders_from_users.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    });
  };
}
export function UpdateOrderStatus(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/change_orders_status.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    });
  };
}

export function CancelTagUpdate(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/cancel_tagging_jobs.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    });
  };
}

export function GetAllTags() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/order_tags.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(res => {
        console.log("Tags imported")
        dispatch({
          type: GET_ORDERS_TAGS,
          payload: {data: res.data, time: new Date()}
        })
      })
      .catch(error => {
        console.log("Error while importig Tags imported")
      });
  };
}

export function SearchTags(search) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/order_tags/search.json?name=${search}`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(res => {
        console.log("Tags imported")
        dispatch({
          type: GET_ORDERS_TAGS,
          payload: {data: res.data, time: new Date()}
        })
      })
      .catch(error => {
        console.log("Error while searching Tags")
      });
  };
}

export function AddTagsToOrder(orderID, tags) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    // let date = new Date(orderID.id)
    //   debugger
    //   if (date.getTime() === orderID.id) {
    //     orderID.id = null; // or use 'nil' if your API specifically expects 'nil'
    //   }
      const data = {
        order_id: orderID,
        tags: tags // Assuming tags is an array or a list of tags
      };

      // dispatch({ type: ADD_ORDER_LOADING });

    axios({
      method: 'post',
      url: `${url}/order_tags/create_or_update.json`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        'content-type': 'application/json'
      },
      data: data
    })
      .then(res => {
        dispatch({
          type: ADD_ORDER_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        // dispatch({
        //   type: ADD_ORDER_FAIL,
        //   error: error.response ? error.response.data : 'Network Error'
        // });
        console.log("error", error)
      });
  };
}


export function AddOrderItems(orderID, data) {
  // console.log("unique", orderID);
  // console.log("unique order", data);
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/${orderID}/add_item_to_order.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(async res => {
        // console.log("akerman", res)
        dispatch({
          type: ADD_ORDER_ITEMS,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_ORDER_ITEMS_FAIL,
          payload: error.response.status,
          time: new Date()
        });
      });
  };
}

export function RemoveOrderItems(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/remove_item_from_order.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(async res => {
        dispatch({
          type: REMOVE_ORDER_ITEMS,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: REMOVE_ORDER_ITEMS_FAIL,
          payload: error.response.status,
          time: new Date()
        });
      });
  };
}

export function RemoveOrderItemsQty(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/remove_item_qty_from_order.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(async res => {
        dispatch({
          type: REMOVE_ORDER_ITEMS,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: REMOVE_ORDER_ITEMS_FAIL,
          payload: error.response.status,
          time: new Date()
        });
      });
  };
}

export function UpdateOrderInfo(ID, data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'put',
      url: `${url}/orders/${ID}.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(async res => {
        dispatch({
          type: UPDATE_ORDER_INFO,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_ORDER_INFO_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
      });
  };
}

export function printScanOrder(ID) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    {
      axios({
        method: 'get',
        url: `${url}/orders/generate_packing_slip.json`,
        headers: {
          Authorization: `Bearer ${access_token}`
        },
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'barcode.pdf');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {});
    }
  };
}

export function activityTextFile(id) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      // /orders/:id/print_activity_log
      url: `${url}/orders/${id}/print_activity_log`,
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'activities.txt');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {});
  };
}

export function emailCustomer(id) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/${id}/send_packing_cam_email`,
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }).catch(error => {});
  };
}

export function recordException(id, data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/${id}/record_exception.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    }).then(res => {
      dispatch({
        type: RECORD_EXCEPTION,
        payload: res.data
      });
    });
  };
}

export function clearException(id) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/${id}/clear_exception.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    }).then(res => {
      dispatch({
        type: CLEAR_EXCEPTION,
        payload: res.data
      });
    });
  };
}

export function AddOrderImage(orderID, data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    const img = data.substring(data.indexOf(',') + 1);
    const type = data.substring(data.indexOf('/') + 1, data.indexOf(';'));
    // console.log("type here------->>>>>>>",data)
    const datanew = {
      base_64_img_upload: true,
      order_id: orderID,
      image: {
        image: img,
        content_type: `image/${type}`,
        original_filename: `order${orderID}.${type}`
      }
    };
    dispatch({
      type: ADD_ORDER_IMAGE_LOADING
    });
    axios({
      method: 'post',
      url: `${url}/scan_pack/upload_image_on_s3`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        'content-type': 'application/json'
      },
      data: datanew
    })
      .then(res => {
        dispatch({
          type: ADD_ORDER_IMAGE,
          payload: {
            status: res.data.status,
            image: res.data.status ? res.data.image : null
          }
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_ORDER_IMAGE_FAIL
        });
      });
  };
}

export function clearImageStates() {
  return async dispatch => {
    dispatch({
      type: ADD_ORDER_IMAGE_CLEAR
    });
  };
}

export function RemoveOrderImage(orderID, imageID) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    const datanew = {
      packing_cam_id: imageID
    };
    dispatch({
      type: REMOVE_PACKING_IMAGE_LOADING
    });
    axios({
      method: 'post',
      url: `${url}/orders/${orderID}/remove_packing_cam_image`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        'content-type': 'application/json'
      },
      data: datanew
    })
      .then(res => {
        dispatch({
          type: REMOVE_PACKING_IMAGE_DONE,
          payload: {status: res.data.status, id: imageID}
        });
      })
      .catch(error => {
        dispatch({
          type: REMOVE_PACKING_IMAGE_FAIL
        });
      });
  };
}

export function Getshipment(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/scan_pack/get_shipment.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data
    })
      .then(res => {
        dispatch({
          type: GET_SHIPMENT_ID_SUCCESS,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_SHIPMENT_ID_FAIL,
          payload: err.data
        });
      });
  };
}

export function fetchLabelData(data, call) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    if (call === false) {
      dispatch({
        type: GET_SS_LABEL_FAIL
      });
    } else {
      dispatch({
        type: CARRIER_LOADING
      });
      axios({
        method: 'post',
        url: `${url}/stores/fetch_label_related_data.json`,
        headers: {Authorization: `Bearer ${access_token}`},
        data
      })
        .then(res => {
          dispatch({
            type: GET_SS_LABEL,
            payload: res.data
          });
        })
        .catch(error => {
          if (error?.response?.status == '401') {
            dispatch({
              type: GET_SS_LABEL_FAIL,
              payload: error?.response?.status
            });
          }
        });
    }
  };
}

export function RemoveBoxItems(payload) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    const urlCheck = payload.kit_product_id ? `${url}/box/remove_from_box.json?box_id=${payload.box_id}&order_item_id=${payload.order_item_id}&kit_product_id=${payload?.kit_product_id}` : `${url}/box/remove_from_box.json?box_id=${payload.box_id}&order_item_id=${payload.order_item_id}`
    axios({
      method: 'put',
      url: urlCheck,
      headers: {Authorization: `Bearer ${access_token}`},
      body: payload
    })
      .then(async res => {
        // console.log("akerman", res)
        dispatch({
          type: REMOVE_BOX_ITEMS,
          payload: res.data,
          time: new Date()
        });
        dispatch(
          GetOrderDetail({
            id: payload.id
          })
        );
      })
      .catch(error => {
        dispatch({
          type: REMOVE_BOX_ITEMS,
          payload: error.response.status,
          time: new Date()
        });
      });
  };
}

export function LoaderShow(payload) {
  return async dispatch => {
    dispatch({
      type: ORDER_DETAIL_LOADER,
      payload: payload,
    });
  }
}

export function RemoveBox(payload) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'put',
      url: `${url}/box/delete_box.json?id=${payload.id}`,
      headers: {Authorization: `Bearer ${access_token}`}
      // body: payload
    })
      .then(async res => {
        // console.log("akerman", res)
        dispatch({
          type: REMOVE_BOX,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: REMOVE_BOX,
          payload: error.response.status,
          time: new Date()
        });
      });
  };
}

export function AddBox(payload, goNext) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/box.json?name=${payload.name}&order_id=${payload.order_id}`,
      headers: {Authorization: `Bearer ${access_token}`},
      body: payload
    })
      .then(async res => {
        // console.log("akerman", res)
        goNext();
        dispatch({
          type: ADD_BOX,
          payload: res.data,
          time: new Date()
        });
        if(res.status === 200){
          dispatch(
            GetOrderDetail({
              id: payload.order_id
            })
          );
        }
      })
      .catch(error => {
        dispatch({
          type: ADD_BOX,
          payload: error.response.status,
          time: new Date()
        });
      });
  };
}

export function AddItemBox(ID) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/products.json?filter=all&sort=&order=&is_kit=-1&limit=20&offset=0`,
      headers: {Authorization: `Bearer ${access_token}`}
      // body: payload
    })
      .then(async res => {
        dispatch({
          type: ADD_ITEM_BOX,
          // payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_ITEM_BOX,
          // payload: error.response.status,
          time: new Date()
        });
      });
  };
}

export function PrintSlip(payload) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    {
      axios({
        method: 'post',
        url: `${url}/orders/generate_packing_slip.json`,
        headers: {
          Authorization: `Bearer ${access_token}`
          // 'content-type': 'application/pdf'
        },
        responseType: 'blob',
        data: payload
      })
        .then(response => {
          const urls = window.URL.createObjectURL(
            new Blob(response.config.url, {type: 'application/pdf'})
          );
          window.open(urls);
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', 'multiboxslip.pdf');
          // document.body.appendChild(link);
          // link.click();
        })
        .catch(error => {});
    }
  };
}

export function fetchRealTimeRates(data, call) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    if (call === false) {
      dispatch({
        type: GET_SS_LABEL_FAIL
      });
    } else {
      dispatch({
        type: CARRIER_LOADING
      });
      axios({
        method: 'post',
        url: `${url}/orders/get_realtime_rates`,
        headers: {Authorization: `Bearer ${access_token}`},
        data
      })
        .then(res => {
          dispatch({
            type: FETCH_REAL_TIME_RATES,
            payload: res.data
          });
        })
        .catch(error => {
          if (error?.response?.status == '401') {
            dispatch({
              type: FETCH_REAL_TIME_RATES_FAIL,
              payload: error?.response?.status
            });
          }
        });
    }
  };
}

export function fetchRealTimeRatesSelected(data, call) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    if (call === false) {
      dispatch({
        type: GET_SS_LABEL_FAIL
      });
    } else {
      dispatch({
        type: CARRIER_LOADING
      });
      axios({
        method: 'post',
        url: `${url}/orders/get_realtime_rates`,
        headers: {Authorization: `Bearer ${access_token}`},
        data
      })
        .then(res => {
          dispatch({
            type: FETCH_REAL_TIME_RATES_SELECTED,
            payload: res.data
          });
        })
        .catch(error => {
          if (error?.response?.status == '401') {
            dispatch({
              type: FETCH_REAL_TIME_RATES_SELECTED_FAIL,
              payload: error?.response?.status
            });
          }
        });
    }
  };
}

export function updateOrderAddress(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders/update_ss_label_order_data`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: data
    })
      .then(async res => {
        dispatch({
          type: UPDATE_ORDER_ADDRESS,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_ORDER_ADDRESS_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
      });
  };
}

export function setCarrierVisibility(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/shipstation_rest_credentials/set_carrier_visibility`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: data
    })
      .then(async res => {
        dispatch({
          type: SET_CARRIER_VISIBILITY,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: SET_CARRIER_VISIBILITY_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
      });
  };
}

export async function asyncSetCarrierVisibility(data) {
  try {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    const res = await axios({
      method: 'post',
      url: `${url}/shipstation_rest_credentials/set_carrier_visibility`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: data
    });
    return res.data;
  } catch (error) {
    return error?.respose?.status;
  }
}

export function setRateVisibility(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/shipstation_rest_credentials/set_rate_visibility`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: data
    })
      .then(async res => {
        dispatch({
          type: SET_RATE_VISIBILITY,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: SET_RATE_VISIBILITY_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
      });
  };
}

export function createSsLabel(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    dispatch({
      type: PURCHASE_LOADING,
      time: new Date()
    });
    axios({
      method: 'post',
      url: `${url}/orders/create_ss_label`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: data
    })
      .then(async res => {
        dispatch({
          type: PURCHASE_LOADING_DONE,
          time: new Date()
        });
        dispatch({
          type: CREATE_SS_LABEL,
          payload: res.data,
          time: new Date()
        });
        
      })
      .catch(error => {
        dispatch({
          type: CREATE_SS_LABEL_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
        dispatch({
          type: PURCHASE_LOADING_DONE,
          time: new Date()
        });
      });
  };
}

export function setLabelShortcut(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/shipstation_rest_credentials/set_label_shortcut.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: data
    })
      .then(async res => {
        dispatch({
          type: SET_LABEL_SHORTCUT,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: SET_LABEL_SHORTCUT_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
      });
  };
}

export function setContractedCarrier(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/shipstation_rest_credentials/set_contracted_carriers`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: data
    })
      .then(async res => {
        dispatch({
          type: SET_CONTRACTED_CARRIER,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: SET_CONTRACTED_CARRIER_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
      });
  };
}

export function setPresets(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/shipstation_rest_credentials/set_presets`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: data
    })
      .then(async res => {
        dispatch({
          type: SET_PRESETS,
          payload: res.data,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: SET_PRESETS_FAIL,
          payload: error?.response?.status,
          time: new Date()
        });
      });
  };
}

export function CreateOrder() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/orders.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(data => {
        dispatch({
          type: CREATE_ORDER,
          payload: data.data.order.id
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function SetIsOrderCreated() {
  return dispatch => {
    dispatch({
      type: IS_ORDER_CREATED,
      payload: true
    });
  };
}

export function SetOrdersUpdated() {
  return dispatch => {
    dispatch({
      type: ARE_ORDERS_UPDATED,
      payload: true
    });
  };
}
export function ResetOrdersUpdated() {
  return dispatch => {
    dispatch({
      type: ARE_ORDERS_UPDATED,
      payload: false
    });
  };
}
export function ResetOrdersTagsUpdated() {
  return dispatch => {
    dispatch({
      type: ADD_ORDER_FAIL,
      payload: false
    });
  };
}
