import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  FlatList,
  SafeAreaView,
  Vibration,
} from "react-native";
import styles from "../style/orderdetail";
import { FontAwesome } from "@expo/vector-icons";
import { useSelector } from "react-redux";

function ItemsList(props) {
  const Capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };
  const colors = {
    active: '#7bfac3',
    inactive: '#979797',
    new: '#FFB538'
  }
  const isHaptic = useSelector((state) => state.menu.isHaptics);
  return (
    <SafeAreaView>
      {props.orderList?.length > 0 && (
        <View style={{ overflow: "hidden" }}>
          {props?.orderList?.length > 0 && (
            <FlatList
              data={props.orderList}
              keyExtractor={(item) => item.id}
              extraData={props.orderList}
              renderItem={({ item }) => (
                <TouchableOpacity
                  testID="selectItem"
                  onPress={() => {
                    isHaptic && Vibration.vibrate(30)
                    props.onPress(item);
                  }}
                  delayLongPress={1000}
                >
                  <View
                    style={[
                      styles.itemContainer,
                      { justifyContent: "center", alignItems: "center" },
                    ]}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        paddingHorizontal: "2%",
                        width: "100%",
                        justifyContent: "space-around",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                      // style={styles.itemFlexRow}
                    >
                      {props.selected?.includes(item.id) ? (
                        <FontAwesome
                          name="dot-circle-o"
                          style={{
                            resizeMode: "contain",
                            width: "5%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          size={24}
                          color="rgba(250, 250, 250, .4)"
                        />
                      ) : (
                        <FontAwesome
                          name="circle-o"
                          style={{
                            resizeMode: "contain",
                            width: "5%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          size={24}
                          color="rgba(250, 250, 250, .4)"
                        />
                      )}
                      <View
                        style={{
                          width: "95%",
                          flexDirection: "row",
                          backgroundColor: props.selected?.includes(item.id)
                            ? "rgba(250, 250, 250, .1)"
                            : "",
                          borderBottomWidth: 0.7,
                          borderColor: "white",
                          justifyContent: "space-around",
                        }}
                      >
                        <View style={[styles.addItemView, { width: "25%" }]}>
                          <Text style={[styles.itemsku, { fontSize: 16 }]}>
                            {item[props.c1]}
                          </Text>
                        </View>

                        <View style={[styles.addItemView, { width: "10%" }]}>
                          <Text style={[styles.itemName2, { fontSize: 16 }]}>
                            {item[props.c2]}
                          </Text>
                        </View>

                        <View
                          style={[
                            styles.addItemView,
                            {
                              width: "35%",
                            },
                          ]}
                        >
                          <Text style={[styles.itemName2, { fontSize: 16 }]}>
                            {item[props.c3]}
                          </Text>
                        </View>

                        <View style={[styles.addItemView, { width: "15%" }]}>
                          <Text style={[styles.statusNew, {fontSize: 16, 
                            color: props.addItem && colors[item.status] }]}>
                            {Capitalize(item.status)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            />
          )}
        </View>
      )}
    </SafeAreaView>
  );
}

export default ItemsList;