import React, {Component} from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  Alert,
  Dimensions,
  BackHandler,
  TouchableOpacity,
  ScrollView
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {connect} from 'react-redux';
import {GetItem, SetItem} from '../../actions/updateAsyncAction';
import CommonStyles from '../../style/commonStyles';

import {
  GetOrderDetail,
  UpdateOrderQuantity,
  RemoveOrderItems,
  UpdateOrderInfo,
  UpdateOrderStatus,
  activityTextFile,
  emailCustomer,
  recordException,
  clearException,
  RemoveBoxItems,
  RemoveBox,
  AddBox,
  AddItemBox,
  PrintSlip
} from '../../actions/orderActions';
import {OrderBackButtonClick} from '../../actions/headerActions';
import ItemsDetail from './items_detail';
import NotesDetail from './notes_detail';
import {SubmitLog} from '../../actions/saveLogAction';
import ActivitiesExceptionDetail from './activities_exception_detail';
import MultiBoxDetail from './multi_box_detail';
import InformationDetail from './information_detail';
import styles from '../../style/orderdetail';
import {LinearGradient} from 'expo-linear-gradient';
import {GetBothSettings} from '../../actions/userAction';
import InterNetChecks from '../internateConnectionCheck';
import InerternetConnectionAlert from '../internetConnectionAlert';
import PackingCamDetail from '../scanpack/packing_cam_detail';
import ShippingLabel from './shipping_label';

let saveData = false;
let productID = 0;
let removeOrder = true;
let updateOrder = true;
let fetchData = true;
let isfromEmailPopup = false;
let updateOrderSuccessNew = true;
const windowHeight = Dimensions.get('window').height;

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        filter: 'awaiting',
        order: 'DESC',
        limit: '1',
        offset: '0'
      },
      dataList: {
        filter: 'awaiting',
        order: 'DESC',
        limit: '1',
        offset: '0',
        app: 'app'
      },
      visible: false,
      itemActive: true,
      notesActive: false,
      informationActive: false,
      activitiesActive: false,
      multiBoxActive: false,
      packingCamActive: false,
      shippingLabelActive: false,
      items: [],
      removeIds: [],
      order: [],
      windowWidth: 0,
      popInternet: true,
      productPermissionPopup: false,
      isFromProductPage: false
    };
    window.addEventListener('resize', this.update);
  }

  componentDidMount() {
    const sI = setInterval(this.test, 2000);
    this.props.GetBothSettings();
    const id = this.props.route?.params?.item?.id || null;
    this.setState({id});

    if (id) {
      this.apiCall(id);
      this.multipleTime();
    }

    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.backAction
    );
    this.update();
    if (this.props?.route?.params?.userData?.role?.add_edit_order_items === false) {
      this.setState({productPermissionPopup: true});
    }
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };
  backAction = () => {
    Alert.alert('Hold on!', 'Are you sure you want to go back?', [
      {
        text: 'Cancel',
        onPress: () => null,
        style: 'cancel'
      },
      {text: 'YES', onPress: () => this.updateBasicInfo()}
    ]);
    return true;
  };

  multipleTime = async () => {
    let getItem = await AsyncStorage.getItem('logsArray');
    let convert = getItem && JSON.parse(getItem);
    this.props.SubmitLog(convert);
  };
  removeBoxItem = (box_id, order_item_id) => {
    let data = {
      box_id,
      order_item_id
    };
    this.props.RemoveBoxItems(data);
  };

  removeBox = id => {
    let data = {
      id
    };
    this.props.RemoveBox(data);
  };

  addBox = item => {
    this.props.AddBox(item);
  };

  addItemBox = ID => {
    this.props.AddItemBox(ID);
  };
  printSlip = item => {
    this.props.PrintSlip(item);
  };

  componentDidUpdate(prevProps) {
    const {
      orderDetail,
      removeOrderItems,
      updateOrderSuccess,
      route,
      navigation
    } = this.props;

    if (orderDetail !== this.state.order) {
      this.setState({order: orderDetail}, () => {
        if (route?.params?.from === 'ScanPackItem' || route.params.isFromGrid) {
          this.changeState('shippingLabelActive');
        }
      });
    }

    if (removeOrder && removeOrderItems) {
      this.apiCall(this.state.id);
      removeOrder = false;
    }

    if (updateOrder && route?.params?.from === 'UpdateOrder') {
      const id = route.params.data;
      this.apiCall(id);
      updateOrder = false;
      navigation.navigate('OrderDetail', {data: null, from: null});
    }

    if (
      updateOrderSuccessNew &&
      updateOrderSuccess?.scan_pack_data &&
      updateOrderSuccess?.scan_pack_data[0] &&
      updateOrderSuccess?.scan_pack_data[0].scan_hash.data
    ) {
      if (isfromEmailPopup) {
        this.setState({visible: false});
        updateOrderSuccessNew = false;
        isfromEmailPopup = false;
      } else if (route.params.page === 'OrderSearch') {
        this.setState({visible: false});
        this.goBackPage()
        updateOrderSuccessNew = false;
      }else if (route.params.page === 'AllOrders') {
        this.setState({visible: false});
        navigation.navigate('AllOrders', {data: {from: 'orderDetailPage'}});
        updateOrderSuccessNew = false;
      }
       else {
        this.setState({visible: false});
        navigation.navigate('ScanPackItem', {
          data: {
            from: 'orderDetailPage',
            order: route.params?.callOrder
          }
        });
        updateOrderSuccessNew = false;
      }
    }
  }
  apiCall = id => {
    if (id !== null) {
      const detail = {
        id,
        orderDetail: this.state.data
      };
      this.props.GetOrderDetail(detail);
    }
  };

  redirect(item) {
    this.props.navigation.navigate('ProductDetail', {
      data: item,
      token: this.state.token,
      url: this.state.url
    });
  }

  changeState = state => {
    const newState = {
      itemActive: false,
      notesActive: false,
      informationActive: false,
      activitiesActive: false,
      multiBoxActive: false,
      packingCamActive: false,
      shippingLabelActive: false
    };

    switch (state) {
      case 'itemActive':
        newState.itemActive = true;
        break;
      case 'notesActive':
        newState.notesActive = true;
        break;
      case 'informationActive':
        newState.informationActive = true;
        break;
      case 'activitiesActive':
        newState.activitiesActive = true;
        break;
      case 'multiBoxActive':
        newState.multiBoxActive = true;
        break;
      case 'packingCamActive':
        newState.packingCamActive = true;
        break;
      case 'shippingLabelActive':
        newState.shippingLabelActive = true;
        break;
      default:
        break;
    }
    this.setState(newState);
  };

  updateQuantity = async item => {
    console.log("iiitem:", item)
    if (item) {
      let data = {
        orderitem: item.iteminfo.id,
        qty: item.iteminfo.qty && parseInt(item.iteminfo.qty),
        on_ex: 'on GPX'
      };
      await this.props.UpdateOrderQuantity(data);
    }
  };

  addOrderItem() {
    this.changeState('itemActive');
    updateOrder = true;
  }

  removeOrderItem(ids) {
    const {removeIds} = this.state;
    const index = removeIds.indexOf(ids.iteminfo.id);
    if (index !== -1) {
      removeIds.splice(index, 1);
    } else {
      removeIds.push(ids.iteminfo.id);
    }
    this.setState({removeIds: [...removeIds]});
  }

  handleChange(text, item, index) {
    let qtyValue = item.iteminfo;
    qtyValue['qty'] = text && JSON.parse(text);
    let order = this.state.order;
    let items = order.items;
    items[index].iteminfo = qtyValue;
    order.items = items;
    this.setState({order});
  }

  removeItems = async () => {
    const {removeIds} = this.state;
    // let removeIds = this.state.removeIds;
    if (removeIds && removeIds.length > 0) {
      const data = {
        orderitem: removeIds,
        on_ex: 'on GPX'
      };
      await this.props.RemoveOrderItems(data);
      removeOrder = true;
    }
  };
  goToProductDetail = id => {
    let page = 'OrderDetail';
    this.props.navigation.navigate('ProductDetail', {
      productInfo: id,
      userData: this.props.route.params.userInfo,
      page: page,
      onComeBack: () => {
        this.apiCall(this.state.order?.basicinfo?.id);
        this.props.navigation.goBack()
      }
    });
  };

  handleBasicInfo(name, value) {
    let tempInfo = this.state?.order?.basicinfo;
    tempInfo[name] = value;
    this.setState({tempInfo});
  }

  updateOrderStatus = value => {
    let basicInfo = this.state?.order?.basicinfo;
    basicInfo.status = value;
    const data = {
      filter: 'all',
      inverted: false,
      limit: 20,
      offset: 0,
      order: 'DESC',
      orderArray: [{id: this.state.order?.basicinfo?.id}],
      product_search_toggle: 'true',
      reallocate_inventory: false,
      search: '',
      select_all: false,
      sort: '',
      status: value
    };
    this.props.UpdateOrderStatus(data);
  };

  updateBasicInfo = async () => {
    this.setState({visible: true});
    let basicInfo = this.state?.order?.basicinfo;
    if (basicInfo) {
      basicInfo['app'] = 'app';
      let id = basicInfo.id;
      let data = basicInfo;
      await this.props.UpdateOrderInfo(id, data);
      updateOrderSuccessNew = true;
    }
  };
  componentWillUnmount() {
    this.backHandler.remove();
  }
  drawerClose = () => {
    this.props.route.params.closeDrawer();
  };

  textFileDownload = id => {
    this.props.activityTextFile(id);
  };
  emailSend = id => {
    this.props.emailCustomer(id);
  };
  recordExceptionMain = (id, data) => {
    this.props.recordException(id, data);
  };

  clearExceptionMain = id => {
    this.props.clearException(id);
  };
  goBackPage = () => {
    if (this.props.route.params.page == 'OrderSearch') {
      this.props.route.params.onComeBack();
    }
    this.props.navigation.goBack();
  };
  test = async () => {
    let num = await InterNetChecks();
    if (num !== true) {
      this.setState({popInternet: false});
    } else {
      this.setState({popInternet: true});
    }
  };

  renderActivityIndicator = () => (
    <View style={{flex: 1, justifyContent: 'center', backgroundColor: 'black'}}>
      <ActivityIndicator size="large" color="white" />
      <Text style={{textAlign: 'center', color: 'white', fontSize: 16}}>
        Saving Changes...
      </Text>
    </View>
  );
  renderTabsNavigation = () => {
    const {order, popInternet} = this.state;
    const {params} = this.props.route;
    const paramsData = params;
    let sslaelFromOrderSearch = paramsData?.updateSetting?.print_ss_label;
    let checkPage = paramsData?.page;
    let fromOrderSearchPage = paramsData?.item?.print_ss_label;
    let isSearchPage = paramsData?.page === 'OrderSearch' ? true : false;

    return (
      <LinearGradient
        colors={[
          '#000',
          '#000',
          '#5c778f',
          '#5c778f',
          '#253c57',
          '#253c57',
          '#000'
        ]}
        start={[-0.5, -1]}
        style={[CommonStyles.orderDetailStyle, {height: 'auto', minHeight: '100%'}]}>
        <View style={{flex: 1}}>
          {order && (
            <>
              {popInternet === false ? (
                <InerternetConnectionAlert />
              ) : null}
              <LinearGradient
                testID="orderDetailMenu"
                colors={['#2E3D46', '#587284']}
                style={{
                  flexDirection: 'row',
                  paddingTop: 5,
                  paddingLeft: '2%'
                }}>
                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  contentContainerStyle={{
                    flexGrow: 1
                  }}>
                  <TouchableOpacity
                    testID="showItems"
                    onPress={this.changeState.bind(this, 'itemActive')}
                    style={[
                      {
                        backgroundColor: this.state.itemActive ? '#000' : ''
                      },
                      styles.tabBtn
                    ]}>
                    <Text style={[styles.tabLabel]}>Order Items</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID="showNotes"
                    onPress={this.changeState.bind(this, 'notesActive')}
                    style={[
                      {
                        backgroundColor: this.state.notesActive ? '#000' : ''
                      },
                      styles.tabBtn
                    ]}>
                    <Text style={[styles.tabLabel]}>Notes</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID="showInformation"
                    onPress={this.changeState.bind(this, 'informationActive')}
                    style={[
                      {
                        backgroundColor: this.state.informationActive
                          ? '#000'
                          : ''
                      },
                      styles.tabBtn
                    ]}>
                    <Text style={[styles.tabLabel]}>Order Info</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID="showActivities"
                    onPress={this.changeState.bind(this, 'activitiesActive')}
                    style={[
                      {
                        backgroundColor: this.state.activitiesActive
                          ? '#000'
                          : ''
                      },
                      styles.tabBtn
                    ]}>
                    <Text style={[styles.tabLabel]}>Activity</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={this.changeState.bind(this, 'multiBoxActive')}
                    style={[
                      {
                        backgroundColor: this.state.multiBoxActive ? '#000' : ''
                      },
                      styles.tabBtn
                    ]}>
                    <Text style={[styles.tabLabel]}>Multi-Box Info</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID="showPackingCam"
                    onPress={this.changeState.bind(this, 'packingCamActive')}
                    style={[
                      {
                        backgroundColor: this.state.packingCamActive
                          ? '#000'
                          : ''
                      },
                      styles.tabBtn
                    ]}>
                    <Text style={[styles.tabLabel]}>Packing Cam</Text>
                  </TouchableOpacity>
                  {(checkPage == 'OrderSearch'
                    ? fromOrderSearchPage
                    : sslaelFromOrderSearch) && (
                    <TouchableOpacity
                      dataSet={{componentName: 'shippingLabelActiveBtn'}}
                      onPress={this.changeState.bind(
                        this,
                        'shippingLabelActive'
                      )}
                      style={[
                        {
                          backgroundColor: this.state.shippingLabelActive
                            ? '#000'
                            : ''
                        },
                        styles.tabBtn
                      ]}>
                      <Text style={[styles.tabLabel]}>
                        Create Shipping Label
                      </Text>
                    </TouchableOpacity>
                  )}
                </ScrollView>
              </LinearGradient>

              {this.state.itemActive && popInternet && (
                <ItemsDetail
                  items={order}
                  token={this.state.token}
                  url={this.state.url}
                  redirect={this.redirect.bind(this)}
                  navigation={this.props.navigation}
                  updateQuantity={e => this.updateQuantity(e)}
                  addOrderItem={e => this.addOrderItem(e)}
                  updateOrderStatus={value => this.updateOrderStatus(value)}
                  handleChange={(e, item, index) =>
                    this.handleChange(e, item, index)
                  }
                  removeOrderItem={ids => this.removeOrderItem(ids)}
                  goToProductDetail={id => this.goToProductDetail(id)}
                  removeIds={this.state.removeIds}
                  removeItems={() => this.removeItems()}
                  updateBasicInfo={() => this.updateBasicInfo()}
                  back={() => this.goBackPage()}
                  userRole={paramsData?.userData}
                  show={this.state.productPermissionPopup}
                  isFromSearchPage={isSearchPage}
                  isFromProductPage={this.state.isFromProductPage}
                  resetFromProductPage={() =>
                    this.setState({isFromProductPage: false})
                  }
                />
              )}
              {this.state.notesActive && popInternet && (
                <NotesDetail
                  basicinfo={order.basicinfo}
                  navigation={this.props.navigation}
                  handleChange={(Name, Value) =>
                    this.handleBasicInfo(Name, Value)
                  }
                  updateOrderStatus={value => this.updateOrderStatus(value)}
                  updateBasicInfo={() => this.updateBasicInfo()}
                  bothSettings={this.props.bothSettings}
                  userRole={paramsData?.userData}
                  back={() => this.goBackPage()}
                  show={this.state.productPermissionPopup}
                />
              )}
              {this.state.informationActive && popInternet && (
                <InformationDetail
                  basicinfo={order.basicinfo}
                  navigation={this.props.navigation}
                  handleChange={(Name, Value) =>
                    this.handleBasicInfo(Name, Value)
                  }
                  updateOrderStatus={value => this.updateOrderStatus(value)}
                  user={this.props?.route}
                  updateBasicInfo={() => this.updateBasicInfo()}
                  back={() => this.goBackPage()}
                  bothSettings={this.props.bothSettings}
                  show={this.state.productPermissionPopup}
                />
              )}
              {this.state.activitiesActive && popInternet && (
                <ActivitiesExceptionDetail
                  basicinfo={order.basicinfo}
                  textFileDownload={id => this.textFileDownload(id)}
                  updateBasicInfo={() => this.updateBasicInfo()}
                  back={() => this.goBackPage()}
                  activities={order.activities}
                  exception={order.exception}
                  navigation={this.props.navigation}
                  orderDetail={this.props.orderDetail}
                  userRole={paramsData?.userData}
                  recordExceptionMain={(id, data) =>
                    this.recordExceptionMain(id, data)
                  }
                  updateOrderStatus={value => this.updateOrderStatus(value)}
                  show={this.state.productPermissionPopup}
                  clearExceptionMain={id => this.clearExceptionMain(id)}
                  orderDetailFun={id => this.apiCall(id)}
                  orderException={this.props.orderException}
                  orderClearException={this.props.orderClearException}
                />
              )}
              {this.state.multiBoxActive && popInternet && (
                <MultiBoxDetail
                  navigation={this.props.navigation}
                  basicinfo={order.basicinfo}
                  updateBasicInfo={() => this.updateBasicInfo()}
                  back={() => this.goBackPage()}
                  bothSettings={this.props.bothSettings}
                  handleChange={(Name, Value) =>
                    this.handleBasicInfo(Name, Value)
                  }
                  updateOrderStatus={value => this.updateOrderStatus(value)}
                  removeIds={this.state.removeIds}
                  removeOrderItem={ids => this.removeOrderItem(ids)}
                  handleChanges={(e, item) => this.handleChange(e, item)}
                  removeItems={() => this.removeItems()}
                  updateQuantity={e => this.updateQuantity(e)}
                  orderDetailFun={id => this.apiCall(id)}
                  show={this.state.productPermissionPopup}
                  orderDetail={this.props.orderDetail}
                  userRole={paramsData?.userData}
                  removeBoxItem={this.removeBoxItem}
                  removeBox={this.removeBox}
                  addBox={this.addBox}
                  addItemBox={this.addItemBox}
                  printSlip={this.printSlip}
                />
              )}
              {this.state.packingCamActive && popInternet && (
                <PackingCamDetail
                  {...this.props}
                  navigation={this.props.navigation}
                  order={this.props.orderDetail}
                  isRemovingImage={this.props.isRemovingImage}
                  updateBasicInfo={isEmail => {
                    isfromEmailPopup = isEmail;
                    this.updateBasicInfo();
                  }}
                  updateOrderStatus={value => this.updateOrderStatus(value)}
                  back={() => this.goBackPage()}
                  userRole={paramsData?.userData}
                  show={this.state.productPermissionPopup}
                  emailSend={id => {
                    this.emailSend(id);
                  }}
                />
              )}
              {this.state.shippingLabelActive && popInternet && (
                <ShippingLabel
                  navigation={this.props.navigation}
                  basicinfo={order.basicinfo}
                  userRole={this.props.route.params.userData}
                  updateOrderStatus={value => this.updateOrderStatus(value)}
                  back={() => this.goBackPage()}
                  bothSettings={this.props.bothSettings}
                />
              )}
            </>
          )}
        </View>
      </LinearGradient>
    );
  };
  render() {
    return (
      <View style={{flex: 1}} onStartShouldSetResponder={this.drawerClose}>
        {this.state.visible
          ? this.renderActivityIndicator()
          : this.renderTabsNavigation()}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderDetail: state.order.order,
    saveData: state.updateAsync.retriveData,
    removeOrderItems: state.order.removeOrderItems,
    updateOrderSuccess: state.order.updateOrder,
    orderException: state.order.exception,
    orderClearException: state.order.clearException,
    bothSettings: state.user.bothSettings,
    isRemovingImage: state.order.isRemovingImage
  };
};

const mapDispatchToProps = {
  GetOrderDetail,
  GetItem,
  UpdateOrderQuantity,
  activityTextFile,
  emailCustomer,
  RemoveOrderItems,
  UpdateOrderInfo,
  UpdateOrderStatus,
  SubmitLog,
  SetItem,
  OrderBackButtonClick,
  GetBothSettings,
  recordException,
  clearException,
  RemoveBoxItems,
  RemoveBox,
  AddBox,
  AddItemBox,
  PrintSlip
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
