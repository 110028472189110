import React, { useEffect, useState, useRef } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet, TextInput } from 'react-native';
import downArrow from '../../../assets/down_arrow.png';
import './custom_date_picker.css'
import DatePicker from '../../commons/datePicker';
import usePersistentMenuState from '../../commons/usePersistentMenuState';

const handleCancelPress = (setSearchTxt, disableFilter) => {
  setSearchTxt("");
  disableFilter();
};

let showInput = true;

const CustomTouchableOpacity = ({ onPress, selectedStatus, handleStatusSelect, disableFilter, buttonName, buttonText, getFilteredOrders, testID, showInput }) => {
  const [searchTxt, setSearchTxt] = useState("");

  return (
    <TouchableOpacity onPress={onPress} style={{ paddingLeft: 5 }} testID={testID}>
      <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems:'baseline'}}>
        <Text style={[handleStatusSelect?.includes(buttonName) && styles.dropdownActiveButton,
          {color:handleStatusSelect?.includes(buttonName) ? '#45d697' : 'white', paddingBottom: 2, fontSize: '15px', paddingLeft: 10, width: '65%', marginLeft: '1%', fontFamily: 'Poppins_300Light', marginBottom: '2px'}]}>
          {buttonText || buttonName}
        </Text>
        <TouchableOpacity onPress={disableFilter} style={[{marginHorizontal: '1%'}]} testID={testID+'_reset'}>
        {handleStatusSelect.includes(selectedStatus) &&
          <i className="icon-cancel" style={{fontSize: '15px', color: 'gray'}}></i>
        }
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

const CustomTouchableFieldOpacity = ({
  onPress,
  selectedStatus,
  handleStatusSelect,
  disableFilter,
  buttonName,
  buttonText,
  getFilteredOrders,
  testID,
  showInput,
  searchTxt,
  setSearchTxt
}) => {
  // console.log(">>>>>>...", handleStatusSelect, buttonName, handleStatusSelect?.includes(searchTxt))
  // const [searchTxt, setSearchTxt] = useState("");
  let isEnterPressed = false;

  useEffect(() => {
      if( handleStatusSelect?.includes('all_day'))
      {
        setSearchTxt('');
      }
  }, [handleStatusSelect]);

  return (
    <View testID={testID}>
      <View style={styles.customFieldContainer}>
        <View
          style={[
            styles.customFiledSubContainer,
            {
              borderColor: handleStatusSelect?.includes(searchTxt) ? 'gray' : 'transparent',
              borderWidth: searchTxt ? 1 : 0
            }
          ]}>
          <Text
            style={[
              handleStatusSelect?.includes(buttonName) &&
                styles.dropdownActiveButton,
              styles.customFiledText,
              {
                color: searchTxt ? 'rgb(69, 214, 151)' : 'white',
                width: '17%',
                marginLeft: '13%'
              }
            ]}>
            Last
          </Text>

          {showInput && (
            <TextInput
              testID="DateNumberInput"
              name="searchWithDays"
              value={searchTxt}
              onChangeText={text => {
                setSearchTxt(text);
              }}
              onSubmitEditing={() => {}}
              style={[
                styles.customFiledInput,
                {
                  color: searchTxt ? 'rgb(69, 214, 151)' : 'gray'
                }
              ]}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onPress(searchTxt);
                  isEnterPressed = true;
                }
              }}
            />
          )}
          <Text
            style={[
              handleStatusSelect?.includes(buttonName) &&
                styles.dropdownActiveButton,
                styles.customFiledText,
              {
                color: searchTxt ? 'rgb(69, 214, 151)' : 'white',
                width: '65%',
                marginLeft: '1%',
              }
            ]}>
            days
          </Text>
        </View>

        <TouchableOpacity
          onPress={() =>
            handleCancelPress(setSearchTxt, disableFilter)
          }
          style={[{marginHorizontal: '1%'}]}
          testID={testID + '_reset'}>
          {handleStatusSelect?.includes(searchTxt) && (
            <i
              className="icon-cancel"
              style={{fontSize: '15px', color: 'gray'}}></i>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};


const OrderDateDropdown = ({getFilteredOrders, resetFilters, formattedItems, productSearchList, shouldUpdateCount, clearAllFilters, resetFilterCheck, status, orderCount}) => {
  const isNumber = !isNaN(Number(status));
  const [isOpen, toggleDropdown] = usePersistentMenuState('dateDropdown');
  const [selectedStatus, setSelectedStatus] = useState(() => status);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchTxt, setSearchTxt] = useState(isNumber? status[0] : "");
  const startPickerRef = useRef(null); // Reference for the start picker
  const endPickerRef = useRef(null); // Reference for the end picker


  useEffect(() => {
      if( selectedStatus?.includes('all_day') && startDate && endDate)
      {
        clearDates();
      }
  }, [status]);

  const handleStatusSelect = (status, date = "") => {
    let statues;
    statues = status === 'start_date' ? status : statues
    statues = status === 'end_date' ? status : statues
    let isPresent = false;
    setSelectedStatus(prevSelectedStatus => {
      if (!prevSelectedStatus.includes(status)) {
        statues = [status];
        return [status];
    } else {
        statues = [prevSelectedStatus];
        isPresent = true;
        return prevSelectedStatus;
    }
    });
    if (status !== 'start_date' && status !== 'end_date') {
      clearDates();
    }
    isPresent === true ? disableFilter(status) : getFilteredOrders([statues], date)
    getFilteredOrders([status]);
  };

  const clearDates = () => {
    setEndDate(new Date());
    setStartDate(new Date());
    setTimeout(() => {
      setEndDate();
      setStartDate();
    }, 100);
  }

  const disableFilter = (statusToRemove) => {
    let updatedStatus = [];
    setSelectedStatus(prevSelectedStatus => {
        updatedStatus = prevSelectedStatus.filter(status => status !== statusToRemove);
        return updatedStatus;
    });
    getFilteredOrders(updatedStatus);
  };

  useEffect(() => {
      if(clearAllFilters === true)
      {
        setSelectedStatus([]);
        resetFilterCheck();
      }
  }, [clearAllFilters]);

  useEffect(() => {
      setSelectedStatus(status)
  }, [status]);

  return (
    <View style={{borderTop: '2px solid rgb(0, 0, 0)', top: 10}}>
      <TouchableOpacity style={styles.dateRangeButton} onPress={toggleDropdown}>
        <View style={{display: 'flex',flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', background: '#262b30'}}>
          <Text style={styles.dateRangeText}>Date Range</Text>
          <Image style={[styles.dateRangeImage, isOpen && styles.iconOpen]} source={downArrow} />
        </View>
      </TouchableOpacity>
      {isOpen && (
        <View style={{marginTop: 10}}>
          <CustomTouchableOpacity
            onPress={() => {
              handleCancelPress(setSearchTxt, disableFilter);
              handleStatusSelect('all_day');
            }}
            selectedStatus={'all_day'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('all_day');
            }}
            buttonName="all_day"
            buttonText="All Dates"
            getFilteredOrders={() => {
              getFilteredOrders();
            }}
            testID="all_day_button"
          />
          <CustomTouchableOpacity
            onPress={() => {
              handleCancelPress(setSearchTxt, disableFilter);
              handleStatusSelect('yesterday');
            }}
            selectedStatus={'yesterday'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('yesterday');
            }}
            buttonName="yesterday"
            buttonText="Yesterday"
            getFilteredOrders={() => {
              getFilteredOrders();
            }}
            testID="yesterday_button"
          />
          <CustomTouchableOpacity
            onPress={() => {
              handleCancelPress(setSearchTxt, disableFilter);
              handleStatusSelect('to_day');
            }}
            selectedStatus={'to_day'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('to_day');
            }}
            buttonName="to_day"
            buttonText="Today"
            getFilteredOrders={() => {
              getFilteredOrders();
            }}
            testID="today_button"
          />
          <CustomTouchableOpacity
            onPress={() => {
              handleCancelPress(setSearchTxt, disableFilter);
              handleStatusSelect('this_week');
            }}
            selectedStatus={'this_week'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('this_week');
            }}
            buttonName="this_week"
            buttonText="This Week"
            getFilteredOrders={() => {
              getFilteredOrders();
            }}
            testID="This_week_button"
          />
          <CustomTouchableOpacity
            onPress={() => {
              handleCancelPress(setSearchTxt, disableFilter);
              handleStatusSelect('last_week');
            }}
            selectedStatus={'last_week'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('last_week');
            }}
            buttonName="last_week"
            buttonText="Last Week"
            getFilteredOrders={() => {
              getFilteredOrders();
            }}
            testID="Last_week_button"
          />
          <CustomTouchableOpacity
            onPress={() => {
              handleCancelPress(setSearchTxt, disableFilter);
              handleStatusSelect('this_month');
            }}
            selectedStatus={'this_month'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('this_month');
            }}
            buttonName="this_month"
            buttonText="This Month"
            getFilteredOrders={() => {
              getFilteredOrders();
            }}
            testID="This_Month_button"
          />
          <CustomTouchableOpacity
            onPress={() => {
              handleCancelPress(setSearchTxt, disableFilter);
              handleStatusSelect('last_month');
            }}
            selectedStatus={'last_month'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('last_month');
            }}
            buttonName="last_month"
            buttonText="Last Month"
            getFilteredOrders={() => {
              getFilteredOrders();
            }}
            testID="Last_Month_button"
          />
          <CustomTouchableFieldOpacity
            onPress={value => {
              handleStatusSelect(value);
              clearDates();
            }}
            selectedStatus={'x_days'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('x_days');
            }}
            buttonName="x_days"
            buttonText="Last Month"
            getFilteredOrders={() => {
              getFilteredOrders('x_days');
            }}
            testID="x_days_button"
            showInput={true}
            searchTxt={searchTxt}
            setSearchTxt={text => setSearchTxt(text)}
          />
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <View>
              <Text style={styles.datePickerText}>Choose a Range</Text>
            </View>
            <DatePicker
             startDate={startDate}
             endDate={endDate}
             setStartDate={(date)=>{
              setStartDate(date);
              setSearchTxt('');
              handleStatusSelect('start_date', date);
             }}
             setEndDate={(date)=>{
              if (date === null) {
                date = '';
              }
              setEndDate(date);
              setSearchTxt('');
              handleStatusSelect('end_date', date);
             }}
             startPickerRef={startPickerRef}
             endPickerRef={endPickerRef}
            />
          </View>
        </View>
      )}
    </View>
  );
};


const styles = StyleSheet.create({
  dropdownActiveButton: {
    border: '1px solid gray',
    borderRadius: 8,
    paddingTop: 2,
    color: 'green'
  },
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    marginRight: '12px'
  },
  datePickerText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    color: 'white'
  },
  customFieldContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  customFiledSubContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
    borderStyle: 'solid'
  },
  customFiledText: {
    paddingBottom: 2,
    fontSize: '15px',
    paddingLeft: 10,
    fontFamily: 'Poppins_300Light',
    marginBottom: '2px'
  },
  customFiledInput: {
    width: '25%',
    borderRadius: '10px',
    borderColor: 'gray',
    borderWidth: 0.5,
    height: 23,
    paddingLeft: '7px',
    backgroundColor: '#262b30',
    marginLeft: '3%'
  },
  dateRangeText: {
    color: '#9BA7B4',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 13,
    padding: 5,
    marginLeft: 10
  },
  dateRangeImage: {
    width: 15,
    height: 15,
    marginRight: '4%',
    marginTop: '1.5%',
    color: 'gray'
  },
  iconOpen: {
    transform: [{rotate: '180deg'}]
  },
  dateRangeButton: {
    maxWidth: '107%',
    background: '#262B30',
    marginLeft: '-13px'
  }
});


export default OrderDateDropdown;
