import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import downArrow from '../../../assets/down_arrow.png';
import dropdownStyles from '../../style/dropdownStyles';
import usePersistentMenuState from '../../commons/usePersistentMenuState';

const CustomTouchableOpacity = ({
  onPress,
  selectedStatus,
  handleStatusSelect,
  disableFilter,
  buttonName,
  buttonText,
  orderCount,
  testID
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={dropdownStyles.customTouchable}
      testID={testID}>
      <View style={dropdownStyles.customTouchableContent}>
        <Text style={dropdownStyles.customTouchableCount}>{orderCount}</Text>
        <Text
          style={[
            handleStatusSelect?.includes(buttonName) &&
              dropdownStyles.dropdownActiveButton,
            dropdownStyles.customTouchableText
          ]}>
          {buttonText || buttonName}
        </Text>
        <TouchableOpacity
          onPress={onPress}
          style={dropdownStyles.customTouchableReset}
          testID={testID + '_reset'}>
          {handleStatusSelect.includes(buttonName) && (
            <i
              className="icon-cancel"
              style={dropdownStyles.customTouchableIcon}></i>
          )}
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

const ShowAllButton = ({onPress, showMore}) => (
  <TouchableOpacity style={dropdownStyles.showAllButton} onPress={onPress}>
    <Text style={dropdownStyles.showAllButtonText}>
      {showMore ? 'Show All' : 'Show Less'}
    </Text>
  </TouchableOpacity>
);

const OrderTagsDropdown = ({
  getFilteredOrders,
  resetFilters,
  includedTags,
  excludedTags,
  clearAllFilters,
  resetFilterCheck,
  status,
  orderCount,
  setSelectedTags,
  setFilterIncludedTags,
  filterIncludedTags
}) => {
  const [isOpen, toggleDropdown] = usePersistentMenuState('orderTagsDropdown');
  const [selectedStatus, setSelectedStatus] = useState(() => [status]);
  const [showAllIncludedTags, setShowAllIncludedTags] = useState(false);
  const [showAllExcludedTags, setShowAllExcludedTags] = useState(false);

  const handleStatusSelect = (status) => {
    let statues;
    let isPresent = false;
    setSelectedStatus(prevSelectedStatus => {
      if (!prevSelectedStatus.includes(status)) {
        statues = [status];
        return [status];
    } else {
        statues = [prevSelectedStatus];
        isPresent = true;
        return prevSelectedStatus;
    }
    });
    isPresent === true ? disableFilter(status) : getFilteredOrders(statues);
    setSelectedTags(statues, filterIncludedTags);
    getFilteredOrders(status);
  };

  const toggleShowAllIncludedTags = () => {
    setShowAllIncludedTags(prevShowAllTags => !prevShowAllTags);
  };

  const toggleShowAllExcludedTags = () => {
    setShowAllExcludedTags(prevShowAllTags => !prevShowAllTags);
  };

  const disableFilter = statusToRemove => {
    let updatedStatus = [];
    setSelectedStatus(prevSelectedStatus => {
      updatedStatus = prevSelectedStatus.filter(
        status => status !== statusToRemove
      );
      return updatedStatus;
    });
    resetFilters(updatedStatus);
  };

  useEffect(() => {
    if (clearAllFilters === true) {
      setSelectedStatus([]);
      resetFilterCheck();
    }
  }, [clearAllFilters]);

  useEffect(() => {
    if (selectedStatus) {
      setSelectedTags(selectedStatus);
    }
  }, [selectedStatus]);

  return (
    <View style={{borderTop: '2px solid rgb(0, 0, 0)'}}>
      <TouchableOpacity style={dropdownStyles.button} onPress={toggleDropdown}>
        <View style={dropdownStyles.buttonContent}>
          <Text style={dropdownStyles.text}>Tags</Text>
          <Image
            style={[dropdownStyles.icon, isOpen && dropdownStyles.iconOpen]}
            source={downArrow}
          />
        </View>
        {isOpen && (
          <View>
            <Text style={[dropdownStyles.text, dropdownStyles.includeTagsText]}>
              Orders that include these tags:
            </Text>
          </View>
        )}
      </TouchableOpacity>
      {isOpen && (
        <View style={dropdownStyles.dropdownContent}>
          {includedTags
            .slice(0, showAllIncludedTags ? includedTags.length : 2)
            .map(tag => (
              <CustomTouchableOpacity
                key={tag.name}
                onPress={() => {
                  setFilterIncludedTags(true);
                  handleStatusSelect(tag.name);
                }}
                selectedStatus={selectedStatus}
                handleStatusSelect={selectedStatus}
                disableFilter={() => disableFilter(tag.name)}
                buttonName={tag.name}
                buttonText={tag.text}
                orderCount={tag.order_count}
                testID={`${tag.name.toLowerCase()}_button`}
              />
            ))}
          {includedTags.length > 2 && (
            <ShowAllButton
              onPress={toggleShowAllIncludedTags}
              showMore={!showAllIncludedTags}
              testID={'show_all_included_tags_button'}
            />
          )}
          <View style={dropdownStyles.button}>
            <Text style={[dropdownStyles.text, dropdownStyles.includeTagsText]}>
              Orders that do not include these tags:
            </Text>
          </View>
          {excludedTags
            .slice(0, showAllExcludedTags ? excludedTags.length : 2)
            .map(tag => (
              <CustomTouchableOpacity
                key={tag.name}
                onPress={() => {
                  setFilterIncludedTags(false);
                  handleStatusSelect(tag.name);
                }}
                selectedStatus={selectedStatus}
                handleStatusSelect={selectedStatus}
                disableFilter={() => disableFilter(tag.name)}
                buttonName={tag.name}
                buttonText={tag.text}
                orderCount={tag.not_present_in_order_count}
                testID={`${tag.name.toLowerCase()}_button`}
              />
            ))}
          {excludedTags.length > 2 && (
            <ShowAllButton
              onPress={toggleShowAllExcludedTags}
              showMore={!showAllExcludedTags}
              testID={'show_all_excluded_tags_button'}
            />
          )}
        </View>
      )}
    </View>
  );
};

export default OrderTagsDropdown;
