import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import downArrow from '../../../assets/down_arrow.png';
import usePersistentMenuState from '../../commons/usePersistentMenuState';


const CustomTouchableOpacity = ({ onPress, selectedStatus, handleStatusSelect, disableFilter, buttonName, buttonText, orderCount, testID }) => {
  const getCountOfAwaitingItems = () => {
    const { awaiting, partially_scanned, scanned, cancelled, serviceissue, onhold } = orderCount;

    if (buttonName.toLowerCase() === 'awaiting') {
      return awaiting || 0;
    } else if (buttonName.toLowerCase() === 'cancelled') {
      return cancelled || 0;
    } else if(buttonName.toLowerCase() === 'scanned'){
      return scanned || 0;
    } else if(buttonName.toLowerCase() === 'partiallyscanned'){
      return partially_scanned || 0;
    } else if(buttonName.toLowerCase() === 'onhold'){
      return onhold || 0;
    }else{
      return serviceissue || 0
    }
  }

  return (
    <TouchableOpacity onPress={onPress} style={{ paddingLeft: 5 }} testID={testID}>
      <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems:'center'}}>
        <Text style={{color: 'white', width: '16%', fontSize: '12px', textAlign: 'right'}}>{orderCount && getCountOfAwaitingItems()}</Text>
        <Text style={[handleStatusSelect?.includes(buttonName) && styles.dropdownActiveButton,
          {color: 'white', paddingBottom: 2, fontSize: '15px', paddingLeft: 10, width: '65%', marginLeft: '1%', fontFamily: 'Poppins_300Light', marginBottom: '2px'}]}>
          {buttonText || buttonName}
        </Text>
        <TouchableOpacity onPress={disableFilter} style={[{marginHorizontal: '1%'}]} testID={testID+'_reset'}>
        {handleStatusSelect.includes(selectedStatus) &&
          <i className="icon-cancel" style={{fontSize: '15px', color: 'gray'}}></i>
        }
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};


const OrderStatusDropdown = ({getFilteredOrders, resetFilters, formattedItems, productSearchList, shouldUpdateCount, clearAllFilters, resetFilterCheck, status, orderCount}) => {
  const [isOpen, toggleDropdown] = usePersistentMenuState('statusDropdown');
  const [selectedStatus, setSelectedStatus] = useState(() => status);


  const handleStatusSelect = (status) => {
    let statues;
    let isPresent = false;
    setSelectedStatus(prevSelectedStatus => {
      if (!prevSelectedStatus.includes(status)) {
        statues = [...prevSelectedStatus, status];
        return [...prevSelectedStatus, status];
    } else {
        statues = [prevSelectedStatus];
        isPresent = true;
        return prevSelectedStatus;
    }
    });
    isPresent === true ? disableFilter(status) : getFilteredOrders(statues)
  };

  const disableFilter = (statusToRemove) => {
    let updatedStatus = [];
    setSelectedStatus(prevSelectedStatus => {
        updatedStatus = prevSelectedStatus.filter(status => status !== statusToRemove);
        return updatedStatus;
    });
    resetFilters(updatedStatus);
  };

  useEffect(() => {
      if(clearAllFilters === true)
      {
        setSelectedStatus([]);
        resetFilterCheck();
      }
  }, [clearAllFilters]);
  useEffect(() => {
      setSelectedStatus(status)
  }, [status]);

  return (
    <View style={{borderTop: '2px solid rgb(0, 0, 0)'}}>
      <TouchableOpacity style={{maxWidth: '107%', background: '#262B30', marginLeft: '-1px'}} onPress={toggleDropdown}>
        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Text style={{color: '#9BA7B4', fontFamily: 'Poppins_600SemiBold', fontSize: 13, padding: 5}}>
            Status
          </Text>
          <Image style={[styles.dateRangeImage, isOpen && styles.iconOpen]} source={downArrow} />
        </View>

      </TouchableOpacity>
      {isOpen && (
        <View style={{ marginTop: 10 }}>
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('Awaiting')}
            selectedStatus={'Awaiting'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('Awaiting')}}
            buttonName="Awaiting"
            orderCount={orderCount}
            testID="awaiting_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('Scanned')}
            selectedStatus={'Scanned'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('Scanned')}}
            buttonName="Scanned"
            orderCount={orderCount}
            testID="scanned_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('Cancelled')}
            selectedStatus={'Cancelled'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('Cancelled')}}
            buttonName="Cancelled"
            orderCount={orderCount}
            testID="cancelled_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('ServiceIssue')}
            selectedStatus={'ServiceIssue'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('ServiceIssue')}}
            buttonName="ServiceIssue"
            buttonText="Service Issue"
            orderCount={orderCount}
            testID="service_issue_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('PartiallyScanned')}
            selectedStatus={'PartiallyScanned'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('PartiallyScanned')}}
            buttonName="PartiallyScanned"
            buttonText="Partially Scanned"
            orderCount={orderCount}
            testID="partically_scanned_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('OnHold')}
            selectedStatus={'OnHold'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('OnHold')}}
            buttonName="OnHold"
            buttonText="Action Required"
            orderCount={orderCount}
            testID="action_required_button"
          />
        </View>
      )}
    </View>
  );
};


const styles = StyleSheet.create({
  dropdownActiveButton: {
    border: '1px solid gray',
    borderRadius: 8,
    paddingTop: 2,
  },
  dateRangeImage: {
    width: 15,
    height: 15,
    marginRight: '4%',
    marginTop: '1.5%',
    color: 'gray'
  },
  iconOpen: {
    transform: [{rotate: '180deg'}]
  },
});


export default OrderStatusDropdown;
