import React, {Component} from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import _ from 'lodash';
import {connect} from 'react-redux';
import {SearchOrder, ResetSearchOrder, GetOrderList, UpdateOrderStatus, ResetOrdersUpdated, GetAllTags, AssignOrdersToUsers, DeassignOrdersToUsers} from '../../actions/orderActions';
import styles from '../../style/productdetail';
import {Text} from 'react-native-elements';
import {LinearGradient} from 'expo-linear-gradient';
import {FontAwesome} from '@expo/vector-icons';
import {Entypo} from '@expo/vector-icons';
import OrderItemList from '../../commons/itemsList';
import CommonStyles from '../../style/commonStyles';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import OrderStatusDropdown from './orderStatusDropdown';
import '@inovua/reactdatagrid-enterprise/index.css'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import PopUpModel from '../scanpack/pop_up_model';
import i18n from 'i18n-js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import SearchNotFoundPrompt from './searchNotFoundPrompt';


import UpdateOrdersDropDowns from './updateOrdersDropDowns';
import UpdateTagsDropDowns from './updateTagsDropDowns';
import { getStatus, loadTagsData } from './commonFunctions';
import OrderDateDropdown from './orderdDateDropdown';
import OrderTagsDropdown from './orderTagsDropdown';
import CustomScrollBar from '../../commons/customScrollBar';
import OrderUserTagsDropdown from './orderUserTags';
import { OrderNumberCell } from './orderNumberCell';
import UserTagsDropdown from './userTagsDropdown';
import { getSelectedOrderIds, getSelectedOrders, getTransformedList, getUpdatedSelectedList } from '../../helpers/scanPackFunctions';
import { SelectAllButton } from './selectAllButton';

let previousData = true;
let prevSkip = 0;
let update = false;
let updateSearch = false;
let shouldUpdateCount = true;
let isFirstCall = true;
let tags_status;
let isSearchOrder = false;
let islastSearchPresent = false;
let allOrders = null;
let allOrdersWithStatus = null;
let isValuePresentForAny = false;
let displayPrompt = false;
let prevlimit = 0;
let alertMessage = ''
let data = {}
let allOrdersSelected = false;
let dateValue = '14';
let currentSelectedDate = "14";
let dateRangeValues = {"start_date": '', "end_date": ''};
let PresentFilterValues;
let unselectedOrderItems;
let FirstOrdersTagsStatus = true;
let isFirstTime = true;
let previousGridSelectionRef = undefined;
let filterIncludedTags = false;
let userAddedCount = null;

let includedTags = [
  {name: 'Awaiting', text: 'Awaiting', order_count: 0},
  {name: 'PartiallyScanned', text: 'Partially Scanned', order_count: 0},
  {name: 'Scanned', text: 'Scanned', order_count: 0}
];

let excludedTags = [
  {name: 'Cancelled', text: 'Cancelled', not_present_in_order_count: 0},
  {name: 'ServiceIssue', text: 'Service Issue', not_present_in_order_count: 0},
  {name: 'OnHold', text: 'On Hold', not_present_in_order_count: 0}
];

let usersOrderCount;
let usersOrderAssignedCount;

const orderCount = {
  awaiting: 5,
  partially_scanned: 3,
  scanned: 8,
  cancelled: 2,
  serviceissue: 1,
  onhold: 4
};


window.moment = moment
class OrderSearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      getAllItem: {
        filter: 'all',
        sort: '',
        order: 'DESC',
        is_kit: 0,
        limit: 20,
        offset: 0
      },
      searchData: {
        search: '',
        sort: '',
        order: 'DESC',
        is_kit: -1,
        limit: 20,
        offset: 0
      },
      allProduct: [],
      searchOrderCount: [],
      formattedItems: [],
      selected: [],
      searchProduct: [],
      existingItems: [],
      orderID: '',
      windowWidth: 0,
      checkWidth: 0,
      searchTxt: '',
      start: 0,
      end: 10,
      currentPage: 1,
      selectedRows: {},
      quickScanToolTip3: false,
      presentkitid: [],
      gridRef: null,
      gridSelectionRef: null,
      selectedStates: props.initial_status ? [props.initial_status] : [],
      selectedTags: [],
      selectedUsers: [],
      curretnDisplayItem: [],
      filteredItems: [],
      clearAllFilters: false,
      current_orders_count: undefined,
      popUpModelShow: false,
      currentOrdersCount: 0,
      isShiftPressed: false,
      ordersTagsStatus: undefined
    };
    window.addEventListener('resize', this.update);
    document.addEventListener('keydown', this.handleShiftKey, false);
    document.addEventListener('keyup', this.handleShiftKey, false);
    this.tooltipRef = React.createRef();
    this.searchField = React.createRef();
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  handleShiftKey =(event)=>{
    if (event.shiftKey) {
      this.setState({isShiftPressed: true});
    } else {
      this.setState({isShiftPressed: false});
    }
  }

  statuses = [
    { id: 'Awaiting', label: 'Awaiting' },
    { id: 'Scanned', label: 'Scanned' },
    { id: 'ServiceIssue', label: 'Service Issue' },
    { id: 'Cancelled', label: 'Cancelled' },
    { id: 'PartiallyScanned', label: 'Partially Scanned' },
    { id: 'OnHold', label: 'Action Required' }
  ];

  formatItems = (items) => {
    return items?.map((item, index) => ({
      id: index+1,
      currentItem: item,
      OrderNumber: item.ordernum?.toString(),
      Store: item.order_info.store_name?.toString(),
      Notes: item?.order_info?.notes?.toString() || '',
      OrderDate: item.order_info.order_date,
      Items: item.order_info.itemslength,
      Recipient: item.order_info.recipient,
      Status: item.order_info.status === 'onhold' ? 'Action Required' : this.Capitalize(item.order_info.status)?.toString(),
      customFieldOne: item.order_info.custom_field_one,
      customFieldTwo: item.order_info.custom_field_two, //'country', 'city', 'email'
      trackingNumber: item.order_info.tracking_num,
      country: item.order_info.country,
      city: item.order_info.city,
      email: item.order_info.email,
      tote: item.tote,
    }));
  }

  filterValue = [
    { name: 'OrderNumber', operator: 'contains', type: 'string' },
    { name: 'Store', operator: 'contains', type: 'string' },
    { name: 'Notes', operator: 'contains', type: 'string' },
    { name: 'OrderDate',operator: 'before',type: 'date', value: '' },
    { name: 'Items', operator: 'gte', type: 'number' },
    { name: 'Recipient', operator: 'contains', type: 'string' },
    { name: 'Status', operator: 'eq', type: 'string', value: this.props.initial_status || '' },
    { name: 'customFieldOne', operator: 'contains', type: 'string' },
    { name: 'customFieldTwo', operator: 'contains', type: 'string' },
    { name: 'trackingNumber', operator: 'contains', type: 'string' },
    { name: 'country', operator: 'contains', type: 'string' },
    { name: 'city', operator: 'contains', type: 'string' },
    { name: 'email', operator: 'contains', type: 'string' },
    { name: 'tote', operator: 'contains', type: 'string' },
  ]

  sortColumnNames = {
    Items: "itemslength",
    Store: "store_name",
    OrderDate: "order_placed_time",
    OrderNumber: 'increment_id',
    Recipient: 'firstname',
    Status:'status',
    customFieldOne: 'custom_field_one',
    trackingNumber: 'custom_field_two',
    country: 'country',
    city: 'city',
    email: 'email',
  }

  columns = [
    { name: 'OrderNumber', header: 'Order Number', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false,
    render: ({ value }, { cellProps })=> {
      const item = allOrders.find(item => cellProps.data.currentItem.order_info?.id === item.order_info?.id);
      return <OrderNumberCell
      item={item}
      value={value}
      redirectToProductDetail={this.redirectToProductDetail}
      redirectToScanPack={this.redirectToScanPackItem}
      />;
    }
    },
    { name: 'Store', header: 'Store', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false
    }
    ,
    { name: 'Notes', header: 'Notes', maxWidth: 1000, showColumnMenuSortOptions: false, defaultFlex: 2,
    },
    { name: 'OrderDate',
    header: 'OrderDate',
    dateFormat: 'MM-DD-YYYY',
    filterEditor: DateFilter,
    maxWidth: 1000,
    defaultFlex: 2,
    filterEditorProps: (props, { index }) => {
      return {
        dateFormat: 'MM-DD-YYYY',
        placeholder: index == 1 ? 'Created date is before...': 'Created date is after...'
      }
    },
    render: ({ value, cellProps: { dateFormat } }) =>
      moment(value).format(dateFormat),
   },
    { name: 'Items', header: 'Items', maxWidth: 1000, type:'number', defaultFlex: 2, showColumnMenuSortOptions: false, filterEditor: NumberFilter },
    { name: 'Recipient', header: 'Recipient', maxWidth: 800, defaultFlex: 2, showColumnMenuSortOptions: false },
    { name: 'Status', header: 'Status', maxWidth: 800, defaultFlex: 2, showColumnMenuSortOptions: false,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: 'All',
        dataSource: this.statuses
      },
    },
    { name: 'customFieldOne', header: 'Custom Field One', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false, defaultVisible: false},
    { name: 'customFieldTwo', header: 'Custom Field Two', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false, defaultVisible: false},
    { name: 'trackingNumber', header: 'Tracking Number', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false, defaultVisible: false},
    { name: 'country', header: 'Country', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false, defaultVisible: false},
    { name: 'city', header: 'City', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false, defaultVisible: false},
    { name: 'email', header: 'Email', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false, defaultVisible: false},
    { name: 'tote', header: 'Tote', maxWidth: 1000, defaultFlex: 2, showColumnMenuSortOptions: false, defaultVisible: false},
  ];
  gridStyle = { minHeight: 200, width: '95%', alignSelf: 'start', height: '95%' }

  componentDidMount() {
    this.searchField.current?.focus();
    this.setState({allProduct: this.props.productSearchList});
    this.update();
    let product = this.props.product;

    if (product) {
      let existingItems = [];
      this.props?.items?.items.map((item, index) => {
        existingItems.push(item.productinfo.id);
      });
      this.setState({existingItems});
      this.props.GetAllProduct(this.state.getAllItem);
    }
    if (this.props?.items?.items?.length) {
      let sorted = this.props.items.items.sort(
        (a, b) =>
          Date.parse(a.iteminfo?.updated_at) -
          Date.parse(b.iteminfo?.updated_at)
      );
      this.setState({items: sorted});
    }
    this.state.searchData['search'] = this.props.lastSearched;
  }

  async componentDidUpdate() {
    if (
      updateSearch === false &&
      this.props.searchProduct &&
      this.props.searchOrderCount &&
      !_.isEqual(this.state.searchProduct, this.props.searchProduct) &&
      !_.isEqual(this.state.searchOrderCount, this.props.searchOrderCount)
    ) {
      let temp = this.state.searchData;
      this.setState({
        allProduct: this.props.searchProduct,
        searchProduct: this.props.searchProduct,
        searchOrderCount: this.props.searchOrderCount,
        temp
      });
      updateSearch = true;
      isSearchOrder = true;
    }
    // this.searchField.current?.focus();
    // const isJobCompleted = await AsyncStorage.getItem('status_update_inprogress');
    // console.log(">>>>>>.ordersTags", this.props?.ordersTags)

    if(this.props.ordersUpdated){
      let temp = this.state.gridRef?.current.getColumnFilterValue('OrderNumber')?.value;
      await this.props.ResetOrdersUpdated();
      // this.state.gridRef?.current.deselectAll();
      this.state.gridRef?.current.setColumnFilterValue('OrderNumber', 'null');
      this.state.gridRef?.current.setColumnFilterValue('OrderNumber', temp);
      this.setState({ currentOrdersCount: 0});
      await AsyncStorage.setItem('progress', JSON.stringify(0));
      userAddedCount = null;
    }
  }

  searchItem() {
    update = true;
    let search = this.state.searchData;
    if (search.search !== '') {
      updateSearch = false;
      this.handleDisableFilters();
      let temp = this.state.selectedStates
      this.state.gridRef?.current.setColumnFilterValue('Status', null)
      setTimeout(() => {
        this.state.gridRef?.current.setColumnFilterValue('Status', temp)
      }, 100);
    }
  }

  // resetFilters = (statues) => {
  //   statues = !statues ? [] : statues;
  //   const list = statues?.length > 0 ? this.getFilteredOrders(statues) : this.formatItems(this.props.productSearchList);
  //   if (!Array.isArray(statues)) {
  //     statues = statues ? [statues] : statues;
  //   }
  //   this.setState({
  //     formattedItems: list,
  //     selectedStates: statues,
  //   });
  // };

  redirectToProductDetail = (order, fromGrid) => {
    let page = this.props.fromPage ? this.props.fromPage : 'OrderSearch';
    this.props.navigation.navigate('OrderDetail', {
      item: order,
      userData: this.props.userInfo,
      page: page,
      onComeBack: () => this.props.onComeBack(),
      isFromGrid: fromGrid
    });
    this.props.ResetSearchOrder()
  };

  redirectToScanPackItem = (order) => {
    this.props.navigation.navigate('ScanPack', {
      orderNumber: order.ordernum,
      userdata: this?.props?.userInfo,
      page: 'orderSearchList'
    });
  }

  reloadGridOrders = async(data) =>{
    let temp = this.state.selectedStates;
    this.state.gridRef?.current.setColumnFilterValue('Status', null);
    await this.props.GetOrderList(data);
    setTimeout(() => {
      this.state.gridRef?.current.setColumnFilterValue('Status', temp)
    }, 1000);
  }

  getUnselectedItemsFormattedIds = () => {
    const { gridSelectionRef, formattedItems } = this.state;
    // console.log(">>>>>>>>>>>>.gridSelectionRef", gridSelectionRef?.unselected, allOrdersWithStatus)
    if (gridSelectionRef?.unselected) {
      const unselectedIds = Object.keys(gridSelectionRef.unselected)
        .filter(key => gridSelectionRef.unselected[key])
        .map(Number);

        unselectedOrderItems = allOrdersWithStatus
        ?.map((item, index) => ({ id: item.currentItem.id, index, objectId: item.id }))
        .filter(item => unselectedIds.includes(item.objectId))
        .map(item => item.id) // Extract the id
        .join(',');
        return unselectedOrderItems;
    } else {
      unselectedOrderItems = ""
      return unselectedOrderItems;
    }
  }

  updateOrderStatus = async (value) => {
    let gridSelectionIdsArray = getSelectedOrderIds(this.state.selectedRows)

    const unselectedItems = this.getUnselectedItemsFormattedIds();
    const lowerCaseStatesString = this.state.selectedStates
      .map(state => state.toLowerCase())
      .join(',');

    data = {
      filter: lowerCaseStatesString || 'all',
      inverted: false,
      limit: 20,
      offset: prevSkip,
      order: 'DESC',
      orderArray: gridSelectionIdsArray,
      product_search_toggle: 'true',
      reallocate_inventory: true,
      search: this.state.searchData?.search || '',
      filters: PresentFilterValues,
      select_all: allOrdersSelected,
      sort: '',
      status: value,
      unselected: unselectedItems
    };
    allOrdersSelected  =  allOrdersSelected === true ? false : allOrdersSelected;
    // console.log("gridSelectionRef", this.state.gridSelectionRef)
    await this.props.UpdateOrderStatus(data);
    this.state.gridRef?.current.deselectAll();
    this.handleResetFilter();
  };

  distributeOrders = (selectedCountofOrders, usersSelected) => {
    const numberOfUsers = usersSelected.length;
    const baseCount = Math.floor(selectedCountofOrders / numberOfUsers); // Get the base number of orders per user
    let remainingOrders = selectedCountofOrders % numberOfUsers; // Get the remaining orders that need to be distributed

    return usersSelected.map((user, index) => {
      // Distribute the highest number of remaining orders to the first user
      const assignedOrders = baseCount + (index < remainingOrders ? 1 : 0); // The first 'remainingOrders' users get 1 extra order
      return {
        user,
        assignedOrders,
      };
    });
  };


  assignOrdersToUsers = async (usersSelected, shoudlAssign = true,  selectedCountofOrders) => {
    if (!getSelectedOrders(this.state.selectedRows)?.length > 0) {
      this.alertBox(i18n.t('order.noSelectedUserError'));
      return;
    }
    let gridSelectionIdsArray = getSelectedOrderIds(this.state.selectedRows);
    const unselectedItems = this.getUnselectedItemsFormattedIds();

    const lowerCaseStatesString = this.state.selectedStates ? this.state.selectedStates
      .map(state => state.toLowerCase())
      .join(',') : null;

    data = {
      filter: lowerCaseStatesString || 'all',
      inverted: false,
      limit: 20,
      offset: prevSkip,
      order: 'DESC',
      orderArray: gridSelectionIdsArray,
      product_search_toggle: 'true',
      reallocate_inventory: true,
      search: this.state.searchData?.search || '',
      filters: PresentFilterValues,
      select_all: allOrdersSelected,
      sort: '',
      unselected: unselectedItems,
      users: usersSelected,
      dateValue: dateValue,
      // username: this.state.selectedUsers
    };

    allOrdersSelected  =  allOrdersSelected === true ? false : allOrdersSelected;
    if(gridSelectionIdsArray.length >= usersSelected.length)
    {
      await shoudlAssign === true ? this.props.AssignOrdersToUsers(data) : this.props.DeassignOrdersToUsers(data)
    }
    else{
      this.alertBox(i18n.t('order.selectedUserError'));
    }
    userAddedCount =  shoudlAssign === true ? this.distributeOrders(selectedCountofOrders, usersSelected) : null
    // this.state.gridRef?.current.deselectAll();
    // this.handleResetFilter();
  };

  handleChange(text) {
    let searchData = this.state.searchData;
    searchData['search'] = text;
    this.setState({searchData});
  }
  closeAlert = () => {
    this.setState({popUpModelShow: false});
  };

  alertBox = (message) => {
    alertMessage = message
    this.setState({popUpModelShow: true});
    setTimeout(
      function () {
        this.setState({
          popUpModelShow: false
        });
      }.bind(this),
      4000
    );
  };
  Capitalize(str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }

  getFilteredOrders = (selectedStatuses) => {
      this.setState({
        selectedStates: selectedStatuses,
        formattedItems: []
      });
      this.state.gridRef?.current.setColumnFilterValue('Status', selectedStatuses)
  };

  loadTagsData = async() => {
    const selectedStates = this.state.selectedStates;
    const gridSelectionRef = this.state.gridSelectionRef;
    const ordersTags = this.props.ordersTags;
    const prevlimit = this.state.prevlimit;
    const prevSkip = this.state.prevSkip;
    const searchData = this.state.searchData;
    const selectedOrders = this.state.selectedRows;
    const tags_selected = this.state.selectedTags

    try {
      const ordersTagsStatus = await loadTagsData({
        selectedStates,
        gridSelectionRef,
        ordersTags,
        prevlimit,
        prevSkip,
        searchData,
        PresentFilterValues,
        isFirstTime,
        tags_selected,
        filterIncludedTags,
        dateValue,
        selectedOrders,
      });

      const currentTags = this.state.ordersTagsStatus?.tags;
      const newTags = ordersTagsStatus?.tags;
      const tagsMatch = currentTags && JSON.stringify(currentTags.all_present) === JSON.stringify(newTags.all_present) &&
                      JSON.stringify(currentTags.not_present) === JSON.stringify(newTags.not_present) &&
                      JSON.stringify(currentTags.partially_present) === JSON.stringify(newTags.partially_present);

      // console.log("Current ordersTagsStatus:", this.state.ordersTagsStatus);
      // console.log("New ordersTagsStatus:", ordersTagsStatus, isFirstTime);
      // debugger
      // if (isFirstTime) {
        let temp = ordersTagsStatus === "Job is still in progress" ? this.state.ordersTagsStatus : ordersTagsStatus
        this.setState({ordersTagsStatus: temp });
        isFirstTime = false
      // }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  updateOrdersTagsStatus = (status, tagName) => {
    this.setState(prevState => {
      // const { all_present, partially_present, not_present } = prevState?.ordersTagsStatus;
      if (status === 'all_present') {
        // isFirstTime = true;
        return {
          ordersTagsStatus: {
            ...prevState.ordersTagsStatus,
            tags: {
              ...prevState.ordersTagsStatus.tags,
              all_present: prevState.ordersTagsStatus.tags.all_present.filter(tag => tag !== tagName),
              not_present: [...prevState.ordersTagsStatus.tags.not_present, tagName]
            }
          }
        };
      } else if (status === 'partially_present') {
        // isFirstTime = true;
        return {
          ordersTagsStatus: {
            ...prevState.ordersTagsStatus,
            tags: {
              ...prevState.ordersTagsStatus.tags,
              partially_present: prevState.ordersTagsStatus.tags.partially_present.filter(tag => tag !== tagName),
              not_present: [...prevState.ordersTagsStatus.tags.not_present, tagName]
            }
          }
        };
      } else if (status === 'not_present') {
        // isFirstTime = true;
        return {
          ordersTagsStatus: {
            ...prevState.ordersTagsStatus,
            tags: {
                ...prevState.ordersTagsStatus.tags,
              all_present: [...prevState.ordersTagsStatus.tags.all_present, tagName],
              not_present: prevState.ordersTagsStatus.tags.not_present.filter(tag => tag !== tagName),
            }
          }
        };
      }

      // Return prevState if no conditions are matched
      return prevState;
    });
  };

  loadData = async({ skip, limit, filterValue, sortInfo }, getStatus = 'all') => {
    isValuePresentForAny = filterValue.some(filter => filter.hasOwnProperty('value') && filter.value !== null && filter.value !== '' && filter.name !== "Status");
    isValuePresentForAny = currentSelectedDate !== "all_day" ? true : isValuePresentForAny;
    const statusFilter = filterValue.find(filter => filter.name === "Status");
    PresentFilterValues = JSON.stringify(filterValue);
    const sortOrder = sortInfo?.dir === -1 ? "DESC" : "ASC";
    // this.resetFilters(statusFilter.value);
    const userUrl = await AsyncStorage.getItem('url');

    const dateRangeString = encodeURIComponent(JSON.stringify(dateRangeValues));

    const url = `${userUrl}/orders/sorted_and_filtered_data.json?filter=${getStatus}&order=${sortOrder}&limit=${limit}&sort=${sortInfo?.columnName ? this.sortColumnNames[sortInfo?.columnName] : ""}&offset=${skip}&app=${''}&count=${''}&dateValue=${dateValue}&dateRange=${dateRangeString}&filters=${JSON.stringify(filterValue)}&search=${this.state.searchData.search || ""}&filterIncludedTags=${filterIncludedTags}&tags_name=${this.state.selectedTags}&username=${this.state.selectedUsers}`;
    const access_token = await AsyncStorage.getItem('access_token');
    const updatedData = await axios({
      method: 'get',
      url: url,
      headers: {Authorization: `Bearer ${access_token}`}
    });
    if (isFirstCall) {previousData = updatedData }
    isFirstCall = false;
    prevSkip = skip;
    prevlimit = limit;
    includedTags = updatedData?.data.tags.present;
    excludedTags = updatedData?.data.tags.not_present;
    // debugger
    usersOrderCount = updatedData?.data.users
    usersOrderAssignedCount = updatedData?.data.assigned_users
    displayPrompt = !updatedData?.data?.orders.length > 0;
    return updatedData?.data
  }

  loadOrders = async (e) => {
    if (islastSearchPresent === true){
      let presentData = this.loadSearchData();
      islastSearchPresent = false;
      return presentData;
    }
    const {orders, orders_count}  = await this.loadData(e)
    allOrders =  orders;
    const formattedItems = this.formatItems(orders);
    this.setState({
      formattedItems: formattedItems,
      current_orders_count: orders_count
    });
    if(this.state.searchData.search && orders_count && orders_count.all === 0)
    {
      this.alertBox(i18n.t('order.orderNotFound'));
      return {data: "", count: 0}
    }
    const count = (orders_count?.filtered_count >= 0) ? orders_count?.filtered_count : orders_count?.all
    this.setState({ currentOrdersCount: count});
    // this.setState({ currentOrdersCount: orders_count?.filtered_count});
    return {data: formattedItems, count: count};
  }

  clearFiltersAndExpandSearch = () => {
    displayPrompt = false;
    this.setState({
      current_orders_count: this.props.orderCount
    });
    const gridRef = this.state.gridRef?.current;
    if (gridRef) {
      gridRef.clearAllFilters();
      this.setState({
        searchProduct: [],
        clearAllFilters: true,
        selectedStates: [],
        selectedTags: []
      })
    }
  };

  loadOrderWithStatus = async (e) => {
    const selectedState = this.state.selectedStates;
    const {orders, orders_count, tags}  = await this.loadData(e, selectedState)
    const formattedItems = this.formatItems(orders) || [];
    allOrders =  orders;
    allOrdersWithStatus =  formattedItems;
    this.setState({
      formattedItems: formattedItems,
      current_orders_count: orders_count
    });
    const count = (orders_count?.filtered_count >= 0)  ? orders_count?.filtered_count === 0 ? 0 : orders_count?.filtered_count || orders_count?.all : this.sumSelectedStates(orders_count, selectedState)
    this.setState({ currentOrdersCount: count});
    if(!parseInt(count) && this.state.searchData.search && !this.state.clearAllFilters) displayPrompt = true;
    return {data: formattedItems, count: count || 0};
  }

  loadSearchData = async (e) =>{
    isSearchOrder = false
    islastSearchPresent = true;
    let formattedItems = this.formatItems(this.props.searchProduct);
    let filteredOrders = [];
    this.state.selectedStates.forEach(selectedStatus => {
      const ordersForStatus = formattedItems.filter(order => order.Status.toLowerCase() === selectedStatus.toLowerCase());

      filteredOrders = filteredOrders.concat(ordersForStatus);
    });
    formattedItems = filteredOrders.length > 0 ? filteredOrders : formattedItems;
    this.setState({
      formattedItems: formattedItems,
    })
    let count = this.state.selectedStates[0] === undefined ?
      this.state.searchOrderCount.search : this.props.searchOrderCount[this.state.selectedStates[0]]
    return {data: formattedItems, count: count};
  }

  sumSelectedStates(orderCount, selectedStates, forSelectedOrders = false) {
      let sum = 0;
      selectedStates =  Array.isArray(selectedStates) ? selectedStates : [selectedStates];
      if (!orderCount) {return;}
      if (!Array.isArray(selectedStates)) {
        selectedStates = [selectedStates];
      }
      selectedStates.forEach(state => {
        state = state === "PartiallyScanned" ? "partially_scanned" : state
        state = state === "ServiceIssue" ? "serviceissue" : state

        sum += orderCount[state?.toLowerCase()] || 0;
      });
      sum = forSelectedOrders ? (!isValuePresentForAny && sum || orderCount?.filtered_count || orderCount?.all || 0) : (isValuePresentForAny && this.state.currentOrdersCount === 0 ? 0 : this.state.currentOrdersCount || sum || 0)
      // console.log(">>>>>>.count >>>>", isValuePresentForAny, this.state.currentOrdersCount , sum)
      return sum.toString();
  }

  isEmptyObject = (obj) => {
    if(obj?.constructor === Object){
      return  Object.keys(obj).length > 0 ? true : false ;
    }
    return true;
  };

  checCallBack = () => {
    FirstOrdersTagsStatus = true

    if(this.state.selectedStates?.length > 0 && !isSearchOrder) {
      const orders = this.loadOrderWithStatus
      return orders
    }
    else {
      if(isSearchOrder) {
        return this.loadSearchData
      }
      else {
        return  this.loadOrders
      }
    }
  }

  filterOrders = () => {
    let temp =
      this.state.gridRef?.current.getColumnFilterValue('OrderNumber')?.value;
    this.state.gridRef?.current.setColumnFilterValue('OrderNumber', '-1');
    setTimeout(() => {
      this.state.gridRef?.current.setColumnFilterValue('OrderNumber', temp);
    }, 1000);
  };

  resetOrderFilters = statues => {
    update = false;
    statues = statues.length === 0 ? null : statues;
    this.state.gridRef?.current.setColumnFilterValue('Status', statues);
    const list =
      statues?.length > 0
        ? this.getFilteredOrders(statues)
        : this.formatItems(this.props.productSearchList);
    this.setState({
      formattedItems: list,
      selectedStates: statues
    });
  };
  handleSkipChange = (skip) => {
    let pageNumber = Math.floor(skip / 20) + 1;
    let currentSelectedRows = this.state.selectedRows[pageNumber] || {};
    if(allOrdersSelected){
      this.setState({
        currentPage: pageNumber,
      },
      ()=>{
        setTimeout(()=>{
          this.state.gridRef?.current.selectAll();
          allOrdersSelected = true;
        }, 3000)
      }
    )
    return
    }
    this.setState({
      currentPage: pageNumber,
      gridSelectionRef: {
        ...this.state.gridSelectionRef,
        selected: getTransformedList(currentSelectedRows)
      }
    });
  }
  handleSelectionChange = (e) => {
    const {currentPage, selectedRows} = this.state;
    let selected = getUpdatedSelectedList(e, this.state.isShiftPressed, selectedRows[currentPage]);
    const unSelectedItems = Object.keys(e.unselected || {}).length;
    const updatedSelectedRows = {...selectedRows};
    if (Object.keys(selected).length === 0) {
      delete updatedSelectedRows[currentPage];
    } else {
      updatedSelectedRows[currentPage] = selected;
    }
    const newState = {
      gridSelectionRef: e,
      selectedRows: updatedSelectedRows,
      currentPage: currentPage
    };
    if (
      allOrdersSelected || unSelectedItems > 0
    ) {
      allOrdersSelected = false;
      newState.gridSelectionRef.selected = getTransformedList(selected);
      newState.gridSelectionRef.unselected = null;
    }
    if(this.state.isShiftPressed){
       newState.gridSelectionRef.selected = selected
    }
    this.setState(newState);
  }
  handleResetFilter = () => {
    this.setState({
      gridSelectionRef: {
        ...this.state.gridSelectionRef,
        selected: {}
      },
      selectedRows: {}
    });
    allOrdersSelected = false
  }
  getCountOfOrdersInView = ()=> {
    const count = !this.state.searchData.search
      ? this.sumSelectedStates(
          this.state.current_orders_count,
          this.state.selectedStates
        ) == 0
        ? this.props.orderCount?.all
        : this.sumSelectedStates(
            this.state.current_orders_count,
            this.state.selectedStates
          )
      : this.state.searchData.search
      ? this.state.current_orders_count?.all
      : this.props.orderCount?.all;
    return count
  }

  handleDisableFilters = ()=> {
    update = false;
    currentSelectedDate = "all_day"
    dateValue = ""
    dateRangeValues["start_date"] = ""
    dateRangeValues["end_date"] = ""
    this.state.gridRef?.current.clearAllFilters();
    const formattedItems = this.formatItems(
      this.props.productSearchList
    );
    this.setState({
      formattedItems: formattedItems,
      searchProduct: [],
      clearAllFilters: true,
      selectedStates: [],
      selectedTags: []
    });
    let temp = this.state.gridRef?.current.getColumnFilterValue('OrderNumber')?.value;
    this.state.gridRef?.current.setColumnFilterValue('OrderNumber', '-1');
    setTimeout(() => {
      this.state.gridRef?.current.setColumnFilterValue('OrderNumber', temp);
    }, 500);
    this.handleResetFilter();
  }
  render() {
    // console.log("Main", this.state.ordersTagsStatus)
    this.getUnselectedItemsFormattedIds();
    let selectedLength = getSelectedOrders(this.state.selectedRows)?.length;
    // selectedLength = this.state?.gridSelectionRef?.selected === true ? this.sumSelectedStates(this.state.current_orders_count, this.state.selectedStates, true) : selectedLength;
    // selectedLength = this.state?.gridSelectionRef?.unselected ? selectedLength - Object.keys(this.state?.gridSelectionRef?.unselected || {}).length : selectedLength;
    const isSelectionAvailable =
      this.state.selectedRows === true ||
      allOrdersSelected ||
      Object.keys(this.state.selectedRows || {}).length > 0;
    return (
      <LinearGradient
        colors={[
          '#000',
          '#000',
          '#5c778f',
          '#5c778f',
          '#253c57',
          '#253c57',
          '#000'
        ]}
        start={[-0.5, -1]}
        style={{
          height: '100%',
          paddingBottom: 15
        }}>
        <View>
          {this.state.windowWidth > 900 ? (
            <View style={{display: 'flex', flexDirection: 'column', top: '3%'}}>
                <View style={[CommonStyles.rdgHeader, {display: !isSelectionAvailable && 'none'}]}>
                  {isSelectionAvailable && <UpdateTagsDropDowns
                    ordersTags={this.props?.ordersTags}
                    loadTagsData={() => {
                      this.loadTagsData();
                    }}
                    ordersTagsStatus={this.state.ordersTagsStatus}
                    getStatus={getStatus(this.state.selectedStates)}
                    prevlimit={prevlimit}
                    prevSkip={prevSkip}
                    filters={JSON.stringify(PresentFilterValues)}
                    search={encodeURIComponent(
                      this.state.searchData.search || ''
                    )}
                    gridSelectionRef={this.state.gridSelectionRef}
                    selectedStates={this.state.selectedStates}
                    FirstOrdersTagsStatus={FirstOrdersTagsStatus}
                    ResetFirstOrdersTagsStatus={() => {
                      FirstOrdersTagsStatus = false;
                    }}
                    updateOrdersTagsStatus={(status, tagName) => {
                      this.updateOrdersTagsStatus(status, tagName);
                      let temp =
                        this.state.gridRef?.current.getColumnFilterValue(
                          'OrderNumber'
                        )?.value;
                      // this.state.gridRef?.current.setColumnFilterValue('OrderNumber', temp);
                    }}
                    unselectedOrderItems={unselectedOrderItems}
                    GetTags={() => {
                      this.props.GetAllTags();
                    }}
                    filterIncludedTags={filterIncludedTags}
                    resetSelectedTags={() => {
                      this.setState({
                        selectedTags: []
                      });
                      this.handleResetFilter()
                    }}
                    selectedRows={this.state.selectedRows}
                  />}
                  <OrderUserTagsDropdown
                    userTags={usersOrderCount}
                    clearAllFilters={this.state.clearAllFilters}
                    resetFilterCheck={() => {
                      this.setState({
                        clearAllFilters: false
                      });
                    }}
                    status={this.state.selectedTags || []}
                    setSelectedTags={statues => {
                      // this.setState({
                      //   selectedTags: statues
                      // });
                    }}
                    setFilterUserTags={value => {
                      filterIncludedTags = value;
                    }}
                    assignOrdersToUsers={(value, shoudlAssign) =>
                      this.assignOrdersToUsers(
                        value,
                        shoudlAssign,
                        selectedLength
                      )
                    }
                    userAddedCount={userAddedCount}
                  />
                </View>
              <View
                style={[
                  CommonStyles.orderInfoStyle,
                  {
                    zIndex: -1,
                    fontFamily: 'Poppins_300Light',
                    padding: '13px',
                    height: window.innerHeight - 100,
                    backgroundColor:
                      this.state.windowWidth >= 900
                        ? 'rgb(49, 57, 67)'
                        : 'rgba(104, 135, 156, 0.4)',
                    flexDirection: 'row',
                    paddingLeft: 1
                  }
                ]}>
                <CustomScrollBar showDefaultStyles={false}>
                <View style={[{minWidth: '20%', flexDirection: 'column'}]}>
                  <View
                   style={{height: '100%', width: '102%'}}
                  >
                    <Text
                      style={{
                        color: '#ffffff',
                        alignSelf: 'center',
                        fontSize: '14px',
                        marginBottom: '8%'
                      }}>
                      Orders selected { allOrdersSelected ? this.getCountOfOrdersInView() : selectedLength}
                    </Text>

                    <Text
                      style={{
                        color: '#ffffff',
                        alignSelf: 'center',
                        textAlign: 'center',
                        fontSize: '15px',
                        marginBottom: '5%',
                        fontFamily: 'Poppins_300Light',
                        width: '112%',
                        paddingTop: 10,
                        marginLeft: '-1px'
                      }}>
                      Orders in current View:{' '}
                      {this.getCountOfOrdersInView()}
                    </Text>
                    <View
                      style={{
                        width: '105%',
                        flexDirection: 'row',
                        alignSelf: 'stretch'
                      }}>
                      <TouchableOpacity
                        testID="searchItemButton"
                        style={{flexDirection: 'row'}}>
                        <i
                          className="icon-search"
                          style={{fontSize: 15, color: 'white'}}></i>
                      </TouchableOpacity>
                      <TextInput
                        testID="searchItem"
                        placeholder="Type Order to search"
                        name="searchOrder"
                        autoFocus={true}
                        ref={this.searchField}
                        value={
                          this.state.searchData && this.state.searchData.search
                        }
                        onChangeText={text => {
                          this.handleChange(text);
                          this.setState({searchTxt: text});
                        }}
                        onSubmitEditing={e => this.searchItem(e)}
                        style={[
                          CommonStyles.typeOrderInput,
                          {
                            height: 20,
                            marginLeft: '-20px',
                            paddingLeft: '25px',
                            backgroundColor: 'lightgray',
                            color: 'gray'
                          }
                        ]}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            shouldUpdateCount = false;
                            this.searchItem();
                          }
                        }}
                      />
                      {this.state.searchData.search && (
                        <TouchableOpacity
                          testID="closeSearch"
                          style={{
                            marginLeft:
                              this.state.windowWidth > 1440 ? '-7%' : '-10%',
                            marginTop: '-1px'
                          }}
                          onPress={() => {
                            displayPrompt = false;
                            this.setState({
                              current_orders_count: this.props.orderCount
                            });
                            this.state.gridRef?.current.setColumnFilterValue(
                              'Status',
                              null
                            );
                            setTimeout(() => {
                              this.state.gridRef?.current?.clearAllFilters();
                            }, 100);
                            this.handleChange('');
                          }}>
                          <i
                            className="icon-cancel-circled"
                            style={{fontSize: 17, color: 'gray'}}></i>
                        </TouchableOpacity>
                      )}
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '95%'
                      }}>
                      <SelectAllButton
                        selectedOrders = {selectedLength}
                        onPress={() => {
                          if (selectedLength > 0) {
                            this.handleResetFilter();
                            return
                          }
                          this.state.gridRef?.current.selectAll();
                          allOrdersSelected = true;
                        }}
                      />
                      <TouchableOpacity
                        testID="disableFiltersButton"
                        style={{
                          marginRight: 10,
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor:
                            this.state.selectedStates?.length > 0 ||
                            this.state.selectedTags.length > 0 ||
                            isValuePresentForAny
                              ? '#fff'
                              : 'gray',
                          borderRadius: 30,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 18,
                          fontFamily: 'Poppins_300Light',
                          marginTop: 10
                        }}
                        onPress={this.handleDisableFilters}>
                        <View
                          style={{flexDirection: 'row', alignItems: 'center'}}>
                          <Text
                            style={[
                              CommonStyles.saveButtonText,
                              {
                                fontSize: 12,
                                color:
                                  this.state.selectedStates?.length > 0 ||
                                  this.state.selectedTags.length > 0 ||
                                  isValuePresentForAny
                                    ? 'white'
                                    : 'gray'
                              }
                            ]}>
                            {this.state.selectedStates?.length > 0 ||
                            this.state.selectedTags.length > 0 ||
                            isValuePresentForAny
                              ? 'Clear All Filters'
                              : 'Filters disabled'}
                          </Text>
                          <FontAwesome
                            name="bomb"
                            size={12}
                            color={
                              this.state.selectedStates?.length > 0 ||
                              this.state.selectedTags.length > 0 ||
                              isValuePresentForAny
                                ? 'white'
                                : 'gray'
                            }
                            style={{marginRight: 4}}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View style={{alignSelf: 'stretch'}}>
                      <OrderStatusDropdown
                        getFilteredOrders={status => {
                          this.state.gridRef?.current.deselectAll();
                          this.getFilteredOrders(status);
                        }}
                        resetFilters={statues => {
                          update = false;
                          statues = statues.length === 0 ? null : statues;
                          this.state.gridRef?.current.setColumnFilterValue(
                            'Status',
                            statues
                          );
                          const list =
                            statues?.length > 0
                              ? this.getFilteredOrders(statues)
                              : this.formatItems(this.props.productSearchList);
                          this.setState({
                            formattedItems: list,
                            selectedStates: statues
                          });
                          this.handleResetFilter();
                        }}
                        formattedItems={this.state.formattedItems}
                        productSearchList={
                          this.state.searchProduct.length === 0
                            ? this.props.productSearchList
                            : this.state.searchProduct
                        }
                        shouldUpdateCount={shouldUpdateCount}
                        clearAllFilters={this.state.clearAllFilters}
                        resetFilterCheck={() => {
                          this.setState({
                            clearAllFilters: false
                          });
                        }}
                        status={this.state.selectedStates || []}
                        orderCount={
                          this.state.searchData.search ||
                          this.state?.gridSelectionRef?.selected
                            ? this.state.current_orders_count
                            : this.props.orderCount
                        }
                      />
                    </View>
                    <View style={{alignSelf: 'stretch', marginTop: '1rem'}}>
                    <OrderTagsDropdown
                      getFilteredOrders={status => {
                        // this.state.gridRef?.current.deselectAll();
                        // status = this.state.selectedStates;
                        // this.getFilteredOrders(status);
                        let temp = this.state.gridRef?.current.getColumnFilterValue('OrderNumber')?.value;
                        // shouldUpdateGrid /= false;
                        this.state.gridRef?.current.setColumnFilterValue('OrderNumber', '-1');
                        setTimeout(() => {
                          this.state.gridRef?.current.setColumnFilterValue('OrderNumber', temp);
                        }, 1000);
                        this.handleResetFilter();
                        // shouldUpdateGrid = true;
                      }}
                      resetFilters={statues => {
                        update = false;
                        statues = statues.length === 0 ? null : statues;
                        this.state.gridRef?.current.setColumnFilterValue(
                          'Status',
                          statues
                        );
                        const list =
                          statues?.length > 0
                            ? this.getFilteredOrders(statues)
                            : this.formatItems(this.props.productSearchList);
                        this.setState({
                          formattedItems: list,
                          selectedStates: statues
                        });
                        this.handleResetFilter();
                      }}
                      includedTags={includedTags}
                      excludedTags={excludedTags}
                      formattedItems={this.state.formattedItems}
                      productSearchList={
                        this.state.searchProduct.length === 0
                          ? this.props.productSearchList
                          : this.state.searchProduct
                      }
                      shouldUpdateCount={shouldUpdateCount}
                      clearAllFilters={this.state.clearAllFilters}
                      resetFilterCheck={() => {
                        this.setState({
                          clearAllFilters: false
                        });
                      }}
                      status={this.state.selectedTags || []}
                      orderCount={orderCount}
                      setSelectedTags={(statues)=>{
                        // console.log(">>>>>>>>>>statues", statues, filterIncludedTags);
                        this.setState({
                          selectedTags: statues
                        });
                      }}
                      setFilterIncludedTags = {(value) => {filterIncludedTags = value;}}
                      filterIncludedTags={filterIncludedTags}
                    />
                    <UserTagsDropdown
                      getFilteredOrders={status => {
                        let temp = this.state.gridRef?.current.getColumnFilterValue('OrderNumber')?.value;
                        this.state.gridRef?.current.setColumnFilterValue('OrderNumber', '-1');
                        setTimeout(() => {
                          this.state.gridRef?.current.setColumnFilterValue('OrderNumber', temp);
                        }, 1000);
                        this.handleResetFilter();
                      }}
                      resetFilters={()=>{}}
                      assignedUserTags={usersOrderAssignedCount}
                      clearAllFilters={this.state.clearAllFilters}
                      resetFilterCheck={() => {
                        this.setState({
                          clearAllFilters: false
                        });
                      }}
                      status={this.state.selectedUsers}
                      setSelectedUsers={(statues)=>{
                        this.setState({
                          selectedUsers: statues
                        });
                      }}
                      setFilterIncludedTags= {(value) => {
                        filterIncludedTags= value;
                        this.handleResetFilter();
                      }}
                      filterIncludedTags={filterIncludedTags}
                    />
                    <View style={{ alignSelf: 'stretch' }}>
                     <OrderDateDropdown
                      getFilteredOrders={(value, date = "") => {
                        if(date && value[0] === 'start_date'){
                          date = moment(date).format('MM-DD-YYYY')
                          dateRangeValues["start_date"] = date || "";
                        }
                        else if(date && value[0] === 'end_date'){
                          date = moment(date).format('MM-DD-YYYY')
                          dateRangeValues["end_date"] = date || "";
                          dateValue = ""
                        }
                        else{
                          if(value && value[0] !== 'start_date' && value[0] !== 'end_date'){
                            dateValue = value && value[0] || ""
                            // currentSelectedDate = value && value[0] || "";
                            dateRangeValues["start_date"] = ""
                            dateRangeValues["end_date"] = ""
                          }
                        }
                        currentSelectedDate = (value && value[0]) || '';
                        this.state.gridRef?.current.deselectAll();
                        let temp = this.state.gridRef?.current.getColumnFilterValue('OrderNumber')?.value;
                        // shouldUpdateGrid /= false;
                        this.state.gridRef?.current.setColumnFilterValue('OrderNumber', '-1');
                        setTimeout(() => {
                          this.state.gridRef?.current.setColumnFilterValue('OrderNumber', temp);
                        }, 1000);
                        this.handleResetFilter();
                      }}
                      resetFilters={()=>{}}
                      formattedItems={this.state.formattedItems}
                      productSearchList={this.state.searchProduct.length === 0 ? this.props.productSearchList : this.state.searchProduct}
                      shouldUpdateCount={shouldUpdateCount}
                      clearAllFilters={this.state.clearAllFilters}
                      resetFilterCheck={()=>{
                          this.setState({
                            clearAllFilters: false
                          })
                      }
                      }
                      status={[currentSelectedDate]}
                      orderCount={this.state.searchData.search || this.state?.gridSelectionRef?.selected ? this.state.current_orders_count : this.props.orderCount}
                     />
                    </View>
                  </View>
                  </View>
                </View>
                </CustomScrollBar>
                <View
                  style={{
                    display: 'flex',
                    width: this.state.windowWidth < 1300 ? '76%' : '82%'
                  }}>
                  <View
                    style={{
                      alignSelf: 'flex-start',
                      marginBottom: '1%',
                      zIndex: 99,
                      flexDirection: 'row'
                    }}>
                    <Text
                     style={{
                        color: '#ffffff',
                        fontSize: '14px',
                        fontFamily: 'Poppins_300Light',
                      }}
                    >
                      {this.state.selectedRows === true ||
                    Object.keys(this.state.selectedRows || {})
                      .length
                      ? 'Modify selected:'
                      : 'Select orders to modify them'}
                    </Text>
                    {this.state.selectedRows === true ||
                    allOrdersSelected ||
                    Object.keys(this.state.selectedRows || {})
                      .length > 0 ? (
                      <UpdateOrdersDropDowns
                        updateOrderStatus={this.updateOrderStatus}
                        gridSelectionRef={this.state.gridSelectionRef}
                        skip={prevSkip}
                        limit={prevlimit}
                        statuses={this.state.selectedStates}
                        redirectToProductDetail={item =>
                          this.redirectToProductDetail(item, true)
                        }
                        alertBox={message => {
                          this.alertBox(message);
                        }}
                        getOrderList={data => this.reloadGridOrders(data)}
                        searchString={this.state.searchData?.search || ''}
                        PresentFilterValues={PresentFilterValues}
                        resetSelectedOrders={() => {}}
                        getUnselectedItems={() => {
                          this.getUnselectedItemsFormattedIds();
                        }}
                        unselectedOrderItems={unselectedOrderItems}
                        filterIncludedTags={filterIncludedTags}
                        selectedRows={this.state.selectedRows}
                      />
                    ) : (
                      <></>
                    )}
                  </View>
                  <ReactDataGrid
                    idProperty="id"
                    columns={this.columns}
                    dataSource={this.checCallBack()}
                    style={this.gridStyle}
                    theme={'default-dark'}
                    onReady={e => {
                      this.setState({
                        gridRef: e
                      });
                    }}
                    onSelectionChange={this.handleSelectionChange}
                    showZebraRows={false}
                    pagination
                    defaultFilterValue={this.filterValue}
                    className="global-datagrid-3px-tomato-border"
                    checkboxColumn={true}
                    checkboxOnlyRowSelect={true}
                    defaultLimit={20}
                    onRowClick={this.handleRowClick}
                    onSkipChange={(skip)=>{this.handleSkipChange(skip)}}
                    selected={this.state.gridSelectionRef?.selected}
                  />
                  {displayPrompt && (
                    <SearchNotFoundPrompt
                      clearFiltersAndExpandSearch={() => {
                        this.clearFiltersAndExpandSearch();
                      }}
                    />
                  )}
                </View>
                {this.state.popUpModelShow && alertMessage && (
                  <PopUpModel
                    closeAlert={this.closeAlert.bind(this)}
                    message={alertMessage}
                    messageTypeSuccess={true}
                  />
                )}
              </View>
            </View>
          ) : (
            <View style={CommonStyles.orderListMobile}>
              <View style={[styles.itemContainerProduct]}>
                <View
                  style={[
                    CommonStyles.itemContainerOrderList,
                    {paddingHorizontal: '2%', justifyContent: 'space-around'}
                  ]}>
                  <View
                    style={{
                      width: '95%',
                      flexDirection: 'row',
                      justifyContent: 'space-around'
                    }}>
                    <View
                      style={{
                        marginLeft: this.state.windowWidth > 650 ? -20 : -10
                      }}>
                      <Entypo name="check" size={24} color="#778fa5" />
                    </View>
                    <View style={[styles.addItemViewProduct, {width: '20%'}]}>
                      <Text style={[styles.gridHeader2Product, {fontSize: 16}]}>
                        Order #
                      </Text>
                    </View>

                    <View style={[styles.addItemViewProduct, {width: '10%'}]}>
                      <Text
                        style={[
                          styles.gridHeader2Product,
                          {fontSize: 16, lineHeight: 13}
                        ]}>
                        Items
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.addItemViewProduct,
                        {
                          width: '25%'
                        }
                      ]}>
                      <Text style={[styles.gridHeader2Product, {fontSize: 16}]}>
                        Store
                      </Text>
                    </View>
                    <View style={[styles.addItemViewProduct, {width: '20%'}]}>
                      <Text style={[styles.gridHeader2Product, {fontSize: 16}]}>
                        Status
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <OrderItemList
                orderList={this.props.productSearchList}
                orderSearch={true}
                onPress={item => this.redirectToProductDetail(item, false)}
                c1="ordernum"
                c2="itemslength"
                c3="store_name"
              />
            </View>
          )}
        </View>
      </LinearGradient>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchProduct: state.order.searchResult,
    searchOrderCount: state.order.searchResultCount,
    ordersUpdated: state.order.ordersUpdated,
    ordersTags:  state.order.ordersTags,
  };
};

const mapDispatchToProps = {
  SearchOrder,
  GetOrderList,
  ResetSearchOrder,
  UpdateOrderStatus,
  ResetOrdersUpdated,
  GetAllTags,
  AssignOrdersToUsers,
  DeassignOrdersToUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSearchList);
