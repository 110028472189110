import React, {useState} from 'react';
import CommonStyles from '../../style/commonStyles';
import {Text, TouchableOpacity, View, StyleSheet} from 'react-native';

export const SelectAllButton = ({onPress, selectedOrders}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  let tooltipTimeout;


  const handleMouseEnter = () => {
    setShowTooltip(true);
    tooltipTimeout = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    clearTimeout(tooltipTimeout);
  };

  return (
    <View>
      <TouchableOpacity
        testID="selectAllButton"
        style={styles.button}
        onPress={onPress}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Text style={[CommonStyles.saveButtonText, styles.buttonText]}>
          {selectedOrders === 0 ? 'Select all' : 'Deselect all'}
        </Text>

        {showTooltip && (
          <View
            style={[styles.tooltipContainer, {marginTop: selectedOrders && -18}]}
            testID="tooltip">
            <Text style={styles.tooltipText}>
              {selectedOrders === 0
                ? 'Select all in view'
                : 'Deselect all in view'}
            </Text>
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    marginRight: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    margin: 10,
    position: 'relative'
  },
  buttonText: {
    fontSize: 12
  },
  tooltipContainer: {
    position: 'absolute',
    top: -33,
    right: 0,
    left: -10,
    backgroundColor: 'black',
    padding: 5,
    borderRadius: 10,
    zIndex: 1,
    width: 110
  },
  tooltipText: {
    fontSize: 12,
    color: '#fff',
    fontFamily: 'Poppins_300Light'
  }
});
