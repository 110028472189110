import React, {useState, useEffect} from 'react';
import {TouchableOpacity, StyleSheet, View} from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesome} from '@expo/vector-icons';
import _ from 'lodash';
import {getStatus} from './commonFunctions';
import { getSelectedOrderIds } from '../../helpers/scanPackFunctions';

function MyCheckbox({
  status,
  tagName,
  prevlimit,
  prevSkip,
  filters,
  search,
  gridSelectionRef,
  selectedStates,
  updateOrdersTagsStatus,
  unselectedOrderItems,
  filterIncludedTags,
  resetSelectedTags,
  selectedOrders
}) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (status === 'all_present') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [status]);

  const renderIcon = () => {
    if (status === 'all_present') {
      return (
        <FontAwesome
          name="check"
          size={16}
          color="white"
          style={{marginRight: -4, fontWeight: 900}}
        />
      );
    } else if (status === 'partially_present') {
      return (
        <FontAwesome
          name="minus-square"
          size={15}
          color="white"
          style={{marginRight: -1}}
        />
      );
    }
    return null;
  };

  const addOrRemovefunction = async () => {
    let updatedURL;
    let select_all = false;
    if (gridSelectionRef.selected === true) select_all = true;

    let gridSelectionIdsArray = getSelectedOrderIds(selectedOrders);
    const orders_id = encodeURIComponent(JSON.stringify(gridSelectionIdsArray));
    const url = await AsyncStorage.getItem('url');
    let order_status = getStatus(selectedStates);
    if (gridSelectionRef?.selected === true && selectedStates.length === 0) {
      order_status = 'all';
    }
    if (status === 'all_present' || status === 'partially_present') {
      if (gridSelectionRef?.selected === true) {
        resetSelectedTags();
      }
      updatedURL = `${url}/orders/remove_tags.json?filter=${order_status}&order=DESC&limit=${prevlimit}&sort=&offset=${prevSkip}&app=&count=&filters=${filters}&search=${
        search || ''
      }&tag_name=${tagName}&select_all=${select_all}&orderArray=${orders_id}&unselected=${
        unselectedOrderItems || ''
      }&filterIncludedTags=${filterIncludedTags}`;
    } else if (status === 'not_present' || !status) {
      updatedURL = `${url}/orders/add_tags.json?filter=${order_status}&order=DESC&limit=${prevlimit}&sort=&offset=${prevSkip}&app=&count=&filters=${filters}&search=${
        search || ''
      }&tag_name=${tagName}&select_all=${select_all}&orderArray=${orders_id}&unselected=${
        unselectedOrderItems || ''
      }&filterIncludedTags=${filterIncludedTags}`;
    }
    postData(updatedURL, status);
  };

  const postData = async (url, action) => {
    const access_token = await AsyncStorage.getItem('access_token');
    if (url !== '') {
      try {
        const response = await axios({
          method: 'post',
          url: url,
          headers: {
            Authorization: `Bearer ${access_token}`,
            'content-type': 'application/json'
          }
        });
        if (response.status) {
          updateOrdersTagsStatus(status, tagName);
        }
      } catch (error) {
        console.log('>>>>>>>>>.', error);
      }
    }
  };

  return (
    <TouchableOpacity
      testID="checkbox"
      style={[styles.checkboxBase, checked && styles.checkboxChecked]}
      onPress={() => {
        addOrRemovefunction();
      }}>
      {renderIcon()}
    </TouchableOpacity>
  );
}

export default function CustomCheckBox({
  option,
  ordersTagsStatus,
  getOrdersStatus,
  prevlimit,
  prevSkip,
  filters,
  search,
  gridSelectionRef,
  selectedStates,
  loadTagsData,
  updateOrdersTagsStatus,
  unselectedOrderItems,
  filterIncludedTags,
  resetSelectedTags,
  selectedOrders
}) {
  const [tagsStatus, setTagsStatus] = useState(ordersTagsStatus);

  useEffect(() => {
    setTagsStatus(ordersTagsStatus);
  }, [ordersTagsStatus]);

  const getStatus = tagName => {
    if (tagsStatus?.all_present.includes(tagName)) {
      return 'all_present';
    } else if (tagsStatus?.partially_present.includes(tagName)) {
      return 'partially_present';
    } else if (tagsStatus?.not_present.includes(tagName)) {
      return 'not_present';
    }
    return null;
  };

  return (
    <View style={styles.appContainer}>
      <View style={styles.checkboxContainer}>
        <MyCheckbox
          status={option && getStatus(option?.name)}
          tagName={option?.name}
          getOrderStatus={getOrdersStatus}
          prevlimit={prevlimit}
          prevSkip={prevSkip}
          filters={filters}
          search={search}
          gridSelectionRef={gridSelectionRef}
          selectedStates={selectedStates}
          loadTagsData={loadTagsData}
          tagsStatus={tagsStatus}
          setOrdersTagsStatus={text => setTagsStatus(text)}
          updateOrdersTagsStatus={updateOrdersTagsStatus}
          unselectedOrderItems={unselectedOrderItems}
          filterIncludedTags={filterIncludedTags}
          resetSelectedTags={resetSelectedTags}
          selectedOrders={selectedOrders}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  checkboxBase: {
    width: 15,
    height: 15,
    justifyContent: 'center',
    alignItems: 'end',
    borderRadius: 4,
    borderWidth: 2,
    borderColor: 'white',
    backgroundColor: 'transparent',
    right: '70%'
  },
  checkboxChecked: {
    backgroundColor: 'none'
  },
  appContainer: {
    flex: 1,
    alignItems: 'end',
    justifyContent: 'center'
  },
  appTitle: {
    marginVertical: 16,
    fontWeight: 'bold',
    fontSize: 24
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  checkboxLabel: {
    marginLeft: 8,
    fontWeight: '500',
    fontSize: 18
  }
});
