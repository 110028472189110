
import {StyleSheet} from 'react-native';

const dropdownStyles = StyleSheet.create({
  dropdownActiveButton: {
    border: '1px solid gray',
    borderRadius: 8,
    paddingTop: 2
  },
  button: {
    maxWidth: '107%',
    backgroundColor: '#262B30',
    marginLeft: '-1px'
  },
  buttonContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  text: {
    color: '#9BA7B4',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 13,
    padding: 5
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: '4%',
    marginTop: '1.5%',
    transition: 'transform 0.3s ease'
  },
  iconOpen: {
    transform: [{rotate: '180deg'}]
  },
  customTouchable: {
    paddingLeft: 5
  },
  customTouchableContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'baseline'
  },
  customTouchableCount: {
    color: 'white',
    width: '16%',
    fontSize: 12,
    textAlign: 'right'
  },
  customTouchableText: {
    color: 'white',
    paddingBottom: 2,
    fontSize: 15,
    paddingLeft: 10,
    width: '65%',
    marginLeft: '1%',
    fontFamily: 'Poppins_300Light',
    marginBottom: 2
  },
  customTouchableReset: {
    marginHorizontal: '1%'
  },
  customTouchableIcon: {
    fontSize: 15,
    color: 'gray'
  },
  showAllButton: {
    width: '97%',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 10
  },
  showAllButtonText: {
    textAlign: 'center',
    color: 'white',
    padding: 5
  },
  dropdownContent: {
    marginTop: 10
  },
  includeTagsText: {
    marginLeft: 10,
    fontSize: 11
  },
  notAssignedStyle: {
    color: '#45d797',
    fontSize: 14
  }
});

export default dropdownStyles;
